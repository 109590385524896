import React from "react";
import vir from "../assets/images/vir.png";
import startvr_new from "../assets/svgs/starvr-new.svg";
import { useTranslation } from "react-i18next";


function MostViralSec() {
  const { t } = useTranslation();

  return (
    <div className=" 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] pt-[2.8rem] 2xl:pb-[5rem] xl:pb-[5rem] lg:pb-[5rem] md:pb-[5rem] sm:pb-[2rem] pb-[2rem] sm:w-[100%] w-[100%] max-w-[1200px] mx-auto">
      <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem]  items-center ">
        <div className="w-[45%] 2xl:block xl:block lg:block md:block sm:hidden hidden  w-[100%]">
          <div className="backdrop-blur-sm h-[570px] bg-[#b7b7b70a] flex justify-center items-center relative  rounded-[20px] border-[2px] border-[#333333db]">
            <img className="max-h-[720px]  z-[1] absolute top-[-9%] left-[20%] " src={vir} alt="" />
            <div className="overflow-hidden w-[50%] absolute right-0 flex justify-end items-center top-0 h-[100%]">
              <div className="circleshadow"></div>
            </div>
          </div>
        </div>
        <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block sm:w-[100%] w-[100%]">
          <h3 className="2xl:hidden xl:hidden pb-[2rem] lg:hidden md:hidden sm:block block  text-[#FBD914] leading-[120%] text-[46px] font-[700] text-center">
          {t("MostViral.mainTitle2")} <br />  {t("MostViral.mainTitl3")}
          </h3>
          <div className=" flex justify-center items-center relative">
            <img className="w-[84%] mr-3 m-auto relative z-[1]" src={vir} alt="" />
            <div className="overflow-hidden  w-[50%] absolute right-0 flex justify-end items-center top-0 h-[100%]">
              <div className="circleshadow"></div>
            </div>
          </div>
        </div>
        <div className="2xl:block xl:block lg:block md:block sm:flex flex justify-center 2xl:w-[45%] xl:w-[45%] lg:w-[45%] md:w-[45%] sm:w-[100%] w-[100%] ">
        <div className="space-y-[25px] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[84%] w-[84%]">
          <h3 className="2xl:block xl:block lg:block md:block sm:hidden hidden  text-[#FBD914] leading-[120%] text-[38px] font-[700]">
          {t("MostViral.mainTitle")}
          </h3>
          <div className="space-y-[20px] 2xl:text-start xl:text-start lg:text-start md:text-start sm:text-center text-center">
            <p className="text-[20px] font-[400] text-[#fff] ">
            {t("MostViral.para1")}
            </p>
            <p className="text-[20px] font-[400] text-[#fff] ">
            {t("MostViral.para2")}
            </p>
            <p className="2xl:block xl:block lg:block md:block sm:hidden hidden text-[20px] font-[400] text-[#fff]">
            {t("MostViral.para3")}
            </p>
            <p className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block text-[20px] font-[400] text-[#fff]">
            {t("MostViral.para4")} <br />{t("MostViral.para5")}

            </p>
          </div>
          <div className="2xl:block xl:block lg:block md:block sm:flex flex justify-center">
            <a href="https://t.me/DogeKombatGame_bot">
              <button className="bg-[#FBDA19] hover:bg-[transparent] border border-[#FBDA19] rounded-[40px] w-[200px] h-[56px] text-[18px] 2xl:font-[700] xl:font-[700] lg:font-[700] md:font-[700] sm:font-[800] font-[800] hover:text-[#fff]">{t("MostViral.btn")}</button>
            </a>
            </div>
            <div className="backdrop-blur-lg bg-[#b7b7b70a]  flex justify-center space-x-3 items-center  2xl:m-0 xl:m-0 lg:m-0 md:m-0 sm:m-auto m-auto w-max px-4 border border-[#545454] h-[39px] rounded-[50px]">
                <img src={startvr_new} alt="" />
                <p className="text-[14px] font-[400] text-[#fff]">{t("MostViral.Played")}</p>
            </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default MostViralSec;
