import { useContext, useState } from "react"
import { UserContext } from "../../../presale-gg/context/UserContext"
import { formatNumber, parseNum, api, formatPrecision } from "../../../presale-gg/utils"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"

const StakeTab = () => {
	const userData = useContext(UserContext)
	const [ inputValue, setInputValue ] = useState("0")
	const [ stakeLoading, setStakeLoading ] = useState(false)
	const [ unstakeLoading, setUnstakeLoading ] = useState(false)
	const { t } = useTranslation()

	const stake = async () => {
		if (stakeLoading) return toast.error(t("Messages.stake-in-progress"));
		if (unstakeLoading) return toast.error(t("Messages.unstake-in-progress"));
		const numTokens = Number.parseFloat(inputValue)
		if (!numTokens) return toast.error(t("Messages.invalid-token-amount"))
		setStakeLoading(true)
		try {
			await toast.promise(userData.stakeTokens(numTokens), {
				loading: t("Messages.stake-loading"),
				success: t("Messages.stake-success"),
				error: (err) => api.getApiErrorMessage(err, t("Messages.stake-error"))
			})
		} catch (err) {}
		setStakeLoading(false)
	}


	const unstake = async () => {
		if (stakeLoading) return toast.error(t("Messages.stake-in-progress"));
		if (unstakeLoading) return toast.error(t("Messages.unstake-in-progress"));
		const numTokens = Number.parseFloat(inputValue)
		if (!numTokens) return toast.error(t("Messages.invalid-token-amount"))
		setUnstakeLoading(true)
		try {
			await toast.promise(userData.unstakeTokens(numTokens), {
				loading: t("Messages.unstake-loading"),
				success: t("Messages.unstake-success"),
				error: (err) => api.getApiErrorMessage(err, t("Messages.unstake-error"))
			})
		} catch (err) {}
		setUnstakeLoading(false)
	}

	const launchPrice = 0.01

	return (
		<div className="flex flex-col space-y-[15px] text-[#fff] justify-between flex-1 py-4">
			<h4 className="flex items-center justify-center gap-4 text-[28px] text-center font-[700] text-[#fff] tracking-[-1.65px]">
				{t("Stake.card-title")}
				<p className="bg-[#142e23] text-[#00b567] text-[14px] tracking-[-0.02em] py-1 px-2 rounded-lg font-[500]">
					{t("Stake.apy", {percentage: 35})}
				</p>
			</h4>
			<div className="grid grid-cols-2 gap-4">
				<div className="flex flex-col col-span-2 items-center bg-[#484848] p-4 rounded-lg">
					<p className="font-[700] text-[18px]">{formatNumber(parseNum(userData.userStakeData?.total_staked), 0, 2)} DOKO</p>
					<p className="text-[13px]">{t("Stake.title-staked")}</p>
				</div>
				<div className="flex flex-col items-center bg-[#484848] p-4 rounded-lg">
					<p className="font-[700] text-[18px]">{formatNumber(parseNum(userData.userStakeData?.daily_interest), 0, 2)} DOKO</p>
					<p className="text-[13px]">{t("Stake.title-daily-interest")}</p>
				</div>
				<div className="flex flex-col items-center bg-[#484848] p-4 rounded-lg">
					<p className="font-[700] text-[18px]">{formatNumber(parseNum(userData.userStakeData?.total_earnings), 0, 2)} DOKO</p>
					<p className="text-[13px]">{t("Stake.title-total-earnings")}</p>
				</div>
				<div className="flex flex-col col-span-2 items-center bg-[#484848] p-4 rounded-lg">
					<p className="font-[700] text-[18px]">${formatNumber(parseNum(userData.userStakeData?.total_staked) * launchPrice, 0, 2)}</p>
					<p className="text-[13px]">{t("Stake.title-launch-price")}</p>
				</div>
			</div>
			<div className="flex flex-col gap-1">
				<div className="bg-[#484848] rounded-xl px-3 py-1">
					<p className="text-[12.5px] font-[400] text-[#DCDCDC]">
						{t("Stake.tokens-to-stake")}
					</p>
					<input
						className="inputbuy1 outline-none bg-[transparent] text-[22px] text-[#ffff] "
						value={inputValue}
						onBlur={() => {
						if (inputValue === "") setInputValue("0")
						}}
						onChange={(e) => {
							const partialNumRegex = /^\d*\.?\d*$/
							let val = e.target.value
							if (!partialNumRegex.test(val)) {
								val = inputValue
								e.target.value = val
								setInputValue(val)
								return
							}
							setInputValue(val)
						}}
					/>
				</div>
				<div className="flex">
					<button
						className="mr-auto px-3 py-1.5 bg-[#484848] text-sm rounded-lg transition-colors cursor-pointer hover:bg-[#404040]"
						onClick={() => setInputValue(formatPrecision(parseNum(userData.userStakeData?.total_can_stake)))}
					>
						{t("Stake.available", {amount: formatNumber(parseNum(userData.userStakeData?.total_can_stake))})}
					</button>
					<button
						className="ml-auto px-3 py-1.5 bg-[#484848] text-sm rounded-lg transition-colors cursor-pointer hover:bg-[#404040]"
						onClick={() => setInputValue(formatPrecision(parseNum(userData.user?.total_tokens)))}
					>
						{t("Stake.max", {amount: formatNumber(parseNum(userData.user?.total_tokens))})}
					</button>
				</div>
			</div>
			<div className="flex gap-2">
				<button
					className="text-[#000] bg-[#FBDA19] hover:bg-[transparent] w-[100%] border border-[#FBDA19] rounded-[40px] px-[24px] py-[8px] text-[18px] font-[600] hover:text-[#fff]"
					onClick={stake}
				>
					{t("Stake.stake")}
				</button>
				<button
					className="text-[#000] bg-[#FBDA19] hover:bg-[transparent] w-[100%] border border-[#FBDA19] rounded-[40px] px-[24px] py-[8px] text-[18px] font-[600] hover:text-[#fff]"
					onClick={unstake}
				>
					{t("Stake.unstake")}
				</button>
			</div>
		</div>
	)
}

export default StakeTab