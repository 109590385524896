import React from "react"
import QRCode from "./QRCode"
import copyIcon from "../assets/svgs/copy.svg"
import closeIcon from "../assets/svgs/close.svg"
import toast from "react-hot-toast"
import { formatNumber } from "../presale-gg/utils"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

/**
 * @callback onClose
 * @returns {unknown}
 */


/**
 * @param {Object} props
 * @param {import("../presale-gg/types/api.types").API.Transaction} props.transaction
 * @param {boolean} props.open
 * @param {onClose} props.onClose
 * @returns {JSX.Element}
 */
export const TransactionModal = (props) => {
	return (
		<div className={clsx("flex flex-col items-center justify-center fixed top-0 left-0 w-full h-full transition-opacity z-[100]", {["opacity-0 pointer-events-none"]: !props.open})}>
			<div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50" onClick={props.onClose} />
			<div className="flex flex-col gap-4 relative bg-[#161616] p-4 rounded-[25px] border-[#fff] border text-[#fff] w-[calc(100%-2rem)] max-w-[30rem] max-h-[calc(100%-2rem)] overflow-auto">
				<h1 className="text-[1.5rem] font-[700] text-center">Transaction</h1>
				<button className="absolute top-3 right-3 hover:bg-[rgba(255,255,255,0.15)] p-2 rounded-full transition-colors" onClick={props.onClose}>
					<img src={closeIcon} alt="Close" />
				</button>
				<div className="flex gap-4 flex-col sm:flex-row">
					<QRCode value={props.transaction.pay_address} className="w-[10rem] h-[10rem]" />
					<div className="flex flex-col flex-1 gap-4">
						<CopyInput
							label={`Pay Amount ${props.transaction.pay_currency.toUpperCase()}`}
							value={formatNumber(props.transaction.pay_amount, 0, 7)}
						/>
						<CopyInput
							label="Payment ID"
							value={props.transaction.payment_id}
						/>
					</div>
				</div>
				{props.transaction.payin_extra_id && (
					<>
						<CopyInput
							label="Destination Tag"
							value={props.transaction.payin_extra_id}
						/>
						<p className="leading-[1.2em]">
							You <span className="font-bold">must include</span> the destination tag in the transaction or you will not receive your tokens
						</p>
					</>
				)}
				<CopyInput
					label="Payment Address"
					value={props.transaction.pay_address}
				/>
				<p className="leading-[1.2em]">
					Pay <span className="font-bold">{formatNumber(props.transaction.pay_amount, 0, 7)}</span>{" "}
					of <span className="font-bold">{props.transaction.pay_currency.toUpperCase()}</span>{" "}
					on the <span className="font-bold">{props.transaction.network.toUpperCase()}</span>{" "}
					network to the address above to confirm the payment.
					<br />
					<br />
					The tokens will automatically be deposited upon received payment. Note that it can take between 10 minutes and 1 hour for transactions to go through.
					<br />
					<br />
					Refresh the page to check your balance.
				</p>
			</div>
		</div>
	)
}

/**
 * @param {Object} props
 * @param {string} props.value
 * @param {string} props.label
*/

export const CopyInput = (props) => {
	const { t } = useTranslation()
	const copy = () => {
		try {
			navigator.clipboard.writeText(props.value)
			toast(t("Messages.copied"))
		} catch (err) {
			toast.error(t("Messages.copy-error"))
		}
	}

	return (
		<div className="flex gap-4 bg-[#2a2a2a] rounded-xl px-3 py-1 items-center">
			<div className="flex flex-col flex-1">
				<p className="text-[#8D8D8D] text-[12px]">{props.label}</p>
				<input
					size="1"
					className="bg-transparent text-[26px] outline-none"
					value={props.value}
					readOnly
				/>
			</div>
			<button
				className="w-10 h-10 rounded-full hover:bg-[rgba(255,255,255,0.1)] p-2 transition-bg"
				onClick={copy}
			>
				<img src={copyIcon} alt="Copy" className="translate-x-0.5" />
			</button>
		</div>
	)
}