import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import ftricn from "../assets/svgs/footericn.svg";
import ftricn1 from "../assets/svgs/ftricn (1).svg";
import ftricn2 from "../assets/svgs/ftricn (2).svg";
import ftricn3 from "../assets/svgs/ftricn (3).svg";
import ftricn4 from "../assets/svgs/ftricn (4).svg";
function Footer() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getAnchorHref = () => {
    return screenSize >= 768 ? "#hero" : "#herosec";
  };

  const handlecopyClick = () => {
    console.log("Image clicked!");
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy URL.");
      });
  };
  return (
    <div className="bg-[#000] 2xl:py-[3rem] xl:py-[3rem] lg:py-[3rem] md:py-[3rem] sm:py-[2rem] py-[2rem]">
            <ToastContainer />
      <div className="space-y-[2rem] flex justify-center items-center flex-col max-w-[1200px] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] m-auto">
        <div className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden justify-center">
          <img src={ftricn} alt="" />
        </div>
        <div className="2xl:block xl:block lg:block md:block sm:hidden hidden">
          <h3 className="text-[39px] text-[#fff] text-center font-[400] leading-[120%]">
            {t("Footer.footertitle")} <br />
            {t("Footer.footertitle2")}
          </h3>
        </div>
        <div className="2xl:hidden  space-y-[20px] xl:hidden lg:hidden md:hidden sm:block block w-[80%] m-auto">
          <h3 className="text-[30px] text-center text-[#fff] text-center font-[400] leading-[110%]">
            {t("Footer.footertitle")} <br />
          </h3>
          <h3 className="text-[30px] text-center text-[#fff] text-center font-[400] leading-[110%]">
            {t("Footer.footertitle2")}
          </h3>
        </div>
        <div className="w-[90%] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[90%] 2xl:ml-auto  xl:ml-auto lg:ml-auto md:ml-auto sm:ml-0 ml-0 ">
          <div className="2xl:space-y-[30px] xl:space-y-[30px] lg:space-y-[30px] md:space-y-[30px] sm:space-y-[30px] space-y-[30px] w-[100%] flex-col flex  2xl:justify-between xl:justify-between lg:justify-between md:justify-between sm:justify-center justify-center  items-center">
            <a href={getAnchorHref()}>
              <button className="ftbutton hover:bg-[transparent] border border-[#FBDA19] rounded-[40px] px-[40px] py-[8px] text-[18px] font-[700] hover:text-[#fff]">
                {t("Footer.btn")}
              </button>
            </a>
            <div className="2xl:space-x-6 xl:space-x-6 lg:space-x-6 md:space-x-6 sm:space-x-5 space-x-5 flex items-center ">
              
              <button onClick={handlecopyClick}>
              <img
                className="2xl:h-[32px] xl:h-[32px] lg:h-[32px] md:h-[32px] sm:h-[28px] h-[28px]"
                src={ftricn4}
                alt=""
              />
              </button>
              <a
                href=" https://t.me/dogekombatgame_chat"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="2xl:h-[32px] xl:h-[32px] lg:h-[32px] md:h-[32px] sm:h-[28px] h-[28px] "
                  src={ftricn2}
                  alt=""
                />
              </a>
              <a
                href="https://x.com/DogeKombatGame"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="2xl:h-[32px] xl:h-[32px] lg:h-[32px] md:h-[32px] sm:h-[28px] h-[28px] "
                  src={ftricn3}
                  alt=""
                />
              </a>
              <a
                href="https://www.instagram.com/dogekombatgame/ "
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="2xl:h-[32px] xl:h-[32px] lg:h-[32px] md:h-[32px] sm:h-[28px] h-[28px] "
                  src={ftricn1}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="w-[80%] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block justify-center items-center 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[0.5rem] space-y-[0.5rem] 2xl:space-x-[3rem] xl:space-x-[3rem] lg:space-x-[2rem] md:space-x-[1rem] sm:space-x-[0rem] space-x-[0rem]">
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#whatisdoge"
          >
            {t("Footer.casinolink1")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#howtobuy"
          >
            {t("Footer.casinolink2")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#whitepapersec"
          >
            {t("Footer.casinolink3")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#tokenomics"
          >
            {t("Footer.casinolink4")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#roadmapsec"
          >
            {t("Footer.casinolink5")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#faqs"
          >
            {t("Footer.casinolink6")}
          </a>
        </div>
        <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:flex flex justify-center">
          <img src={ftricn} alt="" />
        </div>
        <div className="2xl:w-[80%] xl:w-[80%] lg:w-[100%] md:w-[100%] sm:w-[80%] w-[80%] m-auto">
          <p className="text-[14px] font-[400] text-[#B4A5A5] text-center text-[#B4A5A5]">
            {t("Footer.footerpara")}
          </p>
        </div>
        <div className="w-[80%] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden justify-center items-center 2xl:space-x-[2.5rem] xl:space-x-[2.5rem] lg:space-x-[2rem] md:space-x-[1rem] sm:space-x-[0rem] space-x-[0rem]">
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#whatisdoge"
          >
            {t("Footer.casinolink1")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#howtobuysec"
          >
            {t("Footer.casinolink2")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#whitepaper"
          >
            {t("Footer.casinolink3")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#tokenomics"
          >
            {t("Footer.casinolink4")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#roadmap"
          >
            {t("Footer.casinolink5")}
          </a>
          <a
            className="text-[16px] font-[400] block text-center text-[#fff]"
            href="#faqs"
          >
            {t("Footer.casinolink6")}
          </a>
        </div>

        <div>
          <p className="text-[12px] font-[400] text-center text-[#fff]">
            {t("Footer.casinoCopyright")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;