import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


import tapwapimg from "../assets/images/wayimg.png"
import unicn1 from "../assets/svgs/unicons (1).svg"
import unicn2 from "../assets/svgs/unicons (2).svg"
import unicn3 from "../assets/svgs/unicons (3).svg"
import unicn4 from "../assets/svgs/unicons (4).svg"
import unicn5 from "../assets/svgs/unicons (5).svg"
import unicn6 from "../assets/svgs/unicons (6).svg"


const TapYourWaySec = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const { t } = useTranslation();
    useEffect(() => {
      const handleResize = () => {
        setScreenSize(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const getAnchorHref = () => {
      return screenSize >= 768 ? "#hero" : "#herosec";
    };
  

    return (
       <>
       <div className="tapbggr 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden items-end max-w-[1440px] mx-auto">
        <div className="2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[90%] w-[90%] max-w-[1200px] mx-auto pb-[5.5rem] space-y-[3rem] ">
        <div className="bg-[#fff] px-[3rem] pb-[3rem] pt-[2rem] rounded-[41px] space-y-[20px]">
            <h2 className="2xl:w-[90%] text-[104px] leading-[115%] font-[700] tracking-[-1.65px]">{t("TapYourSec.title")}</h2>
            <div className="flex space-x-[3rem]">
                <div className="w-[50%] flex flex-col justify-between mr-auto">
                    <p className="text-[22px] font-[400] ">{t("TapYourSec.para1")}</p>
                    <a href={getAnchorHref()}>
                    <button className="bg-[#FBD914] w-[200px] text-[18px] h-[50px] rounded-[50px] font-[700] hover:text-[#000] border border-[#FBD914] hover:bg-[transparent]">{t("TapYourSec.btn")}</button>
                    </a>
                </div>
                <div className="w-[38%]">
                    <img src={tapwapimg} alt="" />
                </div>
            </div>
        </div>

       </div>
        </div>
        <div className="relative z-[1] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block items-end max-w-[1440px] mx-auto">
            <div className="h-[200px]  w-[100%] ">
                <div className="tapbggrmob h-[500px] top-[0%] absolute w-[100%] z-[-1]"></div>
            </div>
        <div className="2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[90%] w-[90%] max-w-[1200px] mx-auto pb-[5.5rem] space-y-[3rem] ">
        <div className="bg-[#fff] px-[2rem] pb-[3rem] pt-[2rem] rounded-[41px] space-y-[20px]">
            <h2 className="text-[46px] leading-[105%] text-center font-[700] tracking-[-1.65px]">{t("TapYourSec.title")}</h2>
            <div className=" space-y-[2rem]">
                <div className="w-[100%]">
                    <p className="text-[22px] text-center font-[400] ">{t("TapYourSec.para1")}</p>
                  
                </div>
                <div className="w-[100%]">
                    <img src={tapwapimg} alt="" />
                </div>
                <div className="flex justify-center">
                    <a href={getAnchorHref()} >
                    <button className="bg-[#FBD914] w-[200px] text-[18px] h-[50px] rounded-[50px] font-[700] hover:text-[#000] border border-[#FBD914] hover:bg-[transparent]">{t("TapYourSec.btn")}</button>
                    </a>
                    </div>
            </div>
        </div>

       </div>
        </div>
        <div className="unlockbgimg 2xl:block xl:block lg:block md:block sm:hidden hidden max-w-[1440px] mx-auto ">
        <div className="2xl:w-[86%] relative xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[90%] w-[90%] max-w-[1200px] mx-auto pb-[4rem] space-y-[3rem] ">
            <div className="flex space-x-[2rem] absolute top-[-100%]">
                <div className="w-[50%] space-y-[15px] unlockbg p-[3rem] ">
                    <h3 className="text-[#fff] text-[40px] font-[900] tracking-[-0.5px]">{t("UnlockSec.title")}</h3>
                    <p className="pb-[15px] text-[20px] text-[#fff] font-[400] ">{t("UnlockSec.para1")}</p>
                    <a href={getAnchorHref()} >
                    <button className="bg-[#FBD914] w-[200px] h-[50px] rounded-[50px] text-[18px] font-[700] hover:text-[#fff] border border-[#FBD914] hover:bg-[transparent]">{t("UnlockSec.btn")}</button>
                    </a>
                </div>
                <div className="w-[48%] unlockbg flex flex-col justify-center py-[2rem] px-[3rem] space-y-[4rem]">
                   <div className="flex justify-between items-center">
                   <div className="flex flex-col items-center space-y-[20px]">
                    <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img className="" src={unicn1} alt="" />
                    </div>
                        <p className="capitalize text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Daily")}</p>
                    </div>
                    <div className="flex flex-col items-center space-y-[20px]">
                        <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img className="" src={unicn2} alt="" />
                        </div>
                        <p className="capitalize text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Combos")}</p>
                    </div>
                    <div className="flex flex-col items-center space-y-[20px]">
                        <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img className="" src={unicn3} alt="" />
                        </div>
                        <p className="capitalize text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Referrals")}</p>
                    </div>
                   </div>
                   <div className="flex justify-between items-center">
                   <div className="flex flex-col items-center space-y-[20px]">
                    <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img className="" src={unicn4} alt="" />
                    </div>
                        <p className="capitalize text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Levels")}</p>
                    </div>
                    <div className="flex flex-col items-center space-y-[20px]">
                        <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img className="" src={unicn5} alt="" />
                        </div>
                        <p className="capitalize text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Income")}</p>
                    </div>
                    <div className="flex flex-col items-center space-y-[20px]">
                        <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img className="" src={unicn6} alt="" />
                        </div>
                        <p className="capitalize text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Airdrops")}</p>
                    </div>
                   </div>
                    
                </div>
            </div>

       </div>
        </div>
      <div className="relative z-[1] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block max-w-[1440px] mx-auto w-[100%] ">
        <div className="2xl:w-[86%]  xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[94%] w-[94%] max-w-[1200px] mx-auto pb-[0rem] space-y-[1rem] ">
            <div className="2xl:flex xl:flex lg:flex unlockbg  py-[2rem] md:flex sm:block block space-y-[2rem] ">
                <div className="w-[100%] space-y-[15px]  ">
                    <h3 className="text-[#fff] text-[46px] text-center leading-[105%] tracking-[-1.65px] font-[900]">{t("UnlockSec.title")}</h3>
                    <p className="px-[1.5rem] text-[20px] text-center text-[#fff] font-[400] ">{t("UnlockSec.para1")}</p>
                 
                </div>
                <div className="w-[100%] px-[2rem]  flex justify-center py-[2rem]  space-x-[3rem]">
                   <div className="block space-y-[2rem] ">
                   <div className="flex flex-col items-center space-y-[20px]">
                   <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img src={unicn1} alt="" />
                        </div>
                        <p className="capitalize text-center text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Daily")}</p>
                    </div>
                    <div className="flex flex-col items-center space-y-[20px]">
                         <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img src={unicn2} alt="" />
                        </div>
                        <p className="capitalize text-center text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Combos")}</p>
                    </div>
                    <div className="flex flex-col items-center space-y-[20px]">
                         <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img src={unicn3} alt="" />
                        </div>
                        <p className="capitalize text-center text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Referrals")}</p>
                    </div>
                   </div>
                   <div className="block space-y-[2rem] ">
                   <div className="flex flex-col items-center space-y-[20px]">
                         <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img src={unicn4} alt="" />
                        </div>
                        <p className="capitalize text-center text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Levels")}</p>
                    </div>
                    <div className="flex flex-col items-center space-y-[20px]">
                         <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img src={unicn5} alt="" />
                        </div>
                        <p className="capitalize text-center text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Income")}</p>
                    </div>
                    <div className="flex flex-col items-center space-y-[20px]">
                         <div className="w-[92px] h-[93px] rounded-[100%] bg-[#ffffff2e] flex justify-center items-center">
                        <img src={unicn6} alt="" />
                        </div>
                        <p className="capitalize text-center text-[#fff] text-[18px] font-[600]">{t("UnlockSec.Airdrops")}</p>
                    </div>
                   </div>
                    
                </div>
                <div className="flex justify-center">
                    <button className="bg-[#FBD914] w-[200px] h-[50px] rounded-[50px] font-[700] hover:text-[#fff] border border-[#FBD914] hover:bg-[transparent]">{t("UnlockSec.btn")}</button>
                    </div>
            </div>
            <div className="h-[800px]">
            <div className="unlockbgimg z-[-1] left-0 absolute w-[100%] bottom-[4%]">
            </div>
            </div>

       </div>
        </div>
       </>
    )
}
export default TapYourWaySec;