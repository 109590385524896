import React from "react";
import { useTranslation } from "react-i18next";


import preimg from "../assets/images/preimg_new.png"




function PreSaleSec() {
  const { t } = useTranslation();

  return (
    <div className="2xl:w-[86%]  xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[90%] w-[90%] max-w-[1200px] mx-auto py-[4rem] ">
        <div className="pregbgr 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[1rem] space-y-[1rem] 2xl:px-[2.5rem] xl:px-[2.5rem] lg:px-[2.5rem] md:px-[2.5rem] sm:px-[1.5rem] px-[1.5rem] 2xl:py-[3rem] xl:py-[3rem] lg:py-[3rem] md:py-[3rem] sm:py-[2rem] py-[2rem]  2xl:flex xl:flex lg:flex md:flex sm:block block justify-between">
            <div className="space-y-[20px] 2xl:w-[65%] xl:w-[62%] lg:w-[62%] md:w-[62%] sm:w-[100%] w-[100%]">
            <h3 className="2xl:block xl:block lg:block md:block sm:hidden hidden leading-[120%] text-[46px]  font-[700] text-[#FBD914] 2xl:text-start xl:text-start lg:text-start md:text-start sm:text-center text-center">{t("WeDoing.mainTitle")}</h3>
            <h3 className=" leading-[110%] text-[46px] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block font-[700] text-[#FBD914] 2xl:text-start tracking-[-1.65px] xl:text-start lg:text-start md:text-start sm:text-center text-center">{t("WeDoing.mainTitlemob1")} <br />{t("WeDoing.mainTitlemob2")} <br /> {t("WeDoing.mainTitlemob3")}</h3>
            <p className="text-[22px] font-[400] leading-[120%] text-[#fff] 2xl:text-start xl:text-start lg:text-start md:text-start sm:text-center text-center">{t("WeDoing.Para")}</p>
            </div>
            <div className="2xl:w-[28%] xl:w-[28%] lg:w-[28%] md:w-[28%] sm:w-[100%] w-[100%]  relative">
                <img src={preimg} className="2xl:absolute xl:absolute lg:absolute md:absolute sm:none none 2xl:top-[-80%] xl:top-[-80%] lg:top-[0%] md:top-[0%] sm:top-[-80%] top-[-80%] right-[15%]"  alt="" />
            </div>
        </div>
    </div>
  );
}

export default PreSaleSec;
