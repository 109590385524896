import React from "react";
import { useTranslation } from "react-i18next";

import cld3 from "../assets/images/cldmob (1).png"
import cld2 from "../assets/images/cldmob (2).png"
import cld1 from "../assets/images/cldmob (3).png"
import icondoge from "../assets//images/icondoe.png"
import imgvirl from "../assets/images/imgvirl.png"
import imgvirlmob from "../assets/svgs/mobimgs.svg"




function ViralEngineSec() {
  const { t } = useTranslation();

  return (
   <div className="relative w-[100%] max-w-[1440px] m-auto">
     <div className="2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[90%] w-[90%] max-w-[1200px] mx-auto pt-[3rem] 2xl:pb-[3rem] xl:pb-[3rem] lg:pb-[3rem] md:pb-[3rem] sm:pb-[0rem] pb-[0rem] bg-[#FBD914]  rounded-[37px]">
        <div className="relative  max-w-[900px] m-auto 2xl:space-y-[10px] xl:space-y-[10px] lg:space-y-[10px] md:space-y-[10px] sm:space-y-[20px] space-y-[20px]">
            <h3 className="text-center 2xl:leading-[120%] xl:leading-[120%] 2xl:px-0 xl:px-0 lg:px-0 md:px-0 sm:px-3 px-5 lg:leading-[120%] md:leading-[120%] sm:leading-[120%] leading-[108%] text-[46px] font-[700] text-[#000]">{t("ViralEngine.mainTitle")}</h3>
            <p className="relative z-[5] text-[22px] 2xl:max-w-[100%] xl:max-w-[100%] lg:max-w-[100%] md:max-w-[100%] sm:max-w-[310px] max-w-[310px] m-auto font-[400] leading-[120%] text-[#000] text-center">{t("ViralEngine.card2Para")}</p>
            <img className="absolute bottom-[-2%] left-[-6%] w-[120px] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block" src={cld2} alt="" />
            <img className="absolute top-[17%] right-[-6%] w-[120px] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block" src={cld1} alt="" />
        </div>
       
        <div className="2xl:h-[740px] xl:h-[740px] lg:h-[560px] md:h-[560px] sm:h-[100%] h-[100%] flex justify-center 2xl:pb-0 xl:pb-0 lg:pb-0 md:pb-0 sm:pb-[5rem] pb-[5rem]">
            <img className="2xl:block xl:block lg:block md:block sm:hidden hidden 2xl:w-[95%] xl:w-[95%] lg:w-[96%] md:w-[96%] sm:w-[96%] w-[96%] absolute left-0 bottom-[10%] 
            " src={imgvirl} alt="" />
             <img className="mr-[20%] 2xl:hidden relative z-[1] xl:hidden lg:hidden md:hidden sm:block block 2xl:w-[95%] xl:w-[95%] lg:w-[96%] md:w-[96%] sm:w-[100%] w-[100%] 
            " src={imgvirlmob} alt="" />
        </div>
        <img className="absolute bottom-[-2%] right-[0%] w-[293px] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block" src={cld3} alt="" />
        <img className="absolute bottom-[-9%] left-[15%] w-[293px] z-[1]" src={icondoge} alt="" />
    </div>
   </div>
  );
}

export default ViralEngineSec;
