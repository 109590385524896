import React from "react";
import { useTranslation } from "react-i18next";

import preeimg1 from "../assets/images/reeee (1).png";
import preeimg2 from "../assets/images/reeee (2).png";
import preeimg3 from "../assets/images/reeee (3).png";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';


const preData = [
    {
        id:1,
        title:"ReleasesSec.cardtitle1",
        para:"ReleasesSec.cardpara1",
        img:preeimg1,
    },
    {
        id:2,
        title:"ReleasesSec.cardtitle2",
        para:"ReleasesSec.cardpara2",
        img:preeimg2,
    },
    {
        id:3,
        title:"ReleasesSec.cardtitle3",
        para:"ReleasesSec.cardpara3",
        img:preeimg3,
    },
    // {
    //     id:4,
    //     title:"ReleasesSec.cardtitle4",
    //     para:"ReleasesSec.cardpara4",
    //     img:preeimg1,
    // },
]

function PressReleaseSec() {
  const { t } = useTranslation();

  return (
    <div className="2xl:max-w-[1200px]  xl:max-w-[1400px]  lg:max-w-[1400px] md:max-w-[1400px] sm:max-w-[1400px]  max-w-[1400px]  space-y-[3rem] 2xl:w-[100%] xl:w-[91%] lg:w-[90%] md:w-[90%] sm:w-[9%] w-[94%]  2xl:ml- xl:ml-auto lg:ml-auto md:ml-auto sm:ml-auto ml-auto  pb-[4rem] 2xl:m-auto">
     <div>
        <h2 className="2xl:text-[104px] xl:text-[104px] lg:text-[104px] md:text-[104px] sm:text-[46px] text-[46px] text-[#FBD914] font-[700] leading-[115%] tracking-[-1.65px]">{t("ReleasesSec.title")}</h2>
        <p className="text-[#fff] text-[18px] font-[400]">{t("ReleasesSec.para")}</p>
     </div>
     <div>
     <Swiper 
    breakpoints={{
        320: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        868: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3.3,
          spaceBetween: 30,
        },
      }}
     className="mySwiper">
        {
            preData.map((item)=>(
        <SwiperSlide>
        <div className="rounded-[28px] presal h-[420px] ">
            <img src={item.img} className="w-[100%]" alt="" />
            <div className="px-5 pt-5 flex flex-col justify-between items-center space-y-[15px]">
                <h4 className="text-[18.53px] text-[#fff] font-[400]">{t(item.title)}</h4>
                <p className="text-gray-300 text-[13.47px] text-[400]">{t(item.para)}</p>
            </div>
        </div>
        </SwiperSlide>
            ))
        }
      </Swiper>
     </div>
    </div>
  );
}

export default PressReleaseSec;
