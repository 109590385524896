import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import BuyTab from "./Tabs/BuyTab"
import StakeTab from "./Tabs/StakeTab"
import RankTab from "./Tabs/RankTab"

import Soldicn from "../../assets/svgs/sold_new.svg";
import buyicn11 from "../../assets/svgs/buyicn (11).svg";
import buyicn12 from "../../assets/svgs/buyicn (12).svg";
import heroimg4 from "../../assets/images/sold.png";
import { Web3Context } from "../../presale-gg/context/Web3Context";
import TransactionsTab from "./Tabs/TransactionsTab";
import { ApiContext } from "../../presale-gg/context/ApiContext";
import { useTranslation } from "react-i18next";

const tabs = [
  {label: "ProductDetails.tab1", id: "buy", icon: () => <span className="bg-white text-black rounded-full w-4 h-4 flex items-center justify-center text-sm mr-[-4px]">$</span>, component: () => <BuyTab />},
  {label: "ProductDetails.tab4", id: "rank", icon: () => (
    <svg fill="currentColor" stroke-width="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="1em" width="1em" data-darkreader-inline-fill="" data-darkreader-inline-color=""><path fill="#fff" d="M13 3V1H3v2H0v2a3 3 0 0 0 3.9 2.862A5 5 0 0 0 7 9.899v3.1H6a2 2 0 0 0-2 2h8a2 2 0 0 0-2-2H9v-3.1a5.003 5.003 0 0 0 3.1-2.037A3 3 0 0 0 16 5V3h-3zM3 6.813A1.815 1.815 0 0 1 1.187 5V4H3v1c0 .628.116 1.229.327 1.782-.106.019-.216.03-.327.03zM14.813 5a1.815 1.815 0 0 1-2.14 1.783A4.994 4.994 0 0 0 13 5.001v-1h1.813v1z"></path></svg>
  ), component: () => <RankTab />},
  {label: "ProductDetails.tab2", id: "stake", icon: () => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50" className="w-4 h-4">
      <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.300781 20 6 21.300781 6 23 L 6 47 C 6 48.699219 7.300781 50 9 50 L 41 50 C 42.699219 50 44 48.699219 44 47 L 44 23 C 44 21.300781 42.699219 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 25 30 C 26.699219 30 28 31.300781 28 33 C 28 33.898438 27.601563 34.6875 27 35.1875 L 27 38 C 27 39.101563 26.101563 40 25 40 C 23.898438 40 23 39.101563 23 38 L 23 35.1875 C 22.398438 34.6875 22 33.898438 22 33 C 22 31.300781 23.300781 30 25 30 Z" fill="white"></path>
    </svg>
  ), component: () => <StakeTab />},
  {label: "ProductDetails.tab3", id: "history", icon: () => (
    <svg fill="#FFFFFF" width="800px" height="800px" viewBox="0 0 512 512" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
      <path d="M478,256A222,222,0,0,1,99,413,220.55,220.55,0,0,1,34,256H63.92c0,105.91,86.17,192.08,192.08,192.08S448.08,361.91,448.08,256,361.91,63.92,256,63.92A191.8,191.8,0,0,0,116.58,124H175v29.92H70V49H99.93v49.3A221.93,221.93,0,0,1,478,256ZM250,139V280H373V250H280V139Z" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="12"/>
    </svg>
  ), component: () => <TransactionsTab />},
]

const BuyWidget = () => {
  const apiData = useContext(ApiContext)
  const [activeTabId, setActiveTabId] = useState("buy");
  const web3Data = useContext(Web3Context)

  const activeTab = useMemo(() => {
    return tabs.find((tab) => tab.id === activeTabId)
  }, [activeTabId])

  const handleTabClick = (tabId) => {
    setActiveTabId(tabId);
  };
  const ActiveComp = activeTab.component
  const disabledTabs = web3Data.account ? [] : ["stake", "history", "rank"]
  const { t } = useTranslation()

  useEffect(() => {
    if (disabledTabs.includes(activeTabId)) setActiveTabId("buy")
  }, [disabledTabs])

  return (
    <div className="max-w-[456px] relative w-[100%] z-[10] md:-mx-4" style={{colorScheme: "dark"}}>
      <div className="mt-10 w-[100%]">
        <div className="w-[100%] space-y-[20px] relative">
          <div className="flex flex-col space-y-[15px] px-5 py-5 relative herobggr w-[100%] min-h-[690px]">
            <div className="bg-[red] absolute top-[-20px] py-[8px] left-[50%] -translate-x-1/2 border border-[#fff] max-w-[160px] w-[100%] m-auto  rounded-[50px]">
              <p className="text-[12px] text-[#fff] font-[700] text-center">
                {!apiData.presaleEnded && (
                  <span className="text-[40px] mb-[3px] leading-[0px] blinking-dot">.</span>
                )}
                {" "}
                {apiData.presaleEnded ? t("ProductDetails.preended") : t("ProductDetails.prelive")}
              </p>
            </div>
            <div className="rounded-3xl border-white border flex justify-around items-center px-1 py-1">
              {tabs.map((tab) => {
                const Icon = tab.icon
                return (
                  <button
                    className={`flex relative group justify-center items-center space-x-2 w-full rounded-3xl py-0.5 cursor-pointer text-center text-white ${
                      activeTabId === tab.id
                        ? "bg-[#676767]"
                        : "bg-transparent"
                    }`}
                    disabled={disabledTabs.includes(tab.id)}
                    onClick={() => {
                      if (disabledTabs.includes(tab.id)) return;
                      handleTabClick(tab.id)
                    }}
                  >
                    
                    <Icon />
                    <p className="text-[15px] text-[#fff]">{t(tab.label)}</p>
                    {disabledTabs.includes(tab.id) && (
                      <div className="opacity-0 pointer-events-none absolute top-full left-1/2 -translate-x-1/2 bg-[#2a2a2a] text-[#fff] p-2 rounded-lg custom-shadow whitespace-nowrap transition-opacity group-hover:opacity-100">
                        {t("ProductDetails.connect-wallet")}
                      </div>
                    )}
                  </button>
                )
              })}
            </div>
            <ActiveComp />
          </div>
        </div>
        <div className="pt-[1.7rem]">
          <a href="https://github.com/solidproof/Projects/blob/main/2024/DogeKombat/Solana_Audit_SolidProof_DOGE%20KOMBAT.pdf" target="_blank" className="flex items-center justify-center space-x-2">
          <p className="text-[#fff] text-[14px] underline leading-[21px] font-[400]"> {t("ProductDetails.para3last")}</p>
              <img src={Soldicn} alt="" className="h-[22px]"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default BuyWidget