import React from "react";
import rdicn from "../assets//images/msicn.png";
import cardsif from "../assets/svgs/cardsif_new_2.0.svg";
import cardsifmob from "../assets/svgs/cardsifmob_new.svg";
import dogicon from "../assets/svgs/dogelogo.svg";
import { useTranslation } from "react-i18next";


function IfYouMissedSec() {
  const { t } = useTranslation();

  return (
    <div className="bggrmiss relative z-[1] max-w-[1440px] w-[100%] m-auto">
      <img
        src={rdicn}
        className=" 2xl:block xl:block lg:block md:block sm:hidden hidden max-w-[235px] absolute top-[10%] left-[0%]"
        alt=""
      />

      <div className="2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[94%] w-[94%] max-w-[1200px] mx-auto 2xl:pb-[4rem] xl:pb-[4rem] lg:pb-[4rem] md:pb-[4rem] sm:pb-[0rem] pb-[3rem] 2xl:pt-[4rem] xl:pt-[4rem] lg:pt-[4rem] md:pt-[4rem] sm:pt-[0rem] pt-[1rem]   rounded-[16px]">
        <div className="max-w-[900px] m-auto 2xl:space-y-[1rem] xl:space-y-[1rem] lg:space-y-[1rem] md:space-y-[1rem] sm:space-y-[1rem] space-y-[1rem]">
          <h3 className="2xl:block xl:block lg:block md:block sm:hidden hidden text-center 2xl:px-0 xl:px-0 lg:px-0 md:px-0 sm:px-5 px-3 2xl:tracking-[0px] xl:tracking-[0px] lg:tracking-[0px] md:tracking-[0px] sm:tracking-[-1.65px] tracking-[-1.65px] 2xl:leading-[120%] xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[110%] leading-[110%] text-[46px] font-[700] text-[#FBD914]">
          {t("GemsSec.title")} <br /> {t("GemsSec.title2")}
          </h3>
          <h3 className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block text-center  2xl:tracking-[0px] xl:tracking-[0px] lg:tracking-[0px] md:tracking-[0px] sm:tracking-[-1.65px] tracking-[-1.65px] 2xl:leading-[120%] xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[110%] leading-[110%] text-[46px] font-[700] text-[#FBD914]">
          {t("GemsSec.title1mob")} <br /> {t("GemsSec.title2mob")} <br />{t("GemsSec.title3mob")}<br />  {t("GemsSec.title4mob")}
          </h3>
          <p className="text-[22px] font-[400] leading-[120%] text-[#fff] text-center">
          {t("GemsSec.para1")}
          </p>
        </div>

        <div className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden ifyoubggr  relative mt-[10rem] border-[3px] border-[#333333db] rounded-[30px] relatave  justify-center  items-center ">
          <img className="absolute z-10 top-[-30%]" src={dogicon} alt="" />
          <div className=" pb-[20px] pt-[7rem] w-[100%]  rounded-[30px]  z-[1] bg-[#ffffff00]">
            <div className=" space-y-[20px]">
              <img className="w-[100%]" src={cardsif} alt="" />
              <p className="text-[14px] text-end px-[1.5rem] text-[#fff]">
              {t("GemsSec.date")}
              </p>
            </div>
          </div>
        </div>
        <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block ifyoubggr  mt-[2rem] border-[3px] border-[#333333db] rounded-[30px] relatave  justify-center  items-center ">
          <div className="py-[0.6rem] pr-[0.6rem]  w-[100%]  rounded-[30px]  bg-[#ffffff00]">
            <div className=" space-y-[20px]">
              <p className="text-[14px] absolute right-[0%] top-[4%] text-end px-[1.5rem] text-[#fff]">
              {t("GemsSec.datemob")} <br /> {t("GemsSec.datemob2")}

              </p>
              <img className="w-[100%]" src={cardsifmob} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="circlebuy2 2xl:block xl:block lg:block md:block sm:hidden hidden absolute bottom-[-2%] left-[-25%] z-[-1]"></div>
      <div className="circlebuymob 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block absolute bottom-[61%] left-[-51%] z-[-1]"></div>
    </div>
  );
}

export default IfYouMissedSec;
