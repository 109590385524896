import React, { useContext } from "react"
import copyIcon from "../assets/svgs/copy.svg"
import closeIcon from "../assets/svgs/close.svg"
import toast from "react-hot-toast"
import clsx from "clsx"
import { UserContext } from "../presale-gg/context/UserContext"
import { useTranslation } from "react-i18next"

/**
 * @callback onClose
 * @returns {unknown}
 */


/**
 * @param {Object} props
 * @param {boolean} props.open
 * @param {onClose} props.onClose
 * @returns {JSX.Element}
 */
export const ReferralLinkModal = (props) => {
	const userData = useContext(UserContext)
	const url = `${new URL(`/?referral_code=${userData.user?.referral_code}`, window.location.origin).toString()}`
	const { t } = useTranslation()

	const copy = () => {
		try {
			navigator.clipboard.writeText(userData.user?.referral_code)
			toast(t("Messages.copied"))
		} catch (err) {
			toast.error(t("Messages.copy-error"))
		}
	}

	const share = async () => {
		const shareItem = {
			text: `Use my referral code "${userData.user?.referral_code}" to get bonus $DOKO on Doge Kombat`,
			title: "Doge Kombat Referral",
			url
		}
		if ("share" in navigator) {
			try {
				await navigator.share(shareItem)
			} catch (err) {
			}
		}
		window.open(
			`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareItem.url)}&text=${encodeURIComponent(shareItem.text)}${shareItem.tags ? `&hashtags=${encodeURIComponent(shareItem.tags.join(", "))}` : ""}`,
			"__blank"
		)
	}

	return (
		<div className={clsx("flex flex-col items-center justify-center fixed top-0 left-0 w-full h-full transition-opacity z-[100]", {["opacity-0 pointer-events-none"]: !props.open})}>
			<div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50" onClick={props.onClose} />
			<div className="flex flex-col gap-4 relative bg-[#161616] p-4 rounded-[25px] border-[#fff] border text-[#fff] max-w-[min(calc(100%-2rem),25rem)] max-h-[calc(100%-2rem)] overflow-auto">
				<h1 className="text-[1.5rem] font-[700] text-center">Referral Link</h1>
				<p className="text-center">
					Share this code to get a <span className="bg-[#20f79b33] text-[#20f79b] px-1 py-0.5 mx-1 text-sm rounded-lg">+5%</span> bonus on buys when people use your referral code
				</p>
				<div className="relative flex items-center bg-[#2a2a2a] p-2 rounded-lg break-all">
					<p className="mx-auto">
						{userData.user?.referral_code}
					</p>
					<button
						className="absolute right-2 top-1/2 -translate-y-1/2 flex w-8 h-8 p-2 rounded-full flex-shrink-0 hover:bg-[rgba(255,255,255,0.15)] transition-colors"
						onClick={copy}
					>
						<img src={copyIcon} alt="Copy" />
					</button>
				</div>
				<button
					className="text-[#000] bg-[#FBDA19] hover:bg-[transparent] border border-[#FBDA19] rounded-[40px] px-[12px] py-[4px] text-[16px] font-[600] min-w-[7.75rem]  h-[2.5rem] text-center hover:text-[#fff]"
					onClick={share}
				>
					Share
				</button>
			</div>
		</div>
	)
}