import React from "react";
import { useTranslation } from "react-i18next";


import imgcard from "../assets/images/imgcard (3).png";
import imgcard2 from "../assets/images/imgcard (1).png";
import imgcard3 from "../assets/images/imgcard (2).png";
import innericn from "../assets/images/inner.png";
import tapicn from "../assets/images/tapicn.png";

function BreakingSec() {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div className="relative max-w-[1200px] space-y-[3rem] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] m-auto  pb-[4rem]">
        <img
          src={tapicn}
          className="w-[290px] 2xl:block xl:block lg:block md:block sm:hidden hidden absolute top-[-40%] left-[-8%]"
          alt=""
        />
        <div className="2xl:h-[680px] xl:h-[680px] lg:h-[680px] md:h-[680px] sm:h-[100%] h-[100%]">
          <div className="breakbgimg 2xl:h-[450px] xl:h-[450px] lg:h-[450px] md:h-[450px] sm:h-[100%] h-[100%] space-y-[30px] px-[2rem] 2xl:pt-[2rem] xl:pt-[2rem] lg:pt-[2rem] md:pt-[2rem] sm:pt-[0rem] pt-[0rem]">
            <h3 className="text-[#FBD914] text-center text-[46px] font-[700] tracking-[-1.65px]">
            {t("BreakingSec.title")}
            </h3>
            <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[1rem] space-y-[1rem]">
              <div className="breakbgimgcard space-y-[15px] p-5 flex flex-col justify-center items-center">
                <div className="2xl:pt-[2rem] xl:pt-[2rem] lg:pt-[2rem] md:pt-[2rem] sm:pt-[0rem] pt-[0rem] pb-[1rem] flex items-end relative justify-center">
                  <img src={imgcard} alt="" />
                  <div className="innerbg  absolute bottom-[12%] 2xl:left-[8%] xl:left-[8%] lg:left-[8%] md:left-[8%] sm:left-[auto] left-[auto] flex items-center justify-center space-x-2 text-center max-w-[250px] w-[100%] px-2 h-[50px] !m-auto">
                    <img src={innericn} alt="" />
                    <p className="text-[20px] font-[700] text-[#fff]">
                    {t("BreakingSec.cardListed")} <span className="text-[#FBD914] text-[20px]">{t("BreakingSec.cardBinance")}
                      </span>
                    </p>
                  </div>
                </div>
                <h4 className="text-[23px] text-[#FBD914] font-[600]">
                {t("BreakingSec.cardtitle1")}
                </h4>
                <p className="text-[12.74px] text-center  text-[#fff] font-[400]">
                {t("BreakingSec.cardpara1")}
                </p>
              </div>
              <div className="breakbgimgcard space-y-[15px] p-5 flex flex-col justify-center items-center">
                <div className="pb-[3rem] flex items-center relative">
                  <img src={imgcard2} alt="" />
                  <div className="innerbg absolute bottom-[11%] left-[13%] text-center max-w-[240px] w-[100%] px-2 h-[70px] m-auto flex items-center justify-center">
                    <p className="text-[20px] font-[700] text-[#fff] leading-[110%]">
                      <span className="text-[#FBD914] text-[20px]">
                      {t("BreakingSec.cardPlayers")} <br />{" "}
                      </span>{" "}
                      {t("BreakingSec.cardCouple")}
                    </p>
                  </div>
                </div>
                <h4 className="text-[23px] text-[#FBD914] font-[600]">
                {t("BreakingSec.cardtitle2")}
                </h4>
                <p className="text-[12.74px] text-center  text-[#fff] font-[400]">
                {t("BreakingSec.cardpara2")}
                </p>
              </div>
              <div className="breakbgimgcard space-y-[15px] p-5 flex flex-col justify-center items-center">
                <div className="relative 2xl:pt-[1rem] xl:pt-[1rem] lg:pt-[1rem] md:pt-[1rem] sm:pt-[0rem] pt-[0rem] pb-[2rem] flex items-center">
                  <img src={imgcard3} alt="" />
                  <div className="innerbg absolute bottom-[10.8%] left-[6%] text-center max-w-[270px] w-[100%]  px-2 h-[70px] m-auto flex items-center justify-center">
                    <p className="text-[20px] font-[700] text-[#fff] leading-[110%]">
                      <span className="text-[#FBD914] text-[20px]">
                      {t("BreakingSec.cardMarket")} <br />{" "}
                      </span>
                      {t("BreakingSec.cardLaunch")}
                    </p>
                  </div>
                </div>
                <h4 className="text-[23px] text-center text-[#FBD914] font-[600]">
                {t("BreakingSec.cardtitle3")}
                </h4>
                <p className="text-[12.74px] text-center text-[#fff] font-[400]">
                {t("BreakingSec.cardpara3")}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-between items-center">
      <button className="bg-[#FBDA19] m-auto max-w-[200px] w-[100%] hover:bg-[transparent] border border-[#FBDA19] rounded-[40px] px-[24px] py-[8px] text-[18px] font-[600] hover:text-[#fff]">BUY $DOKO</button>
      </div> */}
      </div>
    </div>
  );
}

export default BreakingSec;
