import React from "react";
import { useTranslation } from "react-i18next";


import prdicn1 from "../assets/images/prdicn (15).png";
import prdicn2 from "../assets/images/prdicn (14).png";
import prdicn3 from "../assets/images/prdicn (13).png";
import prdicn4 from "../assets/images/prdicn (12).png";
import prdicn5 from "../assets/images/prdicn (11).png";
import prdicn6 from "../assets/images/prdicn (10).png";
import prdicn7 from "../assets/images/prdicn (9).png";
import prdicn8 from "../assets/images/prdicn (8).png";
import prdicn9 from "../assets/images/prdicn (7).png";
import prdicn10 from "../assets/images/prdicn (6).png";
import prdicn11 from "../assets/images/prdicn (5).png";
import prdicn12 from "../assets/images/prdicn (4).png";
import prdicn13 from "../assets/images/prdicn (3).png";
import prdicn14 from "../assets/images/prdicn (2).png";
import prdicn15 from "../assets/images/prdicn (1).png";
import prd_trust from "../assets/svgs/prd_trust.svg";
import prd_w from "../assets/svgs/prd_w2.svg";

const PowrdDataDesktop = [
    {
        id:1,
        img:prdicn1,
    },
    {
        id:2,
        img:prdicn2,
    },
    {
        id:3,
        img:prdicn3,
    },
    {
        id:4,
        img:prdicn4,
    },
    {
        id:5,
        img:prdicn5,
    },
    {
        id:11,
        img:prdicn11,
    },
    // {
    //     id:6,
    //     img:prdicn6,
    // },
    {
        id:7,
        img:prdicn7,
    },
    {
        id:8,
        img:prd_trust,
    },
    {
        id:9,
        img:prd_w,
    },
    // {
    //     id:8,
    //     img:prdicn8,
    // },
    // {
    //     id:9,
    //     img:prdicn9,
    // },
    {
        id:10,
        img:prdicn10,
    },
    
    {
        id:12,
        img:prdicn12,
    },
    {
        id:13,
        img:prdicn13,
    },
    {
        id:14,
        img:prdicn14,
    },
    {
        id:1,
        img:prdicn15,
    },

]

const PowrdDataMobile = [
    {
        id:1,
        img:prdicn1,
    },
    {
        id:2,
        img:prdicn2,
    },
    {
        id:3,
        img:prdicn3,
    },
    {
        id:4,
        img:prdicn4,
    },
    {
        id:5,
        img:prdicn5,
    },
    {
        id:7,
        img:prdicn7,
    },
    
    // {
    //     id:6,
    //     img:prdicn6,
    // },
    {
        id:8,
        img:prd_trust,
    },
    {
        id:9,
        img:prd_w,
    },
    // {
    //     id:8,
    //     img:prdicn8,
    // },
    // {
    //     id:9,
    //     img:prdicn9,
    // },
    {
        id:10,
        img:prdicn10,
    },
    {
        id:11,
        img:prdicn11,
    },
    {
        id:12,
        img:prdicn12,
    },
    {
        id:13,
        img:prdicn13,
    },
    {
        id:14,
        img:prdicn14,
    },
    {
        id:1,
        img:prdicn15,
    },

]

function PoweredSec() {
  const { t } = useTranslation();

  return (
    <div className=" max-w-[1200px] space-y-[3rem] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[94%] w-[94%] m-auto  pb-[4rem] Powered">
     <div className="bg-[#FBD914] rounded-[37px] 2xl:px-[4rem] xl:px-[4rem] lg:px-[4rem] md:px-[4rem] sm:px-[4rem] px-[3rem] pt-[3rem] pb-[4rem] space-y-[2rem] ">
        <h3 className="text-[40px] font-[700] text-center">{t("PoweredBy.title")}</h3>
        {/* For Desktop */}
        <div className="palce-items-center hidden md:grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-2 grid-cols-2 gap-[3rem] justify-center items-center">
           {
               PowrdDataDesktop.slice(0,-4).map((item, index)=>(
                   <div className={`flex justify-center items-center ${index === 14 ? "2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 col-span-2" : ''}`}>
                    <img src={item.img} alt="" />
                </div>
            ))
        }
        </div>
        <div className="w-[80%] mx-auto palce-items-center hidden md:grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-[3rem] justify-center items-center">
           {
               PowrdDataDesktop.slice(-4).map((item, index)=>(
                   <div className={`flex justify-center items-center ${index === 14 ? "2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 col-span-2" : ''}`}>
                    <img src={item.img} alt="" />
                </div>
            ))
        }
        </div>
        {/* For Mobile */}
        <div className="palce-items-center grid md:hidden 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-2 grid-cols-2 gap-y-[3rem] gap-x-[2rem] justify-center items-center">
           {
            PowrdDataMobile.map((item, index)=>(
                <div className={`flex justify-center  items-center ${index === 9 ? "!items-end h-[60px]" : ''}`}>
                    <img src={item.img} alt="" />
                </div>
            ))
           }
        </div>
     </div>
     <p id="faqs"></p>
    </div>
  );
}

export default PoweredSec;
