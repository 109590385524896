export const truncateString = (str, length) => {
	if (str.length < length) return str;
	const cutIndex = length / 2
	return str.substring(0, cutIndex) + "..." + str.substring(str.length - length / 2)
}

export const truncateAddress = (str, length) => {
	if (!str.startsWith("0x")) return truncateString(str, length)
	return `0x${truncateString(str.substring(2), length - 2)}`
}

export const capitalize = (str) => {
	return str.split("_")
		.map((str) =>
			str.substring(0, 1).toUpperCase() +
			str.substring(1).toLowerCase()
		).join(" ")
}