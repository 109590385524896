import QRCodeStyling from "qr-code-styling"
import { useEffect, useMemo, useRef } from "react"
import clsx from "clsx"

/**
 * @param {Object} props
 * @param {string} props.value
*/

const QrCode = ({ value, ...others }) => {
  const qrCode = useMemo(() => new QRCodeStyling({
    width: 500,
    height: 500,
    data: value,
    backgroundOptions: {
      color: "#161616"
    },
    qrOptions: {
      errorCorrectionLevel: "Q"
    },
    dotsOptions: {
      type: "dots",
      color: "#fff"
    },
    cornersDotOptions: {
      type: "dot",
    },
    cornersSquareOptions: {
      type: "extra-rounded",
    },
    imageOptions: {
      crossOrigin: "anonymous"
    }
  }), [value])

  const ref = useRef(null)

  useEffect(() => {
    const el = ref.current
    if (!el) return
    el.innerHTML = ""
    qrCode.append(el)
  }, [qrCode])

  return (
    <div {...others} ref={(el) => ref.current = el} className={clsx("p-4 bg-[#161616] self-center aspect-square [&>*]:w-full [&>*]:h-full", others.className)}>
    </div>
  )
}

export default QrCode