import React from "react";

import { useTranslation } from "react-i18next";

import vulticn1 from "../assets/svgs/vulticns (1).svg"
import vulticn2 from "../assets/svgs/vulticns (2).svg"
import vulticn3 from "../assets/svgs/vulticns (3).svg"
import vulticn4 from "../assets/svgs/vulticns (4).svg"
import vulticn5 from "../assets/svgs/vulticns (5).svg"
import vulticn6 from "../assets/svgs/vulticns (6).svg"
import vulticn7 from "../assets/svgs/vulticns (7).svg"





function BuiltDifferentSec() {
  const { t } = useTranslation();

  return (
  <>
   <div className="2xl:block xl:block lg:block md:block sm:hidden hidden h-[700px] relative max-w-[1200px] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] m-auto">
     <div className="w-[100%]  p-[4rem] builtbggr space-y-[3rem] absolute top-[-5%]">
            <div>
                <h3 className="text-[#FBD914]  text-[40px] font-[900] capitalize text-center">{t("builtdifferentSec.title")}</h3>
            </div>

        <div className="">
            <div className="space-x-[10px] flex border-b border-[#FBD914] pb-2">
                <div className="w-[25%]"></div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[80px]" src={vulticn7} alt="" />
                    <p className="text-[18px] text-[#fff] fony-[400]">DOKO</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[80px]" src={vulticn6} alt="" />
                    <p className="text-[18px] text-[#fff] fony-[400]">Dogecoin</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[80px]" src={vulticn5} alt="" />
                    <p className="text-[18px] text-[#fff] fony-[400]">Shiba Inu</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[80px]" src={vulticn4} alt="" />
                    <p className="text-[18px] text-[#fff] fony-[400]">Pepe Coin</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[80px]" src={vulticn3} alt="" />
                    <p className="text-[18px] text-[#fff] fony-[400]">DogWif</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[80px]" src={vulticn2} alt="" />
                    <p className="text-[18px] text-[#fff] fony-[400]">BONK</p>
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-3">
                <div className="w-[25%]">
                    <h4 className="text-[18px] font-[700] text-[#fff]">{t("WeDoing.tablwtext1")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-3">
                <div className="w-[25%]">
                    <h4 className="text-[18px] font-[700] text-[#fff]">{t("WeDoing.tablwtext2")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-3">
                <div className="w-[25%]">
                    <h4 className="text-[18px] font-[700] text-[#fff]">{t("WeDoing.tablwtext3")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-3">
                <div className="w-[25%]">
                    <h4 className="text-[18px] font-[700] text-[#fff]">{t("WeDoing.tablwtext4")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-3">
                <div className="w-[25%]">
                    <h4 className="text-[18px] font-[700] text-[#fff]">{t("WeDoing.tablwtext5")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex  py-3">
                <div className="w-[25%]">
                    <h4 className="text-[18px] font-[700] text-[#fff]">{t("WeDoing.tablwtext6")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[30px]" src={""} alt="" />
                </div>
            </div>
    <p id="whitepaper"></p>
        </div>
    </div>
   </div>
   <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block  max-w-[1200px] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[100%] w-[100%] m-auto">
     <div className="w-[100%]  py-[2rem] px-[1rem] builtbggr !rounded-[0px] space-y-[2rem]">
            <div>
                <h3 className="text-[#FBD914] xs-text-for-mob text-[46px] font-[700] leading-[100%] capitalize text-center">{t("builtdifferentSec.title")}</h3>
            </div>

        <div className="">
            <div className="space-x-[10px] flex border-b border-[#FBD914] pb-2">
                <div className="w-[25%]"></div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[26px]" src={vulticn7} alt="" />
                    <p className="text-[6px] text-[#fff] font-[400]">DOKO</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[26px]" src={vulticn6} alt="" />
                    <p className="text-[6px] text-[#fff] font-[400]">Dogecoin</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[26px]" src={vulticn5} alt="" />
                    <p className="text-[6px] text-[#fff] font-[400]">Shiba Inu</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[26px]" src={vulticn4} alt="" />
                    <p className="text-[6px] text-[#fff] font-[400]">Pepe Coin</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[26px]" src={vulticn3} alt="" />
                    <p className="text-[6px] text-[#fff] font-[400]">DogWif</p>
                </div>
                <div className="w-[12.5%] flex flex-col items-center space-y-2">
                    <img className="h-[26px]" src={vulticn2} alt="" />
                    <p className="text-[6px] text-[#fff] font-[400]">BONK</p>
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-2">
                <div className="w-[25%]">
                    <h4 className="text-[6px] font-[700] text-[#fff]">{t("WeDoing.tablwtext1")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-2">
                <div className="w-[25%]">
                    <h4 className="text-[6px] font-[700] text-[#fff]">{t("WeDoing.tablwtext2")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-2">
                <div className="w-[25%]">
                    <h4 className="text-[6px] font-[700] text-[#fff]">{t("WeDoing.tablwtext3")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-2">
                <div className="w-[25%]">
                    <h4 className="text-[6px] font-[700] text-[#fff]">{t("WeDoing.tablwtext4")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex border-b border-[#FBD91438] py-2">
                <div className="w-[25%]">
                    <h4 className="text-[6px] font-[700] text-[#fff]">{t("WeDoing.tablwtext5")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
            </div>
            <div className="space-x-[10px] flex  py-2">
                <div className="w-[25%]">
                    <h4 className="text-[6px] font-[700] text-[#fff]">{t("WeDoing.tablwtext6")}</h4>
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={vulticn1} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
                <div  className="w-[12.5%] flex flex-col items-center">
                    <img className="h-[10px]" src={""} alt="" />
                </div>
            </div>
        </div>
        <p id="whitepapersec"></p>
    </div>
   </div>
  </>
  );
}

export default BuiltDifferentSec;
