import React from "react";
import { useTranslation } from 'react-i18next';


function TopBannerCard({para, icon }) {
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="flex space-x-[25px] items-center max-w-[350px]">
        <img src={icon} className="2xl:h-[auto] xl:h-[auto] lg:h-[auto] md:h-[auto] sm:h-[auto] h-[62px]" alt="" />
        <p className="text-[#fff] font-[700] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] uppercase leading-[120%]">{t(para)}</p>
      </div>
      {/* 
      test */}
    </div>
  );
}

export default TopBannerCard;
