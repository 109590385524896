import React from "react";
import BuyWidget from "../compunents/BuyWidget/BuyWidget"

import { useTranslation } from "react-i18next";
import buyicn5 from "../assets/svgs/buyicn (5).svg";
import buyicn6 from "../assets/svgs/buyicn (6).svg";
import buyicn7 from "../assets/svgs/buyicn (7).svg";
import buyicn8 from "../assets/svgs/buyicn (8).svg";
import buyicn10 from "../assets/svgs/buyicn (10).svg";
import heroimg2 from "../assets/images/herimg (2).png";
import heroimg3 from "../assets/images/herimg (3).png";
import mobimg from "../assets/images/heromob.png";

const options = [
  {
    value: "usdt-erc20",
    label: "USDT",
    network: "ERC-20",
    image: buyicn10,
  },
  {
    value: "usdt-bep20",
    label: "USDT",
    network: "BEP-20",
    image: buyicn10,
  },
  {
    value: "eth",
    label: "Ethereum",
    network: "",
    image: buyicn7,
  },
  {
    value: "bnb",
    label: "BNB",
    network: "",
    image: buyicn6,
  },
  {
    value: "sol",
    label: "Solana",
    network: "",
    image: buyicn5,
  },
  {
    value: "btc",
    label: "Bitcoin",
    network: "",
    image: buyicn8,
  },
];

function HeroSec() {
  const { t } = useTranslation();

  return (
    <div className="bg-[#151515] bgmobgr">
      <div className=" pt-[4rem] pbcard pb-[2rem] max-w-[1440px] m-auto 2xl:block xl:block lg:block md:block sm:hidden hidden ">
        <div className="max-w-[1100px] space-y-[20px] w-[100%] m-auto">
          <p className="text-[22px] text-white mx-auto w-max">{t("ProductDetails.top_line")}</p>
          <h2 className="max-w-[980px] w-[100%] m-auto tracking-[-1.65px] text-[#fff] text-[46px] text-center leading-[120%] font-[700]">
            {t("ProductDetails.title1")}
          </h2>
          <p
            id="hero"
            className="text-[#FBD914] max-w-[940px] leading-[120%] w-[100%] m-auto text-center text-[22px] font-[400]"
          >
            {t("ProductDetails.para2")}
          </p>
        </div>

        <div className="flex relative justify-between mt-4 min-h-[920px]">
          <div className="w-[37%] self-center">
            <img className="w-[100%]" src={heroimg3} alt="" />
          </div>
          <BuyWidget />
          <div className="w-[37%] self-center">
            <img src={heroimg2} className="w-[100%]" alt="" />
          </div>
        </div>
      </div>
      <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block py-[3rem] ">
        <div className="max-w-[1100px] space-y-[10px] w-[94%] m-auto">
          <p className="text-[16px] text-white w-max">{t("ProductDetails.top_line")}</p>
          <h2 className="tracking-[-1.65px] text-[#fff] text-[28px]  leading-[50px] font-[700]">
            {t("ProductDetails.title1")}
          </h2>
          <p className="text-[#FBD914] max-w-[900px] leading-[120%] w-[100%] leading-[30px] m-auto  text-[20px] font-[400]">
            {t("ProductDetails.para2")}
          </p>
        </div>
        <div className="w-[100%] relative flex justify-center">
          <img src={mobimg} className="w-[auto]" alt="" />
        </div>
        <BuyWidget />
      </div>
    </div>
  );
}

export default HeroSec;
