import React, { useContext, useState, useEffect, useMemo, useRef, useCallback, useLayoutEffect } from "react"
import { createPortal } from "react-dom"

import toast from "react-hot-toast"
import closeIcon from "../../../assets/svgs/close.svg"

import userIcon from "../../../assets/svgs/user.svg"
import confetti from "canvas-confetti";
import howToBuyIcon from "../../../assets/svgs/queher.svg";
import buyicn2 from "../../../assets/svgs/buyicn (2).svg";
// import buyicn9 from "../../../assets/svgs/buyicn (9).svg";
import buyicn9 from "../../../assets/svgs/updated-buyicn (9).svg";
import buydrop from "../../../assets/svgs/buydrp.svg";
import que from "../../../assets/svgs/hqeu.svg";

import { ApiContext } from "../../../presale-gg/context/ApiContext";
import { UserContext } from "../../../presale-gg/context/UserContext";
import { Web3Context } from "../../../presale-gg/context/Web3Context";
import { api, parseNum, minMax, getChainIdFromLabel, getAbi, isCurrencyNative, getContractAddress, PAYMENT_WALLET, getDecimals, formatDollar, formatNumber, formatLargeNumber } from "../../../presale-gg/utils"
import { tokenImageMap } from "../../../assets/tokens"
import clsx from "clsx";
import { TransactionModal } from "../../TransactionModal";
import { ReferralLinkModal } from "../../ReferralLinkModal";
import { LoadContext } from "../../../presale-gg/context/LoadContext";
import { useTranslation } from "react-i18next";

const walletBuyTokens = new Set([
	"ETH-ERC-20",
	"USDT-ERC-20",
	"BNB-BEP-20"
])

let initialPaymentAmountStr = "1";
let initialReceiveTokensStr = "1";
let initialSelectedCurrencyId = null;
let initialCodeVisible = null

/**
 * @param {object} props
 * @param {bool} props.isRankView
 * @returns {JSX.Element}
 */
const BuyTab = (props) => {
  const apiData = useContext(ApiContext)
  const web3Data = useContext(Web3Context)
  const userData = useContext(UserContext)

	const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState(initialSelectedCurrencyId ?? apiData.paymentTokens?.[0].id ?? null)

  const selectedCurrency = useMemo(() => {
    return apiData.paymentTokens?.find((token) => token.id === selectedCurrencyId)
  })

  const [paymentTokenStr, setPaymentTokenStr] = useState(initialPaymentAmountStr)
  const [receiveTokenStr, setReceiveTokenStr] = useState(initialReceiveTokensStr)
  const paymentTokenNum = parseNum(paymentTokenStr)
  const receiveTokenNum = parseNum(receiveTokenStr)

  useEffect(() => {
    initialPaymentAmountStr = paymentTokenStr
    initialReceiveTokensStr = receiveTokenStr
    initialSelectedCurrencyId = selectedCurrencyId
  }, [paymentTokenStr, receiveTokenStr, selectedCurrencyId])

  useEffect(() => {
    if (selectedCurrencyId === null || selectedCurrencyId === undefined) {
      const selectedCurrency = apiData.paymentTokens?.find((token) => token.symbol === "USDT" && token.chain === "ERC-20") ?? apiData.paymentTokens?.[0]
      setSelectedCurrencyId(selectedCurrency?.id)
      setPaymentTokenStr("1000")
    }
  }, [apiData.paymentTokens])

  const [createdTransaction, setCreatedTransaction] = useState(null)
  const [transactionLoading, setTransactionLoading] = useState(false)
  const [transactionModalOpen, setTransactionModalOpen] = useState(false)
  const [referralModalOpen, setReferralModalOpen] = useState(false)

  const currentUsd = parseNum(apiData.stage?.cumulative_usd_raised)
  const totalUsd = parseNum(apiData.stage?.next_stage_target_usd)

  const minimum = useMemo(() => {
		if (!selectedCurrency || !selectedCurrency?.nowpayments_minimum) return null
		return Math.ceil((parseNum(selectedCurrency?.nowpayments_minimum) / parseNum(selectedCurrency?.price)) * 10**6) / 10**6
	}, [selectedCurrency])

  const { t } = useTranslation()

  useEffect(() => {
    const num = paymentTokenNum
    const tokensPerPaymentToken = parseNum(selectedCurrency?.price ?? 1) / parseNum(apiData.stage?.token_price ?? 1)
    setReceiveTokenStr((Math.floor((tokensPerPaymentToken * num) * 100) / 100).toString())
  }, [selectedCurrency])

  const buy = async () => {
    if (apiData.presaleEnded) return toast.error(t("Messages.presale-ended-no-buy"))
    if (transactionLoading) return
    if (!selectedCurrency) return
		if (minimum !== null && paymentTokenNum < minimum) return toast.error(t("Messages.cannot-pay-less", {min: minimum, currency: selectedCurrency?.symbol}))
    if (!web3Data.account) return toast.error(t("Messages.must-connect"))
    try {
      await toast.promise(new Promise(async (resolve, reject) => {
        const walletTransfer = walletBuyTokens.has(selectedCurrency?.symbol.toUpperCase() + "-" + selectedCurrency?.chain.toUpperCase())
        if (walletTransfer) {
          const chainId = getChainIdFromLabel(selectedCurrency?.chain)
          if (!chainId) return toast.error(t("Messages.invalid-chain-id", {chain: selectedCurrency?.chain}))
          const abi = getAbi(chainId)
          if (!abi) return toast.error(t("Messages.invalid-abi", {chainId}))
          setTransactionLoading(true)
          const native = isCurrencyNative(selectedCurrency?.symbol, chainId)
          const contractAddress = getContractAddress(selectedCurrency?.symbol)
          if (!native && !contractAddress) return toast.error(t("Messages.invalid-contract-address", {token: selectedCurrency?.symbol}))
          try {
            toast(t("Messages.confirm-wallet"))
            const transactionHash = await web3Data.sendTransaction({
              chainId,
              native,
              to: PAYMENT_WALLET,
              value: paymentTokenNum.toString(),
              abi,
              contractAddress: contractAddress ?? undefined,
              decimals: getDecimals(selectedCurrency?.symbol) ?? undefined
            })
            api.createTransactionMetadata(web3Data.account, transactionHash)
            resolve("completed")
          } catch (err) {
            console.error(err)
            reject()
          }
          setTransactionLoading(false)
        } else {
          setTransactionLoading(true)
          try {
            const res = await api.createTransaction({
              payment_token_id: selectedCurrency?.id,
              token_amount: paymentTokenNum,
              usd_amount: paymentTokenNum * parseNum(selectedCurrency.price),
              wallet_address: web3Data.account
            })
            setCreatedTransaction(res.data)
            setTimeout(() => {
              setTransactionModalOpen(true)
              setTransactionLoading(false)
            }, 100)
            resolve("created")
          } catch (err) {
            setTransactionLoading(false)
            reject(err)
          }
        }
      }), {
        loading: t("Messages.transaction-loading"),
        success: (data) => data === "completed" ? t("Messages.transaction-completed-success") : t("Messages.transaction-created-success"),
        error: (err) => api.getApiErrorMessage(err, t("Messages.transaction-error"))
      })
    } catch (err) {
      console.log(err)
    }

    try {
			const usdVal = paymentTokenNum * parseNum(selectedCurrency.price)
      window.gtag?.("event", "purchase", {
        value: usdVal,
        currency: "USD"
      });
      window.fbq?.("track", "Purchase", {
        value: usdVal,
        currency: "USD"
      })
    } catch (err) {
      console.error(err)
    }
  }

  const sortedTokens = useMemo(() => {
    return [...(apiData.paymentTokens ?? [])].sort((a, b) => `${a.symbol} ${a.chain}`.toLowerCase().localeCompare(`${b.symbol} ${b.chain}`.toLowerCase()))
  }, [apiData.paymentTokens])

  const [ codeVisible, setCodeVisible ] = useState(initialCodeVisible)
  useEffect(() => {
    initialCodeVisible = codeVisible
  }, [codeVisible])

  const loadValue = useContext(LoadContext)
  useEffect(() => {
    if (!userData.user) return
    const url = new URL(window.location.href)
    if (url.searchParams.has("referral_code")) setCodeVisible("referral")
    else if (url.searchParams.has("bonus_code")) setCodeVisible("bonus")
    
  }, [userData.user])

  const percentage = minMax(currentUsd * 100 / (totalUsd || 1), 0, 100)
  const [ tooltipOpen, setTooltipOpen ] = useState(false)

  const displayTokens = useMemo(() => {
    const tokens = ["SOL-SOLANA", "ETH-ERC-20", "USDT-ERC-20", "BNB-BEP-20"]
    const tokenIdGetter = (token) => `${token.symbol}-${token.chain}`.toUpperCase()
    return (apiData.paymentTokens ?? []).filter((token) => {
      return tokens.includes(tokenIdGetter(token))
    })
      .sort((a, b) => {
        return tokens.indexOf(tokenIdGetter(a)) - tokens.indexOf(tokenIdGetter(b))
      })
      .map((token) => (apiData.paymentTokens ?? [])
        .filter((otherToken) => token.name === otherToken.name)
        .sort((a, b) => tokenIdGetter(a).localeCompare(tokenIdGetter(b)))
      )
        
  }, [apiData.paymentTokens])

  const otherTokens = useMemo(() => {
    return sortedTokens
      .filter((token) => !displayTokens.find((otherToken) => otherToken.symbol === token.symbol))
      .slice(0, 5)
  }, [sortedTokens, displayTokens])
  const [ otherTokenDropdownOpenSymbol, setOtherTokenDropdownOpenSymbol ] = useState(null)

  const getCurrentNftLabel = () => {
    const usdAmountSpending = paymentTokenNum * parseNum(selectedCurrency?.price)
    if (usdAmountSpending < 1000) return null
    else if (usdAmountSpending < 2500) return "student"
    else if (usdAmountSpending < 5000) return "trainee"
    else if (usdAmountSpending < 10000) return "employee"
    else if (usdAmountSpending < 25000) return "manager"
    else if (usdAmountSpending < 100000) return "boss"
    else if (usdAmountSpending < 250000) return "millionaire"
    else return "billionaire"
  }
  const currentNftLabel = getCurrentNftLabel()

	return (
    <div className="flex flex-col gap-[15px] justify-between flex-1">
      <h4 className="text-[28px] text-center font-[700] text-[#fff] tracking-[-1.65px]">
        {apiData.presaleEnded ? t("ProductDetails.presale-ended") : (props.isRankView ? t("Ranks.card-title") : t("ProductDetails.card-title"))}
      </h4>
      {!props.isRankView ? (
        <>
          <div className="bg-[#000] space-y-[10px] border px-5 py-2 rounded-[20px] border-[#FBD914]">
            <p className="text-[36px] text-center font-[700] text-[#fff] tracking-[-1.65px]">
              ${formatNumber(parseNum(apiData.stage?.cumulative_usd_raised), 2, 2)}
            </p>
            <div className="space-y-[5px]">
              <div className="relative flex justify-between items-center">
                <h3 className="text-[14px] font-[500] text-[#fff]">
                  {t("ProductDetails.raised", {percentage: Math.floor(percentage)})}
                </h3>
                <img
                  src={que}
                  alt=""
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseLeave={() => setTooltipOpen(false)}
                  style={{ cursor: "pointer" }}
                  className=""
                />
                {tooltipOpen && (
                  <p className="text-[12px] absolute right-[-18px] md:right-[-63%] bottom-[126%] text-[#fff] bg-[#212121] rounded-[8px] p-3 max-w-[250px]">
                      {t("ProductDetails.tooltip")}
                  </p>
                )}
              </div>
              <div className="herbar h-[14px] w-[100%] mx-auto rounded-[40px] overflow-hidden">
                <div className="rounded-[40px] bg-[#FBD914] h-[14px]" style={{width: `${percentage}%`}} />
              </div>
              <div className="flex justify-end">
                  <h3 className="text-[#fff] text-[15px]">
                    {formatDollar(totalUsd, true, 0, 0)}
                  </h3>
              </div>
            </div>
            <div className="space-y-0">
              <p className="text-[14px] font-[700] text-center text-[#fff]">
                {formatNumber(parseNum(apiData.info?.transactions))} {t("ProductDetails.card-contributions")}
              </p>
              <p className="text-[14px] font-[700] text-center text-[#A9A7A7]">
                {t("ProductDetails.card-listingPrice")}
              </p>
            </div>
          </div>
          {userData.user && (
            <div className="h-12 rounded-xl bg-transparent flex justify-center items-center shadow-2xl" 
              style={{ background: 'linear-gradient(to left, #6B6B6B, #2C2C2C)' }}
            >
              <p className="text-[#fff] text-center text-[14px] font-[700]">
                <span>
                  {t("ProductDetails.you-own")}
                </span>{" "}
                <span className="text-[#FBD914]">{formatNumber(parseNum(userData.user?.total_tokens), 2, 2)}</span>
                <span className="text-[#FBD914] ml-1">$DOKO</span>
                <span className="text-[#FBD914] ml-1">$({formatLargeNumber(parseNum(userData.user?.total_tokens) * parseNum(apiData.stage?.token_price))})</span>
              </p>
            </div>
          )}
        </>
      ) : <UserRankBox />}
      <div className="currentbg py-[5px]">
        <p className="text-[#fff] text-center text-[14px] font-bold">
          {t("ProductDetails.card-body-currcnt", {price: formatNumber(parseNum(apiData.stage?.token_price))})}
        </p>
      </div>
      <div className="flex gap-2">
        {displayTokens.map((tokens) => (
          <div className="relative flex-1 flex flex-col">
            <button
              onClick={() => {
                if (tokens.length === 1) {
                  setSelectedCurrencyId(tokens[0].id)
                  setOtherTokenDropdownOpenSymbol(null)
                } else {
                  if (otherTokenDropdownOpenSymbol === tokens[0].symbol) setOtherTokenDropdownOpenSymbol(null)
                  else setOtherTokenDropdownOpenSymbol(tokens[0].symbol)
                }
                setShowDropdown(null)
              }}
              className={clsx(
                "flex flex-col items-center p-2 gap-1 bg-[#ffffff17] rounded-xl",
                {"outline-1 outline outline-gray-400": selectedCurrency?.symbol === tokens[0].symbol}
              )}
              key={tokens[0].id}
            >
              <img
                className="w-5 h-5"
                src={tokenImageMap[tokens[0].symbol.toLowerCase()]}
                alt={tokens[0].name}              
              />
              <p className="text-[9px] text-[#fff]">
                {tokens[0].symbol.toUpperCase()}
              </p>
            </button>
            {tokens.length > 1 && (
              <div
                className={clsx("absolute top-[calc(100%-4px)] z-10 w-[8rem] shadow-lg -left-1 bg-[#151515] p-2 text-[#fff] max-h-[18rem] overflow-auto transition-opacity rounded-lg", {["opacity-0 pointer-events-none"]: otherTokenDropdownOpenSymbol !== tokens[0].symbol})}
                tabIndex={showDropdown ? 0 : -1}
              >
                {tokens.map((token) => (
                  <button
                    key={token.id}
                    className={clsx(
                      "flex w-[100%] justify-between px-1 py-2 pr-4 items-center space-x-2 rounded-lg cursor-pointer",
                      {"!bg-[#6b7280]": token.id === selectedCurrencyId}
                    )}
                    onClick={() => {
                      setOtherTokenDropdownOpenSymbol(null)
                      setSelectedCurrencyId(token.id)
                    }}
                    tabIndex={showDropdown ? 0 : -1}
                  >
                    <img
                      src={tokenImageMap[token.symbol.toLowerCase()]}
                      alt=""
                      className="w- h-9"
                    />
                    <div className="text-left flex-1">
                      <h3 className="text-[12px] text-[#fff] font-[400]">
                        {token.symbol.toUpperCase()}
                      </h3>
                      <p className={clsx(
                        "text-[#7A7A7A] text-[8px]",
                        {"!text-[#bbb]": token.id === selectedCurrencyId}
                      )}>
                        {token.chain}
                      </p>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <button className="flex gap-1 items-center justify-center" onClick={() => setShowDropdown((show) => !show)}>
        {otherTokens.map((token) => (
          <img
            key={token.id}
            src={tokenImageMap[token.symbol.toLowerCase()]} alt={token.name}
            className="w-6 h-6"
          />
        ))}
        <p className="text-[14px] text-[#fff]">{t("ProductDetails.other")}</p>
      </button>
      <div className="bgpay flex relative z-[1] just`ify-between py-[3px] px-3 items-center ">
        <div className="w-[60%]">
          <p className="text-[12.5px] font-[400] text-[#DCDCDC]">
            {t("ProductDetails.card-body-amount-pay", {currency: selectedCurrency?.symbol.toUpperCase()})}
          </p>
          <input
            type="text"
            className="inputbuy1 outline-none bg-[transparent] text-[22px] text-[#ffff] "
            value={paymentTokenStr}
            onBlur={() => {
              if (paymentTokenStr === "") setPaymentTokenStr("0")
            }}
            onChange={(e) => {
              const partialNumRegex = /^\d*\.?\d*$/
              let val = e.target.value
              if (!partialNumRegex.test(val)) {
                val = paymentTokenStr
                e.target.value = val
                setPaymentTokenStr(val)
                return
              }
              setPaymentTokenStr(val)
              const num = parseNum(val)
              const tokensPerPaymentToken = parseNum(selectedCurrency?.price ?? 1) / parseNum(apiData.stage?.token_price ?? 1)
              setReceiveTokenStr((Math.floor((tokensPerPaymentToken * num) * 100) / 100).toString())
            }}
          />
        </div>
        <div className="relative flex ml-auto w-[36%] flex-shrink-0">
          <button
            className="flex w-[100%] justify-between p-1 pr-4 items-center space-x-2 border border-[#fff] rounded-[40px] cursor-pointer"
            onClick={() => {
              setShowDropdown((dropdown) => !dropdown)
              setOtherTokenDropdownOpenSymbol(null)
            }}
          >
            <img src={tokenImageMap[selectedCurrency?.symbol.toLowerCase()]} alt="" className="h-9 w-9" />
            <div>
              <h3 className="text-[12px] text-[#fff] font-[400]">
                {selectedCurrency?.symbol.toUpperCase()}
              </h3>
              <p className="text-[#7A7A7A] text-[8px]">{selectedCurrency?.chain}</p>
            </div>
            <div className="transition-transform" style={{transform: showDropdown ? "rotate(180deg)" : "rotate(0deg)"}}>
              <img src={buydrop} alt="" />
            </div>
          </button>
          <div
            className={clsx("absolute top-[calc(100%-4px)] z-10 w-[8rem] shadow-lg right-1 bg-[#151515] p-2 text-[#fff] max-h-[18rem] overflow-auto transition-opacity rounded-lg", {["opacity-0 pointer-events-none"]: !showDropdown})}
            tabIndex={showDropdown ? 0 : -1}
          >
            {sortedTokens.map((token) => (
              <button
                key={token.id}
                className={clsx(
                  "flex w-[100%] justify-between px-1 py-2 pr-4 items-center space-x-2 rounded-lg cursor-pointer",
                  {"!bg-[#6b7280]": token.id === selectedCurrencyId}
                )}
                onClick={() => {
                  setShowDropdown(false)
                  setSelectedCurrencyId(token.id)
                }}
                tabIndex={showDropdown ? 0 : -1}
              >
                <img
                  src={tokenImageMap[token.symbol.toLowerCase()]}
                  alt=""
                  className="w- h-9"
                />
                <div className="text-left flex-1">
                  <h3 className="text-[12px] text-[#fff] font-[400]">
                    {token.symbol.toUpperCase()}
                  </h3>
                  <p className={clsx(
                    "text-[#7A7A7A] text-[8px]",
                    {"!text-[#bbb]": token.id === selectedCurrencyId}
                  )}>
                    {token.chain}
                  </p>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="bgpay flex justify-between py-[3px] px-3 items-center ">
        <div className="w-[60%]">
          <p className="text-[12.5px] font-[400] text-[#DCDCDC]">
            {t("ProductDetails.card-body-amount-receive")} <span>$DOKO</span>
            {currentNftLabel && <>
              <span> + </span>
              <span className="text-[#07C484] font-[600] underline">
                {t(`ProductDetails.card-body-amount-receive-${currentNftLabel.toLowerCase()}-nft`)}
              </span>
            </>}
          </p>
          <input
            type="text"
            className="inputbuy outline-none bg-[transparent] text-[26px] text-[#FBD914]"
            value={receiveTokenStr}
            onBlur={() => {
              if (receiveTokenStr === "") setReceiveTokenStr("0")
            }}
            onChange={(e) => {
              const partialNumRegex = /^\d*\.?\d*$/
              let val = e.target.value
              if (!partialNumRegex.test(val)) {
                val = paymentTokenStr
                e.target.value = val
                setReceiveTokenStr(val)
                return
              }
              setReceiveTokenStr(val)
              const num = parseNum(val)
              const tokensPerPaymentToken = parseNum(selectedCurrency?.price ?? 1) / parseNum(apiData.stage?.token_price ?? 1)
              setPaymentTokenStr((Math.floor(num * 10 ** 6 / tokensPerPaymentToken) / 10 ** 6).toString())
            }}
          />
        </div>
        <div className="flex w-[36%]  p-1  pr-4 items-center space-x-2 border border-[#fff] rounded-[40px]">
          <img src={buyicn9} alt="" />
          <div>
            <h3 className="text-[12px] text-[#fff] font-[400]">
              $DOKO
            </h3>
          </div>
        </div>
      </div>
      {codeVisible === "bonus" && <BonusCodeInput onClose={() => setCodeVisible(null)} />}
      {codeVisible === "referral" && <ReferralCodeInput onClose={() => setCodeVisible(null)} />}
      <button
        className={clsx(
          "bg-[#FBDA19] hover:bg-[transparent] w-[100%] border border-[#FBDA19] rounded-[40px] px-[24px] py-[8px] text-[18px] font-[600] hover:text-[#fff]",
          {["!bg-[#444] !text-[#fff] !border-[#444] !cursor-auto"]: apiData.presaleEnded}
        )}
        onClick={() => {
          if (!web3Data.account) web3Data.showConnectionModal()
          else buy()
        }}
      >
        {apiData.presaleEnded ? t("ProductDetails.presale-ended") : (
          web3Data.account ? `${t("ProductDetails.tab1")} $DOKO ${t("ProductDetails.now")}` : t("ProductDetails.prebtn")
        )}
      </button>
      <div className="flex justify-between">
        <div className="bgbottombnt w-[160px] items-center cursor-pointer py-[2px] flex justify-center space-x-2 ">
          <img src={howToBuyIcon} alt="" />
          <a href="#howtobuysec" className="text-[#fff] text-[12px] font-[400]">
            {t("ProductDetails.card-body-buyBtn")}
          </a>
        </div>
        <button
          className="bgbottombnt w-[160px] items-center cursor-pointer py-[2px] flex justify-center space-x-2"
          onClick={() => {
            if (!userData.user) return toast.error(t("Messages.must-connect"))
            setReferralModalOpen(true)
          }}
        >
          <img src={buyicn2} alt="" />
          <p className="text-[#fff] text-[12px] font-[400]">
            {t("ProductDetails.card-body-referralBtn")}
          </p>
        </button>
      </div>
      <div className="flex justify-evenly">
        <button
          className="text-[#fff] text-[11px] underline"
          onClick={() => setCodeVisible("bonus")}
        >
          {t("ProductDetails.card-body-Bonus-code")}
        </button>
        <button
          className="text-[#fff] text-[11px] underline"
          onClick={() => setCodeVisible("referral")}
        >
          {t("ProductDetails.card-body-referral-code")}
        </button>
      </div>
      {createPortal(<>
        {createdTransaction && (
          <TransactionModal
            transaction={createdTransaction}
            open={transactionModalOpen}
            onClose={() => setTransactionModalOpen(false)}
          />
        )}
      </>, document.getElementById("modals"), "transaction-modal")}
      {createPortal(
        <ReferralLinkModal
          open={referralModalOpen}
          onClose={() => setReferralModalOpen(false)}
        />, document.getElementById("modals"), "referral-modal")}
    </div>
	)
}

export default BuyTab

let initialBonusCodeStr = ""
const BonusCodeInput = (props) => {
  const userData = useContext(UserContext)
  const apiData = useContext(ApiContext)

  const { t } = useTranslation()
  const [bonusCodeStr, setBonusCodeStr] = useState(initialBonusCodeStr)

  useEffect(() => {
    initialBonusCodeStr = bonusCodeStr
  }, [bonusCodeStr])

  const [ bonusCodeLoading, setBonusCodeLoading ] = useState(false)
  const applyBonusCode = async () => {
    if (bonusCodeLoading) return;
    if (bonusCodeStr.length === 0) return toast.error(t("Messages.invalid-code"))
    setBonusCodeLoading(true)
    try {
      await toast.promise(userData.applyBonusCode(bonusCodeStr), {
        success: t("Messages.bonus-code-success"),
        error: (err) => api.getApiErrorMessage(err, t("Messages.bonus-code-error")),
        loading: t("Messages.bonus-code-loading")
      })
    } catch (err) {}
    setBonusCodeLoading(false)
  }

  const ref = useRef()
  const [ hasParam, setHasParam ] = useState(false)
  const loadValue = useContext(LoadContext)

  useEffect(() => {
		if (!userData.user) return;
		const url = new URL(window.location.href)
		const initialCode = url.searchParams.get("bonus_code");
    loadValue.setQueryParamsLoaded(true)
    if (!initialCode) return
    setBonusCodeStr(initialCode)
    if (loadValue.queryParamsLoaded) return
    setHasParam(true);
    const el = ref.current
    el.scrollIntoView({block: "center", behaviour: "smooth"})
  }, [userData.user])

  return (
    <div
      className={clsx("bgpay flex py-[3px] px-3 items-center rounded-[40px] after:rounded-[14px]", {"flash": hasParam})}
      ref={(el) => ref.current = el}
    >
      <button
        className="absolute top-0 right-0 translate-x-[0.25rem] translate-y-[-0.25rem] bg-[#fff] hover:bg-[#ccc] transition-colors rounded-full p-0.5 w-5 h-5"
        onClick={props.onClose}
        aria-label="Close"
      >
        <svg
          class="w-full h-full"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      </button>
      <div className="flex flex-col flex-1">
        <p className="text-[12.5px] font-[400] text-[#DCDCDC]">
          {t("ProductDetails.bonus-code-label")}
        </p>
        <input
          size="1"
          type="text"
          placeholder={t("ProductDetails.bonus-code-placeholder")}
          className="inputbuy1 outline-none bg-[transparent] text-[26px] text-[#fff]"
          value={bonusCodeStr}
          onChange={(e) => {
            setBonusCodeStr(e.target.value)
          }}
        />
      </div>
      {userData.appliedBonusCode ? (
        <div className="flex gap-2 items-center justify-center text-center text-[#fff] w-[8.5rem] border border-[#fff] rounded-full py-2.5 px-3">
          <span className="text-sm">
            {t("ProductDetails.applied")}
          </span>
          <span className="bg-[#20f79b33] text-[#20f79b] text-[14px] px-1.5 py-0.5 rounded-lg font-[500] w-[3.25rem] text-center">
            +{formatNumber(parseNum(userData.appliedBonusCode.percentage), 0, 2)}%
          </span>
        </div>
      ) : (
        <button
          className={clsx(
            "bg-[#FBDA19] hover:bg-[transparent] border border-[#FBDA19] rounded-[40px] px-[12px] py-[4px] text-[16px] font-[600] min-w-[7.75rem]  h-[2.5rem] text-center hover:text-[#fff]",
            {["!bg-[#444] !border-[#444] !text-[#fff] !cursor-auto"]: apiData.presaleEnded}
          )}
          onClick={apiData.presaleEnded ? undefined : applyBonusCode}
        >
          {apiData.presaleEnded ? t("ProductDetails.presale-ended") : t("ProductDetails.card-body-Bonus-code-apply")}
        </button>
      )}
    </div>
  )
}

let initialReferralCodeStr = ""
const ReferralCodeInput = (props) => {
  const userData = useContext(UserContext)
  const apiData = useContext(ApiContext)

  const { t } = useTranslation()
  const [referralCodeStr, setReferralCodeStr] = useState(initialReferralCodeStr)

  useEffect(() => {
    initialReferralCodeStr = referralCodeStr
  }, [referralCodeStr])

  const [ loading, setLoading ] = useState(false)
  const applyCode = async () => {
    if (loading) return;
    if (referralCodeStr.length === 0) return toast.error(t("Messages.invalid-code"))
      setLoading(true)
    try {
      await toast.promise(userData.updateReferralCode(referralCodeStr), {
        success: t("Messages.referral-code-success"),
        error: (err) => api.getApiErrorMessage(err, t("Messages.referral-code-error")),
        loading: t("Messages.referral-code-loading")
      })
    } catch (err) {}
    setLoading(false)
  }

  const ref = useRef()
  const [ hasParam, setHasParam ] = useState(false)
  const loadValue = useContext(LoadContext)

  useEffect(() => {
		if (!userData.user) return;
		const url = new URL(window.location.href)
		const initialCode = url.searchParams.get("referral_code");
    loadValue.setQueryParamsLoaded(true)
    if (!initialCode) return
    setReferralCodeStr(initialCode)
    if (loadValue.queryParamsLoaded) return
    setHasParam(true)
    const el = ref.current
    el.scrollIntoView({block: "center", behaviour: "smooth"})
  }, [userData.user])

  return (
    <div
      className={clsx("bgpay flex py-[3px] px-3 items-center rounded-[40px] after:rounded-[14px]", {"flash": hasParam})}
      ref={(el) => ref.current = el}
    >
      <button
        className="absolute top-0 right-0 translate-x-[0.25rem] translate-y-[-0.25rem] bg-[#fff] hover:bg-[#ccc] transition-colors rounded-full p-0.5 w-5 h-5"
        onClick={props.onClose}
        aria-label="Close"
      >
        <svg
          class="w-full h-full"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      </button>
      {userData.user?.referred_by ? (
        <div className="flex flex-col flex-1">
          <p className="text-[12.5px] font-[400] text-[#DCDCDC]">
            {t("ProductDetails.referral-code-applied")}
          </p>
          <p className="inputbuy1 text-[#20f79b] outline-none bg-[transparent] text-[26px]">
            {t("ProductDetails.referral-code-applied")}
          </p>
        </div>
      ) : <>
        <div className="flex flex-col flex-1">
          <p className="text-[12.5px] font-[400] text-[#DCDCDC]">
            {t("ProductDetails.referral-code-label")}
          </p>
          <input
            size="1"
            type="text"
            placeholder={t("ProductDetails.referral-code-placeholder")}
            className="inputbuy1 outline-none bg-[transparent] text-[26px] text-[#fff]"
            value={referralCodeStr}
            onChange={(e) => {
              setReferralCodeStr(e.target.value)
            }}
          />
        </div>
        <button
          className={clsx(
            "bg-[#FBDA19] hover:bg-[transparent] border border-[#FBDA19] rounded-[40px] px-[12px] py-[4px] text-[16px] font-[600] min-w-[7.75rem]  h-[2.5rem] text-center hover:text-[#fff]",
            {["!bg-[#444] !border-[#444] !text-[#fff] !cursor-auto"]: apiData.presaleEnded}
          )}
          onClick={apiData.presaleEnded ? undefined : applyCode}
        >
          {apiData.presaleEnded ? t("ProductDetails.presale-ended") : t("ProductDetails.card-body-Bonus-code-apply")}
        </button>
      </>}
    </div>
  )
}

const UserRankBox = () => {
	const apiData = useContext(ApiContext)
	const userData = useContext(UserContext)
	const { t } = useTranslation()
	const [ userLevel, setUserLevel ] = useState(0)
  
	const ranks = useMemo(() => {
	  let ranks = userData.userRankData?.ranks ?? []
	  if (!ranks[0]) return ranks
	  if (ranks[0].level > 0) {
		return [
		  {level: 0, rank: null, reward: null, bonus_percentage: null},
		  ...ranks
		]
	  }
	}, [userData.userRankData?.ranks])
  
	const rankIndex = ranks.reduce((acc, rank, i) => (acc === undefined || userLevel >= rank.level) ? i : acc, undefined)
  
	const visibleRanks = useMemo(() => {
	  let min = Math.max(rankIndex - 2, 0)
	  const minDiff = Math.abs(rankIndex - 2 - min)
	  const max = Math.min(rankIndex + 3 + minDiff, ranks.length)
	  const maxDiff = Math.abs(rankIndex + 3 + minDiff - max)
	  min -= maxDiff
	  return ranks.slice(min, max)
	}, [ranks, rankIndex])
  
	const collapsedRanksStart = useMemo(() => {
	  return ranks.slice(0, ranks.findIndex((rank) => rank?.rank === visibleRanks[0]?.rank))
	}, [ranks, visibleRanks])
  
	const collapsedRanksEnd = useMemo(() => {
	  return ranks.slice(ranks.findIndex((rank) => rank === visibleRanks[visibleRanks.length - 1]) + 1)
	}, [ranks, visibleRanks])
  
	const canLevelUp = userData.userRankData?.can_level_up
	const [ transitionDelays, setTransitionDelays ] = useState(ranks.map(() => 0))
  
	useEffect(() => {
	  const level = userData.userRankData?.current_level
	  if (level === null || level === undefined) return
	  setNewLevel(level)
	}, [userData.userRankData])
  
	useEffect(() => {
	  setTransitionDelays(ranks.map(() => 0))
	}, [ranks.length])
  
	const setNewLevel = useCallback((level) => {
	  const diff = level - userLevel
	  const newRankIndex = ranks.reduce((acc, rank, i) => (acc === undefined || level >= rank.level) ? i : acc, undefined)
  
	  setUserLevel(level)
	  setTransitionDelays(() => {
		let count = -1;
		let delays = ranks.map((_, i) => {
		  if (i <= rankIndex) return 0
		  if (!newRankIndex) return 0
		  if (i <= newRankIndex + 1) {
			count += 1;
			return count * 200
		  }
		})
		if (diff < 0) delays = delays.reverse()
		return delays
	  })
	}, [userLevel, ranks])
  
	const [ selectedRankRef, setSelectedRankRef ] = useState(null)
	const [ selectedRankBounds, setSelectedRankBounds ] = useState(new DOMRect());
	useEffect(() => {
	  const func = () => {
		const el = selectedRankRef
		if (!el) return
		setSelectedRankBounds(el.getBoundingClientRect())
	  }
	  func()
	  window.addEventListener("resize", func)
	  return () => window.removeEventListener("resize", func)
	}, [visibleRanks, userData.userRankData?.current_rank, userLevel, selectedRankRef])
  
	const markerContainerRef = useRef(null)
	const [ markerContainerBounds, setMarkerContainerBounds ] = useState(new DOMRect());
	useEffect(() => {
	  const func = () => {
		const el = markerContainerRef.current
		if (!el) return
		setMarkerContainerBounds(el.getBoundingClientRect())
	  }
	  func()
	  window.addEventListener("resize", func)
	  return () => window.removeEventListener("resize", func)
	}, [])
  
	const [ levelUpLoading, setLevelUpLoading ] = useState(false)
	const levelUp = async () => {
	  if (levelUpLoading) return
	  if (!canLevelUp) return
	  setLevelUpLoading(true)
	  try {
		await userData.levelUp()
		confetti({gravity: 1, origin: {x: 0.5, y: 0.75}})
	  } catch (err) {
		toast.error(api.getApiErrorMessage(err, t("Messages.level-up-error")))
	  }
	  setLevelUpLoading(false)
	}
	const [ initialLoaded, setInitialLoaded ] = useState(false)
	const [ finalLoaded, setFinalLoaded ] = useState(false)
	useLayoutEffect(() => {
	  setTimeout(() => {
		setInitialLoaded(true)
	  }, 10)
	  setTimeout(() => {
		setFinalLoaded(true)
	  }, 100)
	}, [])
  
	return (
	  <div className="rounded-xl bg-transparent flex flex-col p-2 shadow-2xl gap-[0.79rem] border border-[#FBD914]" 
		style={{ background: "#ffffff22" }}
	  >
		<div className="flex justify-between gap-2">
		  <p className="text-center px-2 py-0.5 flex items-center justify-center text-sm bg-[#00000044] rounded-full">
			<span className="text-[#FBD914]">
			  {formatLargeNumber(parseNum(userData.user?.total_tokens))}
			  {" "}
			  <span className="text-[#fff]">
				DOKO
			  </span>
			  {" "}
			  (${formatLargeNumber(parseNum(userData.user?.total_tokens) * parseNum(apiData.stage?.token_price))})
			</span>
		  </p>
		  <p className="text-[#fff] w-[6.5rem] text-center py-0.5 flex gap-1 items-center justify-center text-sm bg-[#00000044] rounded-full">
			{t("Ranks.your-level")}
			<span className="text-[#FBD914] font-[500]">
			  {userLevel}
			</span>
		  </p>
		</div>
		<div className="flex flex-col -mb-5" ref={(el) => markerContainerRef.current = el}>
		  <div
			className="relative rotate-45 my-2 self-start rounded-lg bg-[#343434] border border-[#444] p-2 left-0 -translate-x-1/2"
			style={{
			  "left": `${selectedRankBounds.x - markerContainerBounds.x + selectedRankBounds.width / 2}px`,
			  "transition": !finalLoaded ? "none" : "left 400ms ease-out",
			  "opacity": !initialLoaded ? 0 : 1
			}}
		  >
			<div className="relative -rotate-45">
			  <span className="absolute -top-6 left-1/2 -translate-x-1/2 mt-1 inline-block bg-[#142e23] text-[#00b567] text-[10px] tracking-[-0.02em] py-0.5 px-1 rounded-lg font-[500]">
				+{userData.userRankData?.current_rank?.bonus_percentage ?? 0}%
			  </span>
			  <img className="w-5 h-5" src={userIcon} alt="User" />
			</div>
		  </div>
		</div>
		<div className="flex gap-[0.3rem] items-center pb-10 px-3">
		
		  {collapsedRanksStart.map((rank) => (
			<div key={`start-${rank?.rank}`} className={clsx(
			  "w-3 h-3 rounded-full flex items-center justify-center -ml-2 bg-[#fbda19]"
			)}>
			  <div className="w-1 h-1 bg-[#222222] rounded-full" />
			</div>
		  ))}
		  {collapsedRanksStart.length > 0 && (
			<div className="w-2 -mx-[0.1rem] bg-[#00000044] h-[0.375rem] rounded">
			  <div className="bg-[#fbda19] h-full rounded" style={{width: "100%"}} />
			</div>
		  )}
		  {visibleRanks.map((rank, i) => {
			const trueIndex = i + collapsedRanksStart.length
			const prevRank = i > 0 ? visibleRanks[i - 1] : undefined
			const rankPercentage = !prevRank || userLevel >= rank.level
			  ? "100%"
			  : userLevel > prevRank.level
				? `${(userLevel - prevRank.level) * 100 / (rank.level - prevRank.level)}%`
				: "0"
  
			const nextRank = ranks[trueIndex + 1];
			const selected = userLevel >= rank.level && (!nextRank || userLevel < nextRank.level)
  
			return (
			  <React.Fragment key={`visible-${rank?.rank}`}>
				{prevRank && (
				  <div className="flex-1 bg-[#00000044] h-[0.375rem] rounded">
					<div className="bg-[#fbda19] h-full rounded" style={{width: rankPercentage, transition: "width 200ms ease-out", transitionDelay: `${transitionDelays[trueIndex]}ms`}} />
				  </div>
				)}
				<div className="relative flex-shrink-0">
				  <div ref={selected ? (el) => setSelectedRankRef(el) : undefined} className={clsx(
					"w-3 h-3 rounded-full flex items-center justify-center",
					{"bg-[#fbda19]": userLevel >= rank.level},
					{"bg-[#fff]": !(userLevel >= rank.level)},
				  )}>
					<div className="w-1 h-1 bg-[#222222] rounded-full" />
				  </div>
				  <p className={clsx(
					"absolute leading-[1.1em] left-1/2 text-center text-[10px] text-[#fff] top-full translate-y-0.5 -translate-x-1/2",
					{"!text-[#fbda19] font-bold": userLevel >= rank.level}
				  )}>
					{t("Ranks.level")}&nbsp;{rank.level}
					<br />
					{rank?.rank ? t(`Ranks.rank-${rank.rank.toLowerCase()}`) : ""}
					<br />
					{rank.bonus_percentage !== null && (
					  <span className="mt-0.5 inline-block bg-[#142e23] text-[#00b567] text-[10px] tracking-[-0.02em] py-0.5 px-1 rounded-lg font-[500]">
						+{rank.bonus_percentage}%
					  </span>
					)}
				  </p>
				</div>
			  </React.Fragment>
			)
		  })}
		  {collapsedRanksEnd.length > 0 && (
			<div className="w-2 -mx-[0.1rem] bg-[#00000044] h-[0.375rem] rounded">
			</div>
		  )}
		  {collapsedRanksEnd.map((rank) => (
			<div key={`end-${rank?.rank}`} className={clsx(
			  "w-3 h-3 rounded-full flex items-center justify-center -mr-2",
			  {"bg-[#fbda19]": userLevel >= rank.level},
			  {"bg-[#fff]": !(userLevel >= rank.level)},
			)}>
			  <div className="w-1 h-1 bg-[#222222] rounded-full" />
			</div>
		  ))}
		</div>
		<p className="text-[#fff] text-center">
		  {rankIndex < (ranks.length - 1) ? <>
			{t("Ranks.spend")}{" "}
      <span className="text-[#fbda19] font-bold">{formatDollar(parseNum(userData.userRankData?.usd_to_next_rank))}</span>{" "}
      {t("Ranks.more-to-receive")}{" "}
      <span className="text-[#fbda19] font-bold">
        {t(`ProductDetails.card-body-amount-receive-${ranks[rankIndex + 1].rank.toLowerCase()}-nft`)}
      </span>{" "}
      {t("Ranks.and-get")}{" "}
      <span className="text-[#00b567] font-[700]">
        +{ranks[rankIndex + 1].bonus_percentage}%
      </span>{" "}
      {t("Ranks.bonus-tokens")}
		  </> : <>
			{t("Ranks.highest-rank")} <span className="text-[#fbda19] font-bold">{userData.userRankData?.current_rank?.rank ? t(`Ranks.rank-${userData.userRankData?.current_rank?.rank.toLowerCase()}`) : ""}</span>
		  </>}
		</p>
		<button
		  onClick={levelUp}
		  className={clsx(
			"text-[16px] font-[600] px-[24px] py-[6px] rounded-full border transition-colors",
			{"bg-[#fbda19] border-[#fbda19] hover:bg-[transparent] hover:text-[#fff]": canLevelUp && !levelUpLoading},
			{"bg-[#555] border-[#555] !cursor-auto": !canLevelUp || levelUpLoading}
		  )}
		>
		  {t("Ranks.level-up")}
		</button>
	  </div>
	)
  }