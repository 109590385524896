import React from "react";
import tickicn from "../assets/svgs/rdtick.svg";
import rdicn from "../assets/images/rdin.png";
import rdwhicn from "../assets/svgs/rdwhicn.svg";
import line1 from "../assets/images/rdlines (1).png";
import line2 from "../assets/images/rdlines (4).png";
import line3 from "../assets/images/rdlines (3).png";
import line4 from "../assets/images/rdlines (2).png";
import rdicon from "../assets/images/rdicn.png";
import doticon from "../assets/images/dot.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";


import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

const rdMapData = [
  {
    phase: "RoadmapSec.Phase1",
    title: "RoadmapSec.Phase1subTitle",
    options: [
      {
        "text": "RoadmapSec.Phase1li1",
        "icon": tickicn,
      },
      {
        "text": "RoadmapSec.Phase1li2",
        "icon": tickicn,
      },
      {
        "text": "RoadmapSec.Phase1li3",
        "icon": tickicn,
      },
      {
        "text":  "RoadmapSec.Phase1li4",
        "icon": tickicn,
      },
      {
        "text": "RoadmapSec.Phase1li5",
        "icon": tickicn,
      },
      {
        "text": "RoadmapSec.Phase1li6",
        "icon": tickicn,
      },
    ],
  },
  {
    phase: "RoadmapSec.Phase2",
    title: "RoadmapSec.Phase2subTitle",
    options: [
      {
        "text": "RoadmapSec.Phase2li1",
        "icon": doticon,
      },
      {
        "text": "RoadmapSec.Phase2li2",
        "icon": doticon,
      },
      {
        "text": "RoadmapSec.Phase2li3",
        "icon": doticon,
      },
      {
        "text": "RoadmapSec.Phase2li4",
        "icon": doticon,
      },
      {
        "text": "RoadmapSec.Phase2li5",
        "icon": doticon,
      },
      {
        "text": "RoadmapSec.Phase2li6",
        "icon": doticon,
      },
    ],
  },
  {
    phase: "RoadmapSec.Phase3",
    title: "RoadmapSec.Phase3subTitle",
    options: [
      {
        "text":  "RoadmapSec.Phase3li1",
        "icon": doticon,
      },
      {
        "text":  "RoadmapSec.Phase3li2",
        "icon": doticon,
      },
      {
        "text":  "RoadmapSec.Phase3li3",
        "icon": doticon,
      },
      {
        "text":  "RoadmapSec.Phase3li4",
        "icon": doticon,
      },
      {
        "text":   "RoadmapSec.Phase3li5",
        "icon": doticon,
      },
      {
        "text":    "RoadmapSec.Phase3li6",
        "icon": doticon,
      },
      {
        "text":   "RoadmapSec.Phase3li7",
        "icon": doticon,
      },
      {
        "text":   "RoadmapSec.Phase3li8",
        "icon": doticon,
      },
     
    ],
  },
  {
    phase: "RoadmapSec.Phase4",
    title: "RoadmapSec.Phase4subTitle",
    
    options: [
      {
        "text": "RoadmapSec.Phase4li1",
        "icon": doticon,
      },
      {
        "text": "RoadmapSec.Phase4li2",
        "icon": doticon,
      },
      {
        "text": "RoadmapSec.Phase4li3",
        "icon": doticon,
      },
      {
        "text":  "RoadmapSec.Phase4li4",
        "icon": doticon,
      },
      {
        "text":  "RoadmapSec.Phase4li5",
        "icon": doticon,
      },
      {
        "text":    "RoadmapSec.Phase4li6",
        "icon": doticon,
      },
      {
        "text":  "RoadmapSec.Phase4li7",
        "icon": doticon,
      },
      
     
    ],
  },
];

function RoadmapSec() {
  const { t } = useTranslation();

  return (
    <div  className=" relative  max-w-[1200px] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[94%] w-[94%] m-auto  pb-[4rem]">
      <div className="w-[100%]   2xl:space-y-[3rem] xl:space-y-[3rem] lg:space-y-[3rem] md:space-y-[3rem] sm:space-y-[3rem] space-y-[3rem]">
        <div className="h-[210px] 2xl:hidden xl:hidden lg:hidden md:hidden sm:flex justify-center flex">
          <img 
            src={rdicn}
            className=" max-w-[315px] rotate-[3deg] m-auto absolute top-[-3%] z-[9]"
            alt=""
          />
          <p id="roadmapsec"></p>
        </div>
        <div >
          <h3 className="text-center text-[#FBD914] 2xl:text-[104px] xl:text-[104px] lg:text-[104px] md:text-[104px] sm:text-[46px] text-[46px] font-[700] leading-[115%] tracking-[-1.65px] ">
          {t("RoadmapSec.title")}
          </h3>
          <img
            src={rdicn}
            className="2xl:block xl:block lg:block md:block sm:hidden hidden max-w-[295px] absolute 2xl:top-[-7%] xl:top-[-7%] lg:top-[-13%] md:top-[-13%] sm:top-[-7%] top-[-7%] left-[-3%]"
            alt=""
          />
        </div>
        <div className="2xl:flex xl:flex relative lg:flex md:flex sm:hidden hidden justify-between w-[100%]">
          <div className="space-y-[8rem] w-[45.5%]">
            <div className="bg-[#FBD914] space-y-[1rem] relative rounded-[37px] w-[550px] medwd h-[540px] w-[100%] p-[3rem]">
              <div className="px-[2rem] max-w-[180px]   w-[100%]  bg-[#000] border border-red-500 rounded-[40px] py-[13px]">
                <h4 className="text-[#FBD914] text-[30px] font-[500]">
                {t("RoadmapSec.Phase1")}
                </h4>
              </div>
              <div className="space-y-[18px]">
                <h3 className="2xl:text-[41px] xl:text-[41px] lg:text-[34px] md:text-[34px] sm:text-[34px] text-[34px] font-[700] tracking-[-1.65px]">
                {t("RoadmapSec.Phase1subTitle")}
                </h3>
                <div className="space-y-[0px]">
                   <div className="flex space-x-2 items-center">
                    <img src={tickicn} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase1li1")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={tickicn} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase1li2")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={tickicn} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase1li3")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={tickicn} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase1li4")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={tickicn} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase1li5")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={tickicn} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase1li6")}
                    </p>
                  </div>
                </div>
              </div>
              <img
                src={rdicon}
                className="absolute 2xl:max-w-[380px] xl:max-w-[380px] lg:max-w-[300px] md:max-w-[300px] sm:max-w-[380px] max-w-[380px] bottom-[-3%] right-[-9%]"
                alt=""
              />
              <div className="absolute 2xl:bottom-[6%] xl:bottom-[6.1%] lg:bottom-[2.9%] md:bottom-[2.9%] sm:bottom-[6%] bottom-[6%] right-[5%]">
                <img src={rdwhicn} alt="" />
              </div>
              <img
                src={line1}
                className="absolute 2xl:bottom-[-45%] xl:bottom-[-44.7%] lg:bottom-[-48%] md:bottom-[-48%] sm:bottom-[-47%] bottom-[-47%] z-[1] 2xl:right-[-27.3%] xl:right-[-27%] lg:right-[-40.1%] md:right-[-40.3%] sm:right-[-28%] right-[-28%]"
                alt=""
              />
            </div>
            <div className="bg-[#FBD914] space-y-[1rem] z-[1] relative rounded-[37px] w-[550px] medwd h-[540px] w-[100%] p-[3rem]">
              <div className="px-[2rem] max-w-[180px]   w-[100%]  bg-[#000] border border-red-500 rounded-[40px] py-[13px]">
                <h4 className="text-[#FBD914] text-[30px] font-[500]">
                {t("RoadmapSec.Phase2")}
                </h4>
              </div>
              <div className="space-y-[18px]">
                <h3 className="2xl:text-[41px] xl:text-[41px] lg:text-[34px] md:text-[34px] sm:text-[34px] text-[34px] font-[700] tracking-[-1.65px]">
                {t("RoadmapSec.Phase2subTitle")}
                </h3>
                <div className="space-y-[0px]">
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase2li1")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase2li2")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase2li3")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase2li4")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase2li5")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase2li6")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute 2xl:bottom-[5%] xl:bottom-[5.5%] lg:bottom-[6%] md:bottom-[6%] sm:bottom-[6%] bottom-[6%] right-[6%]">
                <img src={rdwhicn} alt="" />
              </div>
              <img
                src={line3}
                className="absolute 2xl:bottom-[-45.7%]  xl:bottom-[-45.2%] rotate-[-3deg] bottom2 lg:bottom-[-45.3%] md:bottom-[-45.8%] sm:bottom-[-47%] bottom-[-47%]  2xl:right-[-26.5%] xl:right-[-26.5%] lg:right-[-39.6%] md:right-[-40%] sm:right-[-28%] right-[-28%] "
                alt=""
              />
            </div>{" "}
          </div>
          <div className="2xl:space-y-[8rem] xl:space-y-[8.1rem] lg:space-y-[7.2rem] md:space-y-[7.2rem] sm:space-y-[8rem] space-y-[8rem] 2xl:mt-[17.2rem] xl:mt-[17rem] lg:mt-[18.3rem] md:mt-[18.3rem] sm:mt-[18rem] mt-[18rem] w-[45.5%]">
            <div className="bg-[#FBD914] space-y-[1rem] relative  rounded-[37px] w-[550px] medwd h-[540px] w-[100%] p-[3rem]">
              <div className="px-[2rem] max-w-[180px]   w-[100%]  bg-[#000] border border-red-500 rounded-[40px] py-[13px]">
                <h4 className="text-[#FBD914] text-[30px] font-[500]">
                {t("RoadmapSec.Phase3")}
                </h4>
              </div>
              <div className="space-y-[18px]">
                <h3 className="2xl:text-[41px] xl:text-[41px] lg:text-[34px] md:text-[34px] sm:text-[34px] text-[34px] font-[700] tracking-[-1.65px]">
                {t("RoadmapSec.Phase3subTitle")}
                </h3>
                <div className="space-y-[0px]">
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase3li1")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase3li2")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase3li3")}

                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase3li4")}

                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase3li5")}

                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase3li6")}

                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase3li7")}

                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase3li8")}

                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute 2xl:bottom-[6%] xl:bottom-[5.8%] lg:bottom-[6%] md:bottom-[6%] sm:bottom-[6%] bottom-[6%] left-[5%]">
                <img src={rdwhicn} alt="" />
              </div>
              <img
                src={line2}
                className="absolute 2xl:bottom-[-69.6%] xl:bottom-[-69.5%] lg:bottom-[-69.2%] md:bottom-[-69.2%] sm:bottom-[-70%] bottom-[-70%]  2xl:left-[-29%] xl:left-[-29%] lg:left-[-45%] md:left-[-45%] sm:left-[-31%] left-[-31%] 2xl:rotate-[6deg] xl:rotate-[6deg] lg:rotate-[7deg] md:rotate-[7deg] sm:rotate-[6deg] rotate-[6deg]"
                alt=""
              />
            </div>
            <div className="bg-[#FBD914] space-y-[1rem] relative rounded-[37px] w-[550px] medwd h-[540px] w-[100%] p-[3rem]">
              <div className="px-[2rem] max-w-[180px]   w-[100%]  bg-[#000] border border-red-500 rounded-[40px] py-[13px]">
                <h4 className="text-[#FBD914] text-[30px] font-[500]">
                {t("RoadmapSec.Phase4")}
                </h4>
              </div>
              <div className="space-y-[18px]">
                <h3 className="2xl:text-[41px] xl:text-[41px] lg:text-[34px] md:text-[34px] sm:text-[34px] text-[34px] font-[700] tracking-[-1.65px]">
                {t("RoadmapSec.Phase4subTitle")}
                </h3>
                <div className="space-y-[0px]">
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase4li1")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase4li2")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase4li3")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase4li4")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase4li5")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase4li6")}
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <img src={doticon} alt="" />
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[20px] text-[18px] font-[600]">
                    {t("RoadmapSec.Phase4li7")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute 2xl:bottom-[6%] xl:bottom-[6%] lg:bottom-[5.3%] md:bottom-[5.3%] sm:bottom-[6%] bottom-[6%] left-[5%]">
                <img src={rdwhicn} alt="" />
              </div>
              <img
                src={line4}
                className="absolute 2xl:bottom-[-64.1%] bottommed xl:bottom-[-64%] lg:bottom-[-45.6%] md:bottom-[-45.6%] sm:bottom-[-54%] bottom-[-54%]  2xl:left-[20%] xl:left-[20%] lg:left-[25%] md:left-[25%] sm:left-[19%] left-[19%] "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="2xl:hidden relative rdmap xl:hidden lg:hidden md:hidden sm:block block pt-8 w-[100%]">
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            868: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
          navigation={{ clickable: true }}
          modules={[Navigation]}
          className="mySwiper  h-[690px] rdmap"
        >
          {rdMapData.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="bg-[#FBD914] space-y-[1rem] rounded-[37px] max-w-[550px] h-[680px] w-[100%] p-[3rem]">
                <div className="px-[2rem] max-w-[173px] w-[100%] bg-[#000] border-[2px] border-red-600 rounded-[40px] py-[13px]">
                  <h4 className="text-[#FBD914] text-[30px] font-[500]">
                    {t(item.phase)}
                  </h4>
                </div>
                <div className="space-y-[18px]">
                  <h3 className="text-[30px] font-[700] tracking-[-1.65px]">
                    {t(item.title)}
                  </h3>
                  <div className="space-y-[0px]">
                    {item.options.map((option, i) => (
                      <div key={i} className="flex space-x-2 items-center">
                        <img src={option.icon} alt="Tick icon" />
                        <p className="text-[20px] font-[600]">{t(option.text)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <img
          src={rdicon}
          className="absolute max-w-[300px] bottom-[-8%] right-[1%] z-[1]"
          alt=""
        />
      </div>
    </div>
  );
}

export default RoadmapSec;
