import React from "react";
import { useTranslation } from "react-i18next";


import hvawr from "../assets/images/hvawr.png"
import HDicon from "../assets/svgs/hvricn (3).svg"
import HDicon2 from "../assets/svgs/hvricn (1).svg"
import HDicon3 from "../assets/svgs/hvricn (2).svg"
import Xicon1 from "../assets/svgs/xicon (1).svg"
import Xicon2 from "../assets/svgs/xicon (2).svg"
import Xicon3 from "../assets/svgs/xicon (3).svg"
import Xicon4 from "../assets/svgs/xicon (4).svg"
import Xicon5 from "../assets/svgs/xicon (5).svg"



const HoverDropDn = ({onLanguageChange, locales, setMenuOpen}) => {
    const {t} = useTranslation();
    // const handleClick = (lang) => {
    //     i18n.changeLanguage(lang);
    // };
    const handleLangSelection = (locale) => {
        // const selectedLang = locales[locale];
        setMenuOpen(false)
        onLanguageChange(locale); 
    };
    return (
        <>
      <div className="border-[2.5px] 2xl:block xl:block lg:block md:block sm:hidden hidden border-[#9d9d9d] rounded-[20px] ">
         <div className=" xs:p-3 navpopbggr rounded-t-[20px] ">
         <div className="relative z-[0]  hovdrobr xs:py-3 pt-12 flex flex-col justify-between" >
        <img src={hvawr} className="fixed h-[46px] top-[-6.5%] right-[14%] z-[99]" alt="" />
        <div className="w-[41px] h-[3px] fixed top-[-0.4%] right-[15.2%] bg-[#000]"></div>
            {/* <img className="xs:hidden w-[40px] absolute -top-[3.5%] right-[19%]" src={ret} alt="" /> */}
            <div className=" px-12 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden justify-between ">
                <div className="space-y-[35px]">
                    <div>
                        <h3 className="text-[20px]   font-[700] text-[#fff] font">{t("main.hoverDropDn-title1")}</h3>
                    </div>
                    <div className="flex space-x-4 items-center">
                        <img className="w-[42px] h-[42px]" src={HDicon} alt="" />
                        <div className="">
                            <a  href="/" >
                            <h3 className=" text-[22px] text-[#FBD914] font t leading-[23px] font-[700]">{t("main.hoverDropDn-subTitle1")}</h3>
                                <p className="text-white text-[14px] font">{t("main.hoverDropDn-para1")}</p>
                            </a>
                        </div>

                    </div>
                    <div className="flex space-x-4 items-center">
                        <img className="w-[42px] h-[42px]" src={HDicon3} alt="" />
                        <div className="">
                            <a  href="/" >
                            <h3 className=" text-[23px] text-[#FBD914] font leading-[20px] font-[700]">{t("main.hoverDropDn-subTitle2")}</h3>
                                <p className="text-white font">{t("main.hoverDropDn-para2")}</p>
                            </a>
                        </div>

                    </div>
                    <div className="flex space-x-4 items-center">
                        <img className="w-[42px] h-[48px]" src={HDicon2} alt="" />
                        <div className="">
                            <a href="/">
                            <h3 className=" text-[22px] text-[#FBD914] font  leading-[20px] font-[700]">{t("main.hoverDropDn-subTitle3")}</h3>
                                <p className="text-white font">{t("main.hoverDropDn-para3")}</p>
                            </a>
                        </div>

                    </div>

                </div>
                <div className="space-y-[35px]">
                    <div>
                        <h3 className="text-[20px]  font-[700] font  text-[#fff]">{t("main.hoverDropDn-title2")}</h3>
                    </div>
                    <div className="">
                        <a target="_blank" href="https://x.com/DogeKombatGame" rel="noreferrer">
                            <div className="pb-[22px] flex items-center space-x-4">
                                <img className="w-[20px]" src={Xicon4} alt="" />
                                <p className="text-[16px]  font text-[#fff] font-[500] ">X.com</p>
                            </div>
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCb7wF6PIm-m3E9xLTOTvuzw">
                            <div className="pb-[22px] flex items-center space-x-4">
                                <img className="w-[20px]" src={Xicon3} alt="" />
                                <p className="text-[16px] text-[#fff] font-[500] font ">Youtube </p>
                            </div>
                        </a>

                        <a target="_blank" href="https://www.instagram.com/dogekombatgame/" rel="noreferrer">
                            <div className="pb-[22px] flex items-center space-x-4">
                                <img className="w-[20px] " src={Xicon2} alt="" />
                                <p className="text-[16px] font text-[#fff] font-[500] ">Instagram</p>
                            </div>
                        </a>
                        <a target="_blank" href="https://t.me/dogekombatgame_chat" rel="noreferrer">
                            <div className="pb-[22px] flex items-center space-x-4">
                                <img className="w-[20px]" src={Xicon1} alt="" />
                                <p className="text-[16px] font text-[#fff] font-[500] ">Telegram</p>
                            </div>
                        </a>
                        <a target="_blank" href="https://linktr.ee/dogekombatgame" rel="noreferrer">
                            <div className="flex items-center space-x-4">
                                <img className="w-[20px]" src={Xicon5} alt="" />
                                <p className="text-[16px] text-[#fff] font-[500] font ">Linktree</p>
                            </div>
                        </a>

                    </div>

                </div>
                <div className="space-y-[35px]">
                    <div>
                        <h3 className="text-[20px]  font-[700]  text-[#fff] font">{t("main.hoverDropDn-title3")}</h3>
                    </div>
                    <div className="">
                        <div className=" gap-y-[1rem] grid grid-cols-2">
                        {Object.keys(locales).map((locale) => (
                                <div
                                    key={locale}
                                    className="flex items-center space-x-4 cursor-pointer"
                                    onClick={() => handleLangSelection(locale)}
                                >
                                    <img src={locales[locale].img} alt="" />
                                    <p className="text-[16px] text-[#fff] font-[500] font">
                                        {locales[locale].title}
                                    </p>
                                </div>
                            ))}
                        </div>
                      
                    </div>


                </div>
            </div>
                
            {/* <div className="block xs:hidden sm:hidden py-5 rounded-b-[17.81px] navpopbggr  px-[40px]">
                <div className="flex justify-between items-center">
                    <h3 className="text-[18px] font-[Inter] text-white font-[700]">Need support?</h3>
                    <a href="" className="underline font-[Inter] text-white font-[400] text-[18px]">Join our 24/7 Live Chat Support on Telegram</a>
                </div>
            </div>
            <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block space-y-2 px-4  ">
                <div className="space-y-1">
                {Object.keys(locales).map((locale) => (
                                <div
                                    key={locale}
                                    className="flex items-center space-x-4 pt-2 cursor-pointer"
                                    onClick={() => handleLangSelection(locale)}
                                >
                                    <img className="h-[20px]" src={locales[locale].img} alt="" />
                                    <p className="text-[12px] text-[#fff] font-[500] font-[Inter]">
                                        {locales[locale].title}
                                    </p>
                                </div>
                            ))}
                </div>
              
            </div> */}

        </div>
       </div>
        <div className="bg-[#FBD914] w-[100%] py-[1rem] px-8 rounded-b-[20px] flex justify-between">
                    <h4 className="text-[20px] font font-[700]">{t("main.hoverDropDn-footerTitle")}</h4>
                    <a href="https://t.me/dogekombatgame_chat" target="_blank"  rel="noopener noreferrer">
                    <h5 className="text-[20px] font font-[400] underline">{t("main.hoverDropDn-footerPara")}</h5>
                    </a>
                </div>
      </div>
      <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block">
                        <div className=" gap-y-[1rem] grid grid-cols-2">
                        {Object.keys(locales).map((locale) => (
                                <div
                                    key={locale}
                                    className="flex items-center space-x-4 cursor-pointer"
                                    onClick={() => handleLangSelection(locale)}
                                >
                                    <img src={locales[locale].img} alt="" />
                                    <p className="text-[16px] text-[#fff] font-[500] font">
                                        {locales[locale].title}
                                    </p>
                                </div>
                            ))}
                        </div>
                      
                    </div>

        </>
    )
}
export default HoverDropDn