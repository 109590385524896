import React from "react";
// import ambcicn1 from "../assets/svgs/ambcicn (1).svg"
// import ambcicn2 from "../assets/svgs/ambcicn (2).svg"
import verfimg1 from "../assets/svgs/verfimg (1)_new.svg"
import verfimg2 from "../assets/svgs/verfimg (2)_new.svg"
import moblock from "../assets/images/lockmob.png"


const ConfidenceSeecdata = [
    {
        id:1,
        title:"Team is KYC Verified",
        icon:verfimg1,
        para:"The Doge Kombat team has completed KYC verification, ensuring transparency and trust.",
        para2:"This process thoroughly vets the team, instilling confidence in our community.",
        link:'https://github.com/coinscope-co/kyc/blob/main/doko/kyc.png'
      },
    {
        id:2,
        title:"Audited By SolidProof",
        icon:verfimg2,
        para:"Doge Kombat has been audited by SolidProof, a top blockchain auditing firm.",
        para2:"This audit ensures high standards of security and integrity.",
        link: 'https://github.com/solidproof/Projects/blob/main/2024/DogeKombat/Solana_Audit_SolidProof_DOGE%20KOMBAT.pdf'
      },
]


function ConfidenceSeec() {
  return (
    <div className="xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[90%] w-[90%] max-w-[1200px] mx-auto pb-[3rem] 2xl:pt-[3rem] xl:pt-[3rem] lg:pt-[3rem] md:pt-[3rem] sm:pt-[0rem] pt-[0rem] 2xl:space-y-[3rem] x:space-y-[3rem] lg:space-y-[3rem] md:space-y-[3rem] sm:space-y-[1.5rem] space-y-[1.5rem]">
        <div className="flex space-y-[10px] flex-col items-center mt-10 md:mt-0">
          <div className="2xl:hidden xl:hidden lg:hidden md:hidden mb-[1rem] sm:flex flex justify-center ">
            <img src={moblock} alt="" />
          </div>
            <h5 className="text-[24px] font-[700] text-[#fff]">100% SECURE AND VERIFIED</h5>
            <h3 className="text-center 2xl:leading-[120%] xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[110%] leading-[110%] text-[46px] font-[700] text-[#FBD914]">Buy with Confidence</h3>
        </div>
    <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-center  2xl:space-x-[1rem] xl:space-x-[1rem] lg:space-x-[1rem] md:space-x-[1rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[1rem] space-y-[1rem] items-center ">
    {
        ConfidenceSeecdata.map((item)=>(
           <div className="Confidencegrbg w-[100%] relative overflow-hidden flex p-6 rounded-[22px] border-2 border-[#595959f5]">
          <div className="space-y-[15px] w-[100%] max-w-[360px]">
          <h3 className="text-[26px] font-[600] text-[#fff] leading-[120%]">{item.title}</h3>
            {/* For Desktop */}
            <p className="hidden md:block text-[14px] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[66%] w-[66%] leading-[120%] font-[400] text-[#fff]">{item.para}{item?.para2}</p>
            {/* For Mobile */}
            <p className="md:hidden w-[60%] text-[14px] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] leading-[120%] font-[400] text-[#fff]">{item.para}<br /> <br />{item?.para2}</p>
            <a className="font-[700]  text-[#FBD914] text-[12px] uppercase underline" href={item?.link}>Learn More</a>
          </div>
          <div className="absolute md:h-[145px] right-[-4px] bottom-[-8px] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[25%] sm:w-[40%] w-[40%]">
            <img src={item.icon} className="w-[100%]" alt="" />
          </div>
           </div>
        ))
     }
    </div>
    </div>
  );
}

export default ConfidenceSeec;
