import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import whtimg from "../assets/images/comp 5.gif"


function WhatIsDOKO() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getAnchorHref = () => {
    return screenSize >= 768 ? "#hero" : "#herosec";
  };

  return (
   <div className="reltiave z-[1] max-w-[1200px] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[94%] w-[94%] m-auto  py-[4rem]">
     <div className="w-[100%]  px-[2rem] pb-[3rem] pt-[2rem] whatbggrsec space-y-[1rem]">
          
            <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[3rem] space-y-[3rem] items-center">
            <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%] space-y-[30px]">
                <h3 className="text-[#FBD914] text-[40px] font-[700] text-center md:text-left">{t("WhatIsDOKOsec.title")}</h3>
                    <p className="text-[20px] font-[400] text-[#fff]">{t("WhatIsDOKOsec.para")}</p>
                    <ul className="pl-5 space-y-[20px] 2xl:w-[91%]">
                        <li className="text-[20px] font-[400] list-decimal text-[#fff]"><span className="font-[700]">{t("WhatIsDOKOsec.span1")}</span> {t("WhatIsDOKOsec.para1")}</li>
                        <li className="text-[20px] font-[400] list-decimal text-[#fff]"> <span className="font-[700]">{t("WhatIsDOKOsec.span2")}</span> {t("WhatIsDOKOsec.para2")}</li>
                        <li className="text-[20px] font-[400] list-decimal text-[#fff]"><span className="font-[700]">{t("WhatIsDOKOsec.span3")}</span> {t("WhatIsDOKOsec.para3")}</li>
                        <li className="text-[20px] font-[400] list-decimal text-[#fff]"><span className="font-[700]">{t("WhatIsDOKOsec.span4")}</span> {t("WhatIsDOKOsec.para4")}</li>
                    </ul>
                    
                    <a href={getAnchorHref()} className="2xl:block xl:block lg:block md:block sm:hidden hidden">
                    <button className=" bg-[#FBD914] w-[200px] h-[50px] rounded-[50px] font-[600] hover:text-[#fff] border border-[#FBD914] hover:bg-[transparent]">{t("WhatIsDOKOsec.btn")}</button>
                    </a>
                </div>
              <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%] relative">
              <div className="flex  2xl:h-[590px] xl:h-[590px] lg:h-[590px] md:h-[590px] sm:h-[500px] h-[500px] whatbggrcard justify-center ">
                    <img src={whtimg} className="absolute top-[-7%]  2xl:h-[670px] xl:h-[670px] lg:h-[670px] md:h-[700px] sm:h-[580px] h-[580px] ml-1" alt="" />

                    <div className="overflow-hidden w-[50%] absolute right-0 z-[-1] flex justify-end items-center top-0 h-[100%]">
              <div className="circleshadow2 "></div>
            </div>
                </div>
              </div>
              <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:flex flex justify-center">
            <a href={getAnchorHref()}>
            <button className="  bg-[#FBD914] w-[200px] h-[50px] rounded-[50px] font-[600] hover:text-[#fff] border border-[#FBD914] hover:bg-[transparent]">{t("WhatIsDOKOsec.btn")}</button>
            </a>
              </div>
            </div>
       </div>
       </div>
  );
}

export default WhatIsDOKO;