import React from "react";
import whtimg from "../assets/images/whtimg.png"
import { useTranslation } from "react-i18next";
import File from "../assets/PDF/DogeKombat Whitepaper.pdf"


function WhitepaperSec() {
  const { t } = useTranslation();
  const handleClick = () => {
    
    const pdfUrl = File;

    window.open(pdfUrl);
  };

  return (
  <div className="relative mt-10 md:mt-0">
    <div className=" w-[100%]">
    <div className=" max-w-[1200px] 2xl:w-[86%] relative z-[0] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[94%] w-[94%] m-auto 2xl:pt-[4rem] xl:pt-[4rem] lg:pt-[4rem]  md:pt-[4rem] sm:pt-[0rem] pt-[0rem] 2xl:pb-[0rem] xl:pb-[0rem] lg:pb-[0rem] md:pb-[0rem] sm:pb-[0rem] pb-[0rem]">
     <div className="w-[100%]  2xl:px-[6rem] xl:px-[6rem] lg:px-[6rem] md:px-[6rem] sm:px-[2rem] px-[2rem] pb-[3rem] pt-[2rem] whitepaper space-y-[1rem]">
            <div>
                <h3 className="text-[#FBD914] 2xl:text-start xl:text-start lg:text-start md:text-start sm:text-center text-center 2xl:text-[104px] xl:text-[104px] lg:text-[80px] md:text-[72px] sm:text-[46px] text-[46px] font-[700] leading-[115%] tracking-[-1.65px] ">{t("WhitepaperSec.title")}</h3>
            </div>
            <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between items-center 2xl:space-y-[0px] xl:space-y-[0px] lg:space-y-[0px] md:space-y-[0px] sm:space-y-[2rem] space-y-[2rem]">
            <div className="2xl:w-[60%] xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[100%] w-[100%] space-y-[30px]">
                    <p className="2xl:text-start xl:text-start lg:text-start md:text-start sm:text-center text-center text-[20px] font-[400] text-[#fff]">{t("WhitepaperSec.para1")}</p>
                   <div  className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden 2xl:justify-start xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center">
                   <button onClick={handleClick} className="bg-[#FBD914] w-[240px] h-[50px] rounded-[50px] font-[600] hover:text-[#fff] border border-[#FBD914] hover:bg-[transparent]">{t("WhitepaperSec.btn")}</button>
                   </div>
                </div>
                <div className="2xl:w-[38%] xl:w-[38%] lg:w-[38%] md:w-[38%] sm:w-[100%] w-[100%] flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-center justify-center">
                    <img src={whtimg} className="" alt="" />
                </div>
                    

                <div  className="2xl:hidden xl:hidden lg:hidden md:hidden sm:flex flex 2xl:justify-start xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center">
                   <button onClick={handleClick} className="bg-[#FBD914] w-[240px] h-[50px] text-[18px] rounded-[50px] font-[700] hover:text-[#fff] border border-[#FBD914] hover:bg-[transparent]">{t("WhitepaperSec.btn")}</button>
                   </div>
            </div>
       </div>
       </div>
    </div>
  </div>
  );
}

export default WhitepaperSec;
