import React from "react";
import { useTranslation } from "react-i18next";

import advimg1 from "../assets/svgs/advimg (1).svg"
import advimg2 from "../assets/svgs/advimg (3).svg"
import advimg3 from "../assets/svgs/advimg (4).svg"
import advimg4 from "../assets/svgs/advimg (2).svg"



const AdvantagesData = [
    {
        id:1,
        icon:advimg1,
        title:"Advantages.cardtitle1",
        para:"Advantages.cardpara1",
    },
    {
        id:2,
        icon:advimg3,
        title:"Advantages.cardtitle2",
        para:"Advantages.cardpara2",
    },
    {
        id:3,
        icon:advimg2,
        title:"Advantages.cardtitle3",
        title2:"Advantages.doko_tokens",
        para:"Advantages.cardpara3",
    },
    {
        id:4,
        icon:advimg4,
        title:"Advantages.cardtitle4",
        para:"Advantages.cardpara4",
    },
    
]


function AdvantagesSec() {
  const { t } = useTranslation();

  return (
   <div className="relative z-[1] overflow-hidden">
     <div className=" 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[90%] w-[90%] max-w-[1200px] mx-auto pb-[4rem] ">
        <div className="advbggr  2xl:px-[2.5rem] xl:px-[2.5rem] lg:px-[2.5rem] md:px-[2.5rem] sm:px-[0rem] px-[0rem] 2xl:py-[3rem] xl:py-[3rem] lg:py-[3rem]  md:py-[3rem] sm:py-[0rem] py-[0rem]  2xl:space-y-[3rem] xl:space-y-[3rem] lg:space-y-[3rem] md:space-y-[3rem] sm:space-y-[2rem] space-y-[2rem] ">
            <div className="space-y-[20px]">
            <h3 className="2xl:block xl:block lg:block md:block sm:hidden hidden 2xl:max-w-[100%] xl:max-w-[100%] lg:max-w-[100%] md:max-w-[100%] sm:max-w-[290px] max-w-[290px] w-[100%] m-auto 2xl:leading-[120%] xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[110%] leading-[110%] text-center text-[46px] font-[700] text-[#FBD914]">{t("Advantages.mainTitle")} <br />
            {t("Advantages.mainTitle2")}</h3>
              <h3 className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block  w-[100%] m-auto 2xl:leading-[120%] xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[110%] leading-[110%] text-center text-[46px] font-[700] text-[#FBD914]">{t("Advantages.mainTitle3")} <br /> {t("Advantages.mainTitle4")}<br /> {t("Advantages.mainTitle5")}<br />
              {t("Advantages.mainTitle6")} <br /> {t("Advantages.mainTitle7")}</h3>
            </div>
            <div className="2xl:flex xl:flex lg:flex md:flex sm:block block 2xl:space-x-[1.5rem] xl:space-x-[1.5rem] lg:space-x-[1.5rem] md:space-x-[1.5rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[1rem] space-y-[1rem] justify-center 2xl:px-[0rem] xl:px-[0rem] lg:px-[0rem] md:px-[0rem] sm:px-[2rem] px-[2rem]">
            {
        AdvantagesData.map((item)=>(
            <div className="w-[100%] 2xl:space-y-[25px] xl:space-y-[25px] lg:space-y-[25px] md:space-y-[25px] sm:space-y-[35px] space-y-[35px] adcardbggr flex flex-col items-center justify-between rounded-[37px] border-2 border-[#595959f5] py-6 2xl:px-6 xl:px-6 lg:px-6 md:px-6 sm:px-4 px-4">
               <div className="space-y-[15px]">
               <h4 
                className="text-center leading-[120%] text-[#fff] text-[26px] font-[600]" 
                dangerouslySetInnerHTML={{ __html: `${t(item.title)} <br> ${t(item?.title2)}` }} 
                />
               <p className="text-center leading-[120%] text-[14px] font-[400] text-[#fff]">{t(item.para)}</p>
               </div>
               <img src={item.icon} alt="" />
            </div>
        ))
     }
            </div>
        </div>
    </div>
    <div className="circlebuy3 z-[-1] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block absolute top-[23%] right-[-230%]"></div>
   </div>
  );
}

export default AdvantagesSec;
