import React from "react";
import HeroSec from "../Sections/HeroSection";
import TopbannerSec from "../Sections/TopbannerSec";
import SecbarSec from "../Sections/SecbarSec";
import BrandsSec from "../Sections/BrandsSec";
import MostViralSec from "../Sections/MostViralSec";
// import AmbassadorsSec from "../Sections/AmbassadorsSec";
// import MediaSaySec from "../Sections/MediaSaysSec";
// import YouTubeReviewSec from "../Sections/YouTubeReviewSec";
import ConfidenceSeec from "../Sections/ConfidenceSeec";
import IfYouMissedSec from "../Sections/IfYouMissedSec";
import ViralEngineSec from "../Sections/ViralEngineSec";
import PreSaleSec from "../Sections/PreSaleSec";
import AdvantagesSec from "../Sections/AdvantagesSec";
import RewardSection from "../Sections/RewardSection";
import HowToButSec from "../Sections/howtobuySec";
import TapYourWaySec from "../Sections/TapYourWaySec";
import BuiltDifferentSec from "../Sections/BuiltDifferentSec";
import WhitepaperSec from "../Sections/WhitepaperSec";
import RoadmapSec from "../Sections/RoadmapSec";
import WhatIsDOKO from "../Sections/WhatIsDOKO";
import DOKOnomicsSec from "../Sections/DOKOnomicsSec";
import BreakingSec from "../Sections/BreakingSec";
import PressReleaseSec from "../Sections/PressReleaseSec";
import PoweredSec from "../Sections/PoweredSec";
import Faqs from "../Sections/Faqs";
import RevolutionarSec from "../Sections/RevolutionarSec";
import SomeQuestions from "../Sections/SomeQuestions";

function Home() {
  return (
    <div className="pt-[6rem]">
    <HeroSec />
    <TopbannerSec />
    <BrandsSec />
    <MostViralSec />
    <SecbarSec />
    {/* <AmbassadorsSec />
    <MediaSaySec />
    <YouTubeReviewSec /> */}
    <ConfidenceSeec />
    <IfYouMissedSec />
    <ViralEngineSec />
    <PreSaleSec />
    <AdvantagesSec />
    <RewardSection />
    <SomeQuestions />
    <HowToButSec />
    <TapYourWaySec />
    <BuiltDifferentSec />
    <WhitepaperSec />
    <WhatIsDOKO />
    <RevolutionarSec />
    <DOKOnomicsSec />
    <RoadmapSec />
    <BreakingSec />
    <PressReleaseSec />
    <PoweredSec />
    <Faqs />

    </div>
  );
}

export default Home;
