import React,{useState} from "react";
import tool from "../assets/svgs/tool.svg"
import que from "../assets/svgs/hqeu.svg";
import { useTranslation } from "react-i18next";


const RewardCard = ({ item, amountRange, handleRange, handleImageClick }) => {
    const { t } = useTranslation();
    const [visibleTooltipIndex, setVisibleTooltipIndex] = useState(null);

    const handleMouseEnter = (index) => {
      setVisibleTooltipIndex(index);
    };
  
    const handleMouseLeave = () => {
      setVisibleTooltipIndex(null);
    };
  
    return (

        <div className="rounded-md  bgposter 2xl:py-4 xl:py-4 lg:py-4 md:py-4 sm:py-0 py-0">
            <div className=" 2xl:flex xl:flex lg:flex md:flex sm:block block justify-between 2xl:space-y-0 xl:space-y-0 lg:space-y-0 md:space-y-0 sm:space-y-4 space-y-4 2xl:px-4 xl:px-4 lg:px-4 md:px-4 sm:px-0 px-0   w-[100%] sm:w-[100%] xs:w-[100%] ">
                {
                    <>
                     <div className=" pt-4 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block">
                                <img className=" m-auto border rounded-[31px] border-[#FBD914] min-w-[295px] w-auto" src={item.spendImage} alt="" />
                            </div>
                        <div className="space-y-4 2xl:w-[55%] xl:w-[55%] flex flex-col 2xl:items-start xl:items-start lg:items-start md:items-start sm:items-center items-center lg:w-[55%] md:w-[55%] sm:w-[100%] w-[100%]  2xl:pr-2 xl:pr-2 lg:pr-2 md:pr-2 sm:pr-0 pr-0">
                            <div className="w-[100%]">

                                <h3 className="2xl:pl-0 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0 pl-0 2xl:text-start xl:text-start lg:text-start md:text-start sm:text-center text-center 2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[26px] text-[26px] font-[600] 2xl:text-[#fff] xl:text-[#fff] lg:text-[#fff] md:text-[#fff] sm:text-[#FBD914] text-[#FBD914] 2xl:pt-2 xl:pt-2 lg:pt-2 md:pt-2 sm:pt-0 pt-0 ">{t(item.packageType)}</h3>
                            </div>
                            <div className="space-y-2 ">
                                {item.packageList.map((j, id) => (
                                    <div key={id} className="relative flex justify-start space-x-2 items-center ">
                                        <img className="w-[13px]" src={j.icon} alt="" />
                                        <p className=" text-white  text-[#222222] font-[400]  2xl:text-[12px] xl:text-[12px] lg:text-[12px] md:text-[12px] sm:text-[18px] text-[18px]">{t(j.text)}</p>
                                        <div className="">
                                            <img
                                                className="w-[12px] h-[13px] cursor-pointer"
                                                src={que}
                                                alt=""
                                                onMouseEnter={() => handleMouseEnter(id)}
                                                onMouseLeave={handleMouseLeave}
                                                style={{ cursor: "pointer" }}
                                              />
                                              {visibleTooltipIndex === id && (
                                               <div className="bg-[#212121] rounded-[8px] absolute 2xl:right-[-63%] xl:right-[-63%] lg:right-[-63%] md:right-[-63%] sm:right-[-10%] right-[-10%] bottom-[126%] p-3 2xl:w-[350px] xl:w-[350px] lg:w-[350px] md:w-[350px] sm:w-[300px] w-[300px]">
                                                <h3 className="text-[13px] font-[500]  text-[#fff] ">
                                                {t(j.tooltiptitle)}
                                                </h3>
                                                 <p className="text-[12px] font-[400]  text-[#fff] ">
                                                  {t(j.tooltip)}
                                                </p>
                                               </div>
                                              )}
                                            
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden 2xl:justify-start xl:justify-start lg:justify-start md:justify-start sm:justify-center  justify-center">
               <a href="#hero" className="w-[200px]">
               <button className="w-[200px] bg-[#FBD914] rounded-full   h-[46px] hover:text-[#fff] border border-[#FBD914] text-[18px] font-[700] hover:bg-[transparent]  m-auto ">{t("WhatIsDOKOsec.btn")}</button>
               </a>
            </div>
                        </div>
                        <div className="2xl:space-y-2 xl:space-y-2 lg:space-y-2 md:space-y-2 sm:space-y-4 space-y-4  2xl:w-[45%] xl:w-[45%] lg:w-[45%] md:w-[45%] sm:w-[100%] w-[100%]">
                            <div className="tracking-wide xs:pt-3 text-center text-[#292A36] font-bold font-Inter">
                                <h5 className="text-[14px] 2xl:block xl:block lg:block md:block sm:hidden hidden xs:text-[10px] text-white ">{t("RewardSection.whenSpend")}</h5>
                                <h3 className="text-[16px] 2xl:block xl:block lg:block md:block sm:hidden hidden sm:text-[14px] text-white xs:text-[10px] ">{`$${Number(item.spendPrice).toLocaleString('en-US')}  ${t("RewardSection.more")}`}</h3>
                            </div>
                            <div className=" pb-3 2xl:block xl:block lg:block md:block sm:hidden hidden">
                                <img className=" m-auto border rounded-[26px] border-[#FBD914] w-auto" src={item.spendImage} alt="" />
                            </div>
                        </div>
                    </>

                }
            </div>
          
        </div>

    )
}
export default RewardCard;