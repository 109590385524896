import React from "react";
import { useTranslation } from "react-i18next";

import img1 from "../assets/images/pimg (1).png";
import img2 from "../assets/images/pimg (2).png";
import cardicn1 from "../assets/svgs/whyicn (1).svg";
import cardicn2 from "../assets/svgs/whyicn (5).svg";
import cardicn3 from "../assets/svgs/whyicn (4).svg";
import cardicn4 from "../assets/svgs/whyicn (3).svg";
import cardicn5 from "../assets/svgs/whyicn (2).svg";

function RevolutionarSec() {
  const { t } = useTranslation();

  return (
    <div className="relative z-[1] max-w-[1200px] space-y-[3rem] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[90%] w-[90%] m-auto  pb-[2rem]">
      <div className="relative 2xl:space-y-[1rem] xl:space-y-[1rem] lg:space-y-[1rem] md:space-y-[1rem] sm:space-y-[0rem] space-y-[0rem]">
        <h3 className="text-[46px] font-[700] text-[#FDDB12] tracking-[-1.65px] 2xl:leading-[120%] xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[106%] leading-[106%] text-center">
        {t("RevolutionarySec.title1")} <span className="text-[#fff] ">{t("RevolutionarySec.title2")} </span>{t("RevolutionarySec.title3")}
        </h3>
        <img src={img1} className="absolute 2xl:block xl:block lg:block md:block sm:hidden hidden top-[14%] left-[15%]" alt="" />
        <img src={img2} className="absolute 2xl:block xl:block lg:block md:block sm:hidden hidden top-[14%] right-[18%]" alt="" />
        <div className="flex space-x-2 xl:hidden py-2 xl:hidden lg:hidden md:hidden sm:flex justify-center ">
        <img src={img1} className="" alt="" />
        <img src={img2} className="" alt="" />
        </div>
        <p className="text-[#fff] max-w-[660px] w-[100%] m-auto   text-center text-[20px] font-[400]">
        {t("RevolutionarySec.Para")}
        </p>
       
      </div>
      <div className="space-y-[2rem]">
        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[2rem] space-y-[2rem] ">
          <div className=" 2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%] gradientborder">
            <div className="flex justify-center w-[100%] flex-col items-center  whycardbggr px-[2rem] b-[2rem] px-[3rem] 2xl:h-[516px] xl:h-[516px] lg:h-[516px] md:h-[516px] sm:h-[650px] h-[620px] space-y-[1rem] relative overflow-hidden">
              <img className="2xl:w-[28%] xl:w-[28%] lg:w-[28%] md:w-[28%] sm:w-[50%] w-[50%]" src={cardicn1} alt="" />
              <h3 className="text-[48px] font-[700] text-[#FDDB12] max-w-[300px] text-center leading-[100%] w-[100%]">
              {t("RevolutionarySec.cardtitke1")}
              </h3>
              <p className="text-[20px] font-[500] leading-[110%] text-[#fff] text-center">
              {t("RevolutionarySec.cardLastPara")}
              </p>
              <div className="circlerv absolute top-[9%] 2xl:right-[-12%] xl:right-[-12%] lg:right-[-12%] md:right-[-12%] sm:right-[70%] right-[70%]"></div>
            </div>
          </div>
          <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%]  gradientborder">
            <div className="whycardbggr w-[100%] flex justify-center flex-col items-center   px-[2rem] b-[2rem] px-[3rem] 2xl:h-[516px] xl:h-[516px] lg:h-[516px] md:h-[516px] sm:h-[650px] h-[620px] space-y-[1rem] relative overflow-hidden">
              <img className="2xl:w-[32%] xl:w-[32%] lg:w-[32%] md:w-[32%] sm:w-[50%] w-[50%]" src={cardicn2} alt="" />
              <h3 className="text-[48px] font-[700] text-[#FDDB12] max-w-[350px] text-center leading-[100%] w-[100%]">
              {t("RevolutionarySec.cardtitke2")}

              </h3>
              <p className="text-[20px] font-[500] leading-[110%] text-[#fff] text-center">
              {t("RevolutionarySec.cardLastPara2")}

              </p>
              <div className="circlerv absolute 2xl:bottom-[-14%] xl:bottom-[-14%] lg:bottom-[-14%] md:bottom-[-14%] sm:bottom-[40%] bottom-[40%] 2xl:right-[13%] xl:right-[13%] lg:right-[13%] md:right-[13%] sm:right-[-25%] right-[-25%] z-[-1]"></div>
            </div>
          </div>
        </div>
        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[2rem] space-y-[2rem] ">
          <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%] gradientborder">
            <div className="flex justify-center w-[100%] flex-col items-center  whycardbggr px-[2rem]  px-[3rem] 2xl:h-[516px] xl:h-[516px] lg:h-[516px] md:h-[516px] sm:h-[680px] h-[680px] space-y-[1rem] relative overflow-hidden">
              <img className="2xl:w-[20%] xl:w-[20%] lg:w-[20%] md:w-[20%] sm:w-[40%] w-[40%]" src={cardicn3} alt="" />
              <h3 className="text-[48px] font-[700] text-[#FDDB12] max-w-[300px] text-center leading-[100%] w-[100%]">
              {t("RevolutionarySec.cardtitke3")}

              </h3>
              <p className="text-[20px] font-[500] leading-[110%] text-[#fff] text-center">
              {t("RevolutionarySec.cardLastPara3")}

              </p>
              <div className="z-[-1] circlerv absolute 2xl:top-[13%] xl:top-[13%] lg:top-[13%] md:top-[13%] sm:top-[60%] top-[60%] 2xl:left-[-16%] xl:left-[-16%] lg:left-[-16%] md:left-[-16%] sm:left-[-10%] left-[-10%]"></div>
            </div>
          </div>
          <div className=" 2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%] gradientborder">
            <div className="flex w-[100%] justify-center flex-col items-center  whycardbggr px-[2rem]  px-[3rem] 2xl:h-[516px] xl:h-[516px] lg:h-[516px] md:h-[516px] sm:h-[550px] h-[550px] space-y-[1rem] relative overflow-hidden pb-[3rem] ">
              <img className="2xl:w-[20%] xl:w-[20%] lg:w-[20%] md:w-[20%] sm:w-[40%] w-[40%]" src={cardicn4} alt="" />
              <h3 className="text-[48px] font-[700] text-[#FDDB12] max-w-[300px] text-center leading-[100%] w-[100%]">
              {t("RevolutionarySec.cardtitke4")}

              </h3>
              <p className="text-[20px] font-[500] leading-[110%] text-[#fff] text-center">
              {t("RevolutionarySec.cardLastPara4")}

              </p>
              <div className="circlerv absolute 2xl:bottom-[18%] xl:bottom-[18%] lg:bottom-[18%] md:bottom-[18%] sm:bottom-[50%] bottom-[50%] 2xl:right-[-14%] xl:right-[-14%] lg:right-[-14%] md:right-[-14%] sm:right-[-70%] right-[70%] z-[-1]"></div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-[100%] gradientborder ">
            <div className="w-[100%] flex justify-start flex-col items-center  whycardbggr px-[2rem]  pb-[2rem] px-[3rem] pt-[1.5rem] pb-[3rem] space-y-[1rem] relative overflow-hidden">
              <img className="2xl:w-[15%] xl:w-[15%] lg:w-[15%] md:w-[15%] sm:w-[50%] w-[50%]" src={cardicn5} alt="" />
              <h3 className="text-[48px] font-[700] leading-[100%] text-center text-[#FDDB12]">
              {t("RevolutionarySec.cardtitke5")}

              </h3>
              <p className="text-[20px] font-[400] leading-[110%] text-center text-[#fff]">
              {t("RevolutionarySec.cardLastPara5")}
              </p>
              <div className="circlerv z-[-1] absolute 2xl:top-[-16%] xl:top-[-16%] lg:top-[-16%] md:top-[-16%] sm:top-[60%] top-[60%] 2xl:left-[17%] xl:left-[17%] lg:left-[17%] md:left-[17%] sm:left-[80%] left-[80%]"></div>
            </div>
          </div>
        </div>
      </div>
      <p id="tokenomics"></p>
    </div>
  );
}

export default RevolutionarSec;
