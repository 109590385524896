import React from "react";
import img1 from "../assets/images/brands (1).png";
import img2 from "../assets/images/brands (2).png";
import img3 from "../assets/images/brands (3).png";
import img4 from "../assets/images/brands (4).png";
import img5 from "../assets/images/brands (2).png";
import img6 from "../assets/images/brands (5).png";
import img7 from "../assets/images/brands (6).png";
import img8 from "../assets/images/brands (7).png";
import img9 from "../assets/images/brands (8).png";
import img10 from "../assets/images/brands (10).png";
import img11 from "../assets/images/brands (11).png";

const BrandsData = [
  {
    id: 1,
    img: img1,
    para: "4.9/5.0",
  },
  {
    id: 2,
    img: img2,
    para: "4.9/5.0",
  },
  {
    id: 3,
    img: img3,
    para: "4.9/5.0",
  },
  {
    id: 4,
    img: img4,
    para: "4.9/5.0",
  },
  {
    id: 5,
    img: img5,
    para: "4.9/5.0",
  },
  {
    id: 6,
    img: img1,
    para: "4.9/5.0",
  },
  {
    id: 7,
    img: img2,
    para: "4.9/5.0",
  },
  {
    id: 8,
    img: img3,
    para: "4.9/5.0",
  },
  {
    id: 9,
    img: img4,
    para: "4.9/5.0",
  },
  {
    id: 10,
    img: img5,
    para: "4.9/5.0",
  },
  {
    id: 11,
    img: img1,
    para: "4.9/5.0",
  },
  {
    id: 12,
    img: img2,
    para: "4.9/5.0",
  },
  {
    id: 13,
    img: img3,
    para: "4.9/5.0",
  },
  {
    id: 14,
    img: img4,
    para: "4.9/5.0",
  },
  {
    id: 15,
    img: img5,
    para: "4.9/5.0",
  },
  {
    id: 16,
    img: img1,
    para: "4.9/5.0",
  },
  {
    id: 17,
    img: img2,
    para: "4.9/5.0",
  },
  {
    id: 18,
    img: img3,
    para: "4.9/5.0",
  },
  {
    id: 19,
    img: img4,
    para: "4.9/5.0",
  },
  {
    id: 20,
    img: img5,
    para: "4.9/5.0",
  },
  {
    id: 21,
    img: img1,
    para: "4.9/5.0",
  },
  {
    id: 22,
    img: img2,
    para: "4.9/5.0",
  },
  {
    id: 23,
    img: img3,
    para: "4.9/5.0",
  },
  {
    id: 24,
    img: img4,
    para: "4.9/5.0",
  },
  {
    id: 25,
    img: img5,
    para: "4.9/5.0",
  },
 
 
 
];
const BrandsData2 = [
    {
      id: 1,
      img: img6,
      para: "4.9/5.0",
    },
    {
      id: 2,
      img: img7,
      para: "4.9/5.0",
    },
    {
      id: 3,
      img: img8,
      para: "4.9/5.0",
    },
    {
      id: 4,
      img: img9,
      para: "4.9/5.0",
    },
    {
      id: 5,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 6,
      img: img6,
      para: "4.9/5.0",
    },
    {
      id: 7,
      img: img7,
      para: "4.9/5.0",
    },
    {
      id: 8,
      img: img8,
      para: "4.9/5.0",
    },
    {
      id: 9,
      img: img9,
      para: "4.9/5.0",
    },
    {
      id: 10,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 11,
      img: img6,
      para: "4.9/5.0",
    },
    {
      id: 12,
      img: img7,
      para: "4.9/5.0",
    },
    {
      id: 13,
      img: img8,
      para: "4.9/5.0",
    },
    {
      id: 14,
      img: img9,
      para: "4.9/5.0",
    },
    {
      id: 15,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 16,
      img: img6,
      para: "4.9/5.0",
    },
    {
      id: 17,
      img: img7,
      para: "4.9/5.0",
    },
    {
      id: 18,
      img: img8,
      para: "4.9/5.0",
    },
    {
      id: 19,
      img: img9,
      para: "4.9/5.0",
    },
    {
      id: 20,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 21,
      img: img6,
      para: "4.9/5.0",
    },
    {
      id: 22,
      img: img7,
      para: "4.9/5.0",
    },
    {
      id: 23,
      img: img8,
      para: "4.9/5.0",
    },
    {
      id: 24,
      img: img9,
      para: "4.9/5.0",
    },
    {
      id: 25,
      img: img10,
      para: "4.9/5.0",
    },
   
   
   
  ];
  const BrandsData3 = [
    {
      id: 1,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 2,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 3,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 4,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 5,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 6,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 7,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 8,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 9,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 10,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 11,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 12,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 13,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 14,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 15,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 16,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 17,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 18,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 19,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 20,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 21,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 22,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 23,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 24,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 25,
      img: img10,
      para: "4.9/5.0",
    },
   
   
   
  ];
  const BrandsDatamob = [
    {
      id: 1,
      img: img1,
      para: "4.9/5.0",
    },
    {
      id: 2,
      img: img2,
      para: "4.9/5.0",
    },
    {
      id: 3,
      img: img3,
      para: "4.9/5.0",
    },
    {
      id: 4,
      img: img4,
      para: "4.9/5.0",
    },
    {
      id: 5,
      img: img5,
      para: "4.9/5.0",
    },
    {
      id: 6,
      img: img6,
      para: "4.9/5.0",
    },
    {
      id: 7,
      img: img7,
      para: "4.9/5.0",
    },
    {
      id: 8,
      img: img8,
      para: "4.9/5.0",
    },
    {
      id: 9,
      img: img9,
      para: "4.9/5.0",
    },
    {
      id: 10,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 11,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 12,
      img: img1,
      para: "4.9/5.0",
    },
    {
      id: 13,
      img: img2,
      para: "4.9/5.0",
    },
    {
      id: 14,
      img: img3,
      para: "4.9/5.0",
    },
    {
      id: 15,
      img: img4,
      para: "4.9/5.0",
    },
    {
      id: 16,
      img: img5,
      para: "4.9/5.0",
    },
    {
      id: 17,
      img: img6,
      para: "4.9/5.0",
    },
    {
      id: 18,
      img: img7,
      para: "4.9/5.0",
    },
    {
      id: 19,
      img: img8,
      para: "4.9/5.0",
    },
    {
      id: 20,
      img: img9,
      para: "4.9/5.0",
    },
    {
      id: 21,
      img: img10,
      para: "4.9/5.0",
    },
    {
      id: 22,
      img: img11,
      para: "4.9/5.0",
    },
    {
      id: 23,
      img: img1,
      para: "4.9/5.0",
    },
    {
      id: 24,
      img: img2,
      para: "4.9/5.0",
    },
    {
      id: 25,
      img: img3,
      para: "4.9/5.0",
    },
   
   
   
  ];

function BrandsSec() {
  return (
    <div className="relative w-[100%] 2xl:bg-[transparent] 2xl:mt-[0rem] xl:mt-[0rem] lg:mt-[0rem] md:mt-[0rem] sm:mt-[1rem] mt-[1rem] xl:bg-[transparent] 2xl:py-[0px] xl:py-[0px] lg:py-[0px] md:py-[0px] sm:py-[20px] py-[22px] lg:bg-[transparent] md:bg-[transparent] sm:bg-[#202020] bg-[#202020] ">
        <h3 className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block text-[#FDDB12] text-[30px] font-[700] text-center">Featured In</h3>
      <div className=" 2xl:flex xl:flex lg:flex md:flex  sm:block block justify-center 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[10px] space-y-[10px] items-center 2xl:py-[25px] xl:py-[25px] lg:py-[25px] md:py-[25px] sm:py-[0px] py-[0px]">
        <div className="carousel-wrapper py-[2rem] space-y-[2rem]  2xl:block xl:block lg:block md:block sm:hidden hidden">
          <div className="carousel flex 2xl:space-x-[3rem] xl:space-x-[3rem] lg:space-x-[3rem] md:space-x-[3rem] sm:space-x-[1.5rem] space-x-[1.5rem]">
            {BrandsData.map((item, index) => (
              <>
                <div key={index} className="flex items-center w-[180px]   carousel-item ">
                  <img className="" src={item.img} alt="" />
                </div>
              </>
            ))}
            {BrandsData.map((item, index) => (
              <>
                <div key={index} className="flex items-center w-[180px]  carousel-item ">
                  <img className="" src={item.img} alt="" />
                </div>
              </>
            ))}
          </div>
          <div className="carousel flex 2xl:space-x-[3rem] xl:space-x-[3rem] lg:space-x-[3rem] md:space-x-[3rem] sm:space-x-[1.5rem] space-x-[1.5rem]">
            {BrandsData2.map((item, index) => (
              <>
                <div key={index} className="flex items-center w-[180px]   carousel-item ">
                  <img className="" src={item.img} alt="" />
                </div>
              </>
            ))}
            {BrandsData2.map((item, index) => (
              <>
                <div key={index} className="flex items-center w-[180px]  carousel-item ">
                  <img className="" src={item.img} alt="" />
                </div>
              </>
            ))}
          </div>
          <div id="whatisdoge" className="carousel flex 2xl:space-x-[3rem] xl:space-x-[3rem] lg:space-x-[3rem] md:space-x-[3rem] sm:space-x-[1.5rem] space-x-[1.5rem]">
            {BrandsData3.map((item, index) => (
              <>
                <div key={index} className="flex items-center w-[180px]   carousel-item ">
                  <img className="" src={item.img} alt="" />
                </div>
              </>
            ))}
            {BrandsData3.map((item, index) => (
              <>
                <div key={index} className="flex items-center w-[180px]  carousel-item ">
                  <img className="" src={item.img} alt="" />
                </div>
              </>
            ))}
          </div>
        </div>
         <div className="carousel-wrapper py-[0rem] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block">
         <div id="whatisdogesec" className="carousel flex 2xl:space-x-[3rem] xl:space-x-[3rem] lg:space-x-[3rem] md:space-x-[3rem] sm:space-x-[1.5rem] space-x-[1.5rem]">
            {BrandsDatamob.map((item, index) => (
              <>
                <div key={index} className="flex items-center w-[180px]   carousel-item ">
                  <img className="" src={item.img} alt="" />
                </div>
              </>
            ))}
            {BrandsDatamob.map((item, index) => (
              <>
                <div key={index} className="flex items-center w-[180px]  carousel-item ">
                  <img className="" src={item.img} alt="" />
                </div>
              </>
            ))}
          </div>
         </div>
      </div>
    </div>
  );
}

export default BrandsSec;
