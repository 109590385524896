import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const faqsData = [
  {
    question: "SomeQueSec.qust1",
    answer: "SomeQueSec.answ1",
  },
  {
    question: "SomeQueSec.qust2",
    answer:
      "SomeQueSec.answ2",
  },
  {
    question: "SomeQueSec.qust3",
    answer: "SomeQueSec.answ3",
  },
  {
    question: "SomeQueSec.qust4",
    answer: "SomeQueSec.answ4",
  },

];

function SomeQuestions() {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className=" max-w-[1200px] space-y-[3rem] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[94%] w-[94%] m-auto  pb-[4rem]">
      <div className="whatbggrsec rounded-[37px] 2xl:px-[3rem] xl:px-[3rem] lg:px-[3rem] md:px-[3rem] sm:px-[2rem] px-[2rem] pt-[3rem] 2xl:pb-[3rem] xl:pb-[3rem] lg:pb-[3rem] md:pb-[3rem] sm:pb-[3rem] pb-[3rem] space-y-[2rem] ">
        <div>
          <h3 className="2xl:block xl:block lg:block md:block sm:hidden hidden text-[26px] text-[#FBD914] font-[600] text-center">
         {t("SomeQueSec.title")}
          </h3>
          <h3 className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block text-[26px] text-[#FBD914] font-[600] text-center">
         {t("SomeQueSec.title1")} <br /> {t("SomeQueSec.title2")}
          </h3>
        </div>
        <div className="space-y-[1rem]">
          {faqsData.map((faq, index) => (
            <div
              key={index}
              className=" border-b-[2px] border-[#505050] p-[1rem]"
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleClick(index)}
              >
                <h4 className="2xl:text-[24px] xl:text-[24px] lg:text-[24px] md:text-[24px] sm:text-[18px] text-[18px]  font-[400] text-[#fff]">
                  {t(faq.question)}
                </h4>
                <span className="text-[20px] font-[600] text-[#FBD914] border border-[#FBD914] rounded-[50px] w-[30px] flex justify-center items-center h-[30px]">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </div>
              {activeIndex === index && (
                 <p
                 className="faqp text-[#fff] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] font-[400] text-[#000]"
                 dangerouslySetInnerHTML={{ __html: t(faq.answer) }}
               ></p>
              )}
            </div>
          ))}
        </div>
        <p  id="howtobuy"></p>
        <p id="howtobuysec"></p>
      </div>
    </div>
  );
}

export default SomeQuestions;
