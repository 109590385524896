import React, { useContext, useEffect, useRef, useState } from "react"
import { UserContext } from "../../../presale-gg/context/UserContext"
import { formatLargeNumber, parseNum, capitalize, formatNumber } from "../../../presale-gg/utils"
import logo from "../../../assets/Logo/logo.png"
import copyIcon from "../../../assets/svgs/copy.svg"
import toast from "react-hot-toast"

import downChevron from "../../../assets/svgs/chevron-down.svg"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

/**
 * @callback onChange
 * @param {boolean} value
 * @returns {unknown}
 */

/**
 * @param {object} props
 * @param {boolean} props.value
 * @param {onChange} props.onChange
 */
const Toggle = (props) => {
	return (
		<button aria-label="Toggle" className="inline-block py-2" onClick={() => props.onChange(!props.value)}>
			<div className={clsx(
				"h-5 w-12 bg-[#444] rounded-full flex items-center transition-colors",
				{"bg-[#b39c17]": props.value}
			)}>
				<div className={clsx(
					"w-7 h-7 bg-[#fbda19] rounded-full transition-transform",
					{"translate-x-[1.5rem]": props.value},
					{"translate-x-[-0.25rem]": !props.value},
				)} />
			</div>
		</button>
	)
}

const TransactionsTab = () => {
	const userData = useContext(UserContext)
	const { t } = useTranslation()
	const [ showsBonus, setShowsBonus ] = useState(false)

	const loaded = (showsBonus ? userData.bonusTransactions : userData.transactions) !== null
	const transactions = (showsBonus ? userData.bonusTransactions : userData.transactions) ?? []

	return (
		<div className="flex flex-col space-y-[15px] text-[#fff] flex-1 pt-4">
			<div className="flex max-xs:flex-col items-center justify-between gap-2 px-4 max-xs:px-0">
				<h4 className="text-[28px] text-center font-[700] text-[#fff] tracking-[-1.65px] break-words">
					{t("Transactions.card-title")}
				</h4>
				<div className="flex  items-center gap-2">
					<Toggle
						value={showsBonus}
						onChange={(val) => setShowsBonus(val)}
					/>
					<p className="text-sm text-center w-12">{t("Transactions.toggle-bonus")}</p>
				</div>
			</div>
			<div className="flex flex-col gap-2 max-h-[31rem] overflow-auto flex-1 pr-2 -mr-2">
				{transactions.map((trx) => (
					<TransactionItem key={trx.id} trx={trx} bonus={showsBonus} />
				))}
				{transactions.length === 0 && loaded && (
					<p className="text-lg text-center">{t("Transactions.no-transactions")}</p>
				)}
			</div>
		</div>
	)
}

export default TransactionsTab

const statusColMap = {
	"pending": ["#ffa00033", "#ffa000"],
	"processing": ["#1c8cfc33", "#1c8cfc"],
	"completed": ["#20f79b33", "#20f79b"],
	"failed": ["#de6a6a33", "#de6a6a"],
	"expired": ["#de6a6a33", "#de6a6a"],
	"refunded": ["#de6a6a33", "#de6a6a"]
}

/**
 * @param {object} props
 * @param {import("../../../presale-gg/types/api.types").API.PaymentTransaction | import("../../../presale-gg/types/api.types").API.BonusTransaction} props.trx
 * @param {boolean} props.bonus
*/
export const TransactionItem = (props) => {
	const [ expanded, setExpanded ] = useState(false)
	const [ divHeight, setDivHeight ] = useState(0)
	const [ buttonHeight, setButtonHeight ] = useState(52)
	const divRef = useRef(null)
	const buttonRef = useRef(null)
	const { t } = useTranslation()

	useEffect(() => {
		const divEl = divRef.current
		const buttonEl = buttonRef.current
		if (!divEl || !buttonEl) return
		const getSize = () => {
			const { height: divHeight } = divEl.getBoundingClientRect()
			setDivHeight(divHeight)

			const { height: buttonHeight } = buttonEl.getBoundingClientRect()
			setButtonHeight(buttonHeight)
		}
		getSize()
		window.addEventListener("resize", getSize)
		return () => window.removeEventListener("resize", getSize)
	}, [])

	const getIdentifierLabel = () => {
		if (!props.trx.type) return null
		if (props.trx.type === "rank") return "Transactions.type-rank"
		if (props.trx.type === "bonus_code") return "Transactions.type-bonus_code"
		if (props.trx.type === "referral") return "Transactions.label-referral-code"
		if (props.trx.type === "referrer") return "Transactions.label-referral-address"
		return "Transactions.label-identifier"
	}
	const identifierLabel = getIdentifierLabel()

	return (
		<div
			className="flex flex-col flex-shrink-0 bg-[#484848] rounded-lg overflow-hidden transition-[max-height]"
			style={{
				"maxHeight": expanded ? divHeight + buttonHeight : buttonHeight
			}}
		>
			<button
				className="flex items-center justify-between flex-1 px-2 py-1.5 rounded-lg transition-colors hover:bg-[rgba(255,255,255,0.1)] gap-3 text-left"
				onClick={() => setExpanded((expanded) => !expanded)}
				ref={(el) => buttonRef.current = el}
			>
				<img src={logo} alt="Doge Kombat Logo" className="w-10 h-10" />
				{props.trx.payment_usd_amount !== undefined && (
					<p className="text-[#aaa] text-sm whitespace-nowrap w-[4.5rem]">
						-${formatLargeNumber(parseNum(props.trx.payment_usd_amount))}
					</p>
				)}
				{props.trx.tokens_bought !== undefined && (
					<p className="font-[500] text-sm w-[7.25rem]">
						{props.trx.tokens_bought !== null ? `+${formatLargeNumber(parseNum(props.trx.tokens_bought))} DOKO` : t("Transactions.in-progress")}
					</p>
				)}
				{props.trx.bonus_token_amount !== undefined && (
					<p className="font-[500] flex-1 text-center text-sm w-[7.25rem]">
						{props.trx.bonus_token_amount !== null ? `+${formatLargeNumber(parseNum(props.trx.bonus_token_amount))} DOKO` : t("Transactions.in-progress")}
					</p>
				)}
				{props.trx.status !== undefined && (
					<p
						className="flex-shrink-0 text-[12px] px-1.5 py-0.5 rounded-lg font-[500] w-[4.5rem] text-center"
						style={{color: statusColMap[props.trx.status][1], backgroundColor: statusColMap[props.trx.status][0]}}
					>
						{t(`Transactions.status-${props.trx.status.toLowerCase()}`)}
					</p>
				)}
				{props.trx.type !== undefined && props.bonus && (
					<p
						className="flex-shrink-0 text-[12px] px-1.5 py-0.5 rounded-lg font-[500] w-[5rem] text-center"
						style={{color: statusColMap["completed"][1], backgroundColor: statusColMap["completed"][0]}}
					>
						{t(`Transactions.type-${props.trx.type.toLowerCase()}`)}
					</p>
				)}
				<img
					src={downChevron}
					className="w-4 h-4 transition-transform"	
					style={{
						"transform": expanded ? "rotate(180deg)" : "rotate(0deg)"
					}}
				/>
			</button>
			<div
				className="flex flex-shrink-0 flex-wrap gap-2 px-2 py-1.5 justify-between"
				ref={(el) => divRef.current = el}
			>
				<div className="flex flex-col">
					<p className="text-xs font-bold text-[#aaa]">ID</p>
					<p className="flex items-center text-sm min-h-[1.875em] gap-1">
						{props.trx.id}
						<CopyButton value={props.trx.id} />
					</p>
				</div>
				{props.trx.stage_name !== undefined && (
					<div className="flex flex-col">
						<p className="text-xs font-bold text-[#aaa]">{t("Transactions.label-stage")}</p>
						<p className="flex items-center text-sm min-h-[1.875em]">{props.trx.stage_name ?? t("Transactions.in-progress")}</p>
					</div>
				)}
				{props.trx.payment_token_amount !== undefined && (
					<div className="flex flex-col">
						<p className="text-xs font-bold text-[#aaa]">{t("Transactions.label-payment")}</p>
						<p className="flex items-center text-sm min-h-[1.875em]">{formatNumber(parseNum(props.trx.payment_token_amount), 4, 4)} {props.trx.payment_token_name}</p>
					</div>
				)}
				{props.trx.transaction_hash !== undefined && (
					<div className="flex flex-col">
						<p className="text-xs font-bold text-[#aaa]">{t("Transactions.label-transaction-hash")}</p>
						<p className="flex items-center text-sm min-h-[1.875em] gap-1 break-all">
							{props.trx.transaction_hash ?? t("Transactions.in-progress")}
							{props.trx.transaction_hash && (
								<CopyButton value={props.trx.transaction_hash} />
							)}
						</p>
					</div>
				)}
				{props.trx.transaction_id !== undefined && (
					<div className="flex flex-col">
						<p className="text-xs font-bold text-[#aaa]">{t("Transactions.label-transaction-id")}</p>
						<p className="flex items-center text-sm min-h-[1.875em] gap-1 break-all">
							{props.trx.transaction_id ?? t("Transactions.in-progress")}
							{props.trx.transaction_id && (
								<CopyButton value={props.trx.transaction_id} />
							)}
						</p>
					</div>
				)}
				{props.trx.identifier !== undefined && (
					<div className="flex flex-col">
						<p className="text-xs font-bold text-[#aaa]">{t(identifierLabel)}</p>
						<p className="flex items-center text-sm min-h-[1.875em] break-all">
							{props.trx.identifier}
							{props.trx.identifier && (
								<CopyButton value={props.trx.identifier} />
							)}
						</p>
					</div>
				)}
				{props.trx.wallet_address !== undefined && (
					<div className="flex flex-col">
						<p className="text-xs font-bold text-[#aaa]">{t("Transactions.label-wallet-address")}</p>
						<p className="flex items-center text-sm min-h-[1.875em] gap-1 break-all">
							{props.trx.wallet_address ?? t("Transactions.in-progress")}
							{props.trx.wallet_address && (
								<CopyButton value={props.trx.wallet_address} />
							)}
						</p>
					</div>
				)}
			</div>
		</div>
	)
}

/**
 * @param {object} props
 * @param {string} props.value
*/
const CopyButton = (props) => {
	const { t } = useTranslation()
	const copy = () => {
		try {
			navigator.clipboard.writeText(props.value)
			toast(t("Messages.copied"))
		} catch (err) {
			toast.error(t("Messages.copy-error"))
		}
	}

	return (
		<button
			className="flex-shrink-0 p-2 w-7 h-7 hover:bg-[rgba(255,255,255,0.15)] transition-colors rounded-full"
			onClick={copy}
		>
			<img src={copyIcon} alt="Copy" />
		</button>
	)
}