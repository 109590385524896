import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


import buyimg from "../assets/images/buyimg.png"
import buyimgmob from "../assets/svgs/buyimgmob.svg"


const HowToButSec = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getAnchorHref = () => {
    return screenSize >= 768 ? "#hero" : "#herosec";
  };

    return (
      <div className="relative overflow-hidden z-[1] max-w-[1440px] mx-auto">
          <div className="2xl:w-[86%]  xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[93%] w-[93%] max-w-[1100px] mx-auto 2xl:pb-[5rem] xl:pb-[5rem] lg:pb-[5rem] md:pb-[5rem] sm:pb-[4rem] pb-[4rem] space-y-[3rem] ">
        <div className="space-y-[20px]">
            <h3 className=" 2xl:leading-[120%] xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[110%] leading-[110%] text-center text-[40px] font-[700] text-[#FBD914]">{t("BuySection.title")}</h3>
            <div className="flex justify-center m-auto max-w-[430px] space-x-2">
               <a href={getAnchorHref()}> <button className="w-[200px] bg-[#FBD914] rounded-full  h-[56px] hover:text-[#fff] border border-[#FBD914] text-[18px] font-[700] hover:bg-[transparent]  m-auto">{t("BuySection.btn1")}</button></a>
               <a href="https://t.me/dogekombatgame_chat" target="_blank"  rel="noreferrer">
               <button className="2xl:block xl:block lg:block md:block sm:hidden hidden w-[200px] bg-[#313131] rounded-full text-[18px] h-[56px] text-[#fff] hover:border hover:border-[#FBD914] font-[700] hover:bg-[transparent]  m-auto">{t("BuySection.btn2")}</button>
               </a>
               <a href="https://t.me/dogekombatgame_chat" target="_blank"  rel="noreferrer">
               <button className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block w-[150px] bg-[#313131] rounded-full  h-[56px] text-[#fff] hover:border hover:border-[#FBD914] font-[700] hover:bg-[transparent]  m-auto">{t("BuySection.btn3")}</button>
               </a>
            </div>
        </div>
        <div className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden justify-center ">
            <img src={buyimg} className="w-[84%] ml-[3rem]" alt="" />
        </div>
        <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:flex flex justify-center ">
            <img src={buyimgmob} className="w-[100%] " alt="" />
        </div>
        <div className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden justify-between items-center 2xl:space-y-[0rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[1rem] space-y-[1rem]  ">
            <div className="max-w-[330px] space-y-[10px] w-[100%] m-auto px-4"> 
                <h4 className="text-[18px] font-[700] text-center text-[#fff]">{t("BuySection.cardTitle1")}</h4>
                <h3 className="text-[#FBD914] text-center text-[26px] font-[900]">{t("BuySection.cardSubtitle1")}</h3>
                <p className="text-[16px] font-[400] text-center text-[#fff]">{t("BuySection.cardPara1")}</p>
            </div>
            <div className="max-w-[330px] space-y-[10px] w-[100%] m-auto px-3">
                <h4 className="text-[18px] font-[700] text-center text-[#fff]">{t("BuySection.cardTitle2")}</h4>
                <h3 className="text-[#FBD914] text-center text-[26px] font-[900]">{t("BuySection.cardSubtitle2")}</h3>
                <p className="text-[16px] font-[400] text-center text-[#fff]">{t("BuySection.cardPara2")}</p>
            </div>
            <div className="max-w-[330px] space-y-[10px] w-[100%] m-auto px-4">
                <h4 className="text-[18px] font-[700] text-center text-[#fff]">{t("BuySection.cardTitle3")}</h4>
                <h3 className="text-[#FBD914] text-center text-[26px] font-[900]">{t("BuySection.cardSubtitle3")}</h3>
                <p className="text-[16px] font-[400] text-center text-[#fff]">{t("BuySection.cardPara3")}</p>
            </div>
        </div>
        <div className="2xl:hidden xl:hidden lg:hidden md:hidden stepbg sm:block block p-[2.5rem] justify-between items-center 2xl:space-y-[0rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[1rem] space-y-[2rem]  ">
            <div className="max-w-[330px] space-y-[10px] w-[100%] !mx-auto"> 
                <h4 className="text-[18px] font-[700] text-center text-[#fff] uppercase">{t("BuySection.cardTitle1")}</h4>
                <h3 className="text-[#FBD914] text-center text-[26px] font-[900]">{t("BuySection.cardSubtitle1")}</h3>
                <p className="text-[16px] font-[400] text-center text-[#fff]">{t("BuySection.cardPara1")}</p>
            </div>
            <div className="buyline "></div>
            <div className="max-w-[330px] space-y-[10px] w-[100%] !mx-auto">
                <h4 className="text-[18px] font-[700] text-center text-[#fff] uppercase">{t("BuySection.cardTitle2")}</h4>
                <h3 className="text-[#FBD914] text-center text-[26px] font-[900]">{t("BuySection.cardSubtitle2")}</h3>
                <p className="text-[16px] font-[400] text-center text-[#fff]">{t("BuySection.cardPara2")}</p>
            </div>
            <div className="buyline "></div>
            <div className="max-w-[330px] space-y-[10px] w-[100%] !mx-auto">
                <h4 className="text-[18px] font-[700] text-center text-[#fff] uppercase">{t("BuySection.cardTitle3")}</h4>
                <h3 className="text-[#FBD914] text-center text-[26px] font-[900]">{t("BuySection.cardSubtitle3")}</h3>
                <p className="text-[16px] font-[400] text-center text-[#fff]">{t("BuySection.cardPara3")}</p>
            </div>
        </div>
        </div>
        <div className="circlebuy 2xl:block xl:block lg:block md:block sm:hidden hidden absolute bottom-[-51%] left-[-25%] z-[-1]">
        </div>
      </div>
    )
}
export default HowToButSec;