import React, { useState, useEffect } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { useTranslation } from "react-i18next";

import Tick1 from "../assets/svgs/check.svg";
import cross from "../assets/svgs/check.svg";
import img1 from "../assets/images/rwdicn (1).png";
import img2 from "../assets/images/rwdicn (6).png";
import img3 from "../assets/images/rwdicn (7).png";
import img4 from "../assets/images/rwdicn (5).png";
import img5 from "../assets/images/rwdicn (4).png";
import img6 from "../assets/images/rwdicn (3).png";
import img7 from "../assets/images/rwdicn (2).png";

import RewardCard from "../compunents/RewardCard";

const RewardSection = () => {
  const { t } = useTranslation();

  const [rewardAmount, setRewardAmount] = useState(1000);
  const [amountRange, setAmountRange] = useState(1000);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getAnchorHref = () => {
    return screenSize >= 768 ? "#hero" : "#herosec";
  };

  const listData = [
    {
      id: 1,
      packageType: "RewardSection.packageType1",
      spendPrice: amountRange,
      spendImage: img1,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText11",
          tooltip:"RewardSection.tootltip1para1",
          tooltiptitle:"RewardSection.tootltip1title1",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText12",
          tooltip:"RewardSection.tootltip1para2",
          tooltiptitle:"RewardSection.tootltip1title2",
        },
        {
          id: 3,
          icon: cross,
          text: "RewardSection.packageText13",
          tooltip:"RewardSection.tootltip1para3",
          tooltiptitle:"RewardSection.tootltip1title3",
        },
        {
          id: 4,
          icon: cross,
          text: "RewardSection.packageText14",
          tooltip:"RewardSection.tootltip1para4",
          tooltiptitle:"RewardSection.tootltip1title4",
        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText15",
          tooltip:"RewardSection.tootltip1para5",
          tooltiptitle:"RewardSection.tootltip1title5",
        },
        
      ],
    },
    {
      id: 2,
      packageType: "RewardSection.packageType2",
      spendPrice: amountRange,
      spendImage: img2,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText21",
          tooltip:"RewardSection.tootltip1para1",
          tooltiptitle:"RewardSection.tootltip1title21",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText22",
          tooltiptitle:"RewardSection.tootltip1title2",
          tooltip:"RewardSection.tootltip1para2",
        },
        {
          id: 3,
          icon: cross,
          text: "RewardSection.packageText23",
          tooltiptitle:"RewardSection.tootltip2title3",
          tooltip:"RewardSection.tootltip2para3",
        },
        {
          id: 4,
          icon: cross,
          text: "RewardSection.packageText24",
          tooltiptitle:"RewardSection.tootltip1title4",
          tooltip:"RewardSection.tootltip1para4",
        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText25",
          tooltiptitle:"RewardSection.tootltip1title5",
          tooltip:"RewardSection.tootltip1para5",
        },
        
      ],
    },
    {
      id: 3,
      packageType: "RewardSection.packageType3",
      spendPrice: amountRange,
      spendImage: img3,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText31",
          tooltip:"RewardSection.tootltip1para1",
          tooltiptitle:"RewardSection.tootltip1title31",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText32",
          tooltiptitle:"RewardSection.tootltip1title2",
          tooltip:"RewardSection.tootltip1para2",
        },
        {
          id: 3,
          icon: cross,
          text: "RewardSection.packageText33",
          tooltiptitle:"RewardSection.tootltip3title3",
          tooltip:"RewardSection.tootltip3para3",
        },
        {
          id: 4,
          icon: cross,
          text: "RewardSection.packageText34",
          tooltiptitle:"RewardSection.tootltip1title4",
          tooltip:"RewardSection.tootltip1para4",
        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText35",
          tooltiptitle:"RewardSection.tootltip1title5",
          tooltip:"RewardSection.tootltip1para5",
        },
        
      ],
    },
    {
      id: 4,
      packageType: "RewardSection.packageType4",
      spendPrice: amountRange,
      spendImage: img4,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText41",
          tooltip:"RewardSection.tootltip1para1",
          tooltiptitle:"RewardSection.tootltip1title41",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText42",
          tooltiptitle:"RewardSection.tootltip1title2",
          tooltip:"RewardSection.tootltip1para2",
        },
        {
          id: 3,
          icon: cross,
          text: "RewardSection.packageText43",
          tooltiptitle:"RewardSection.tootltip4title3",
          tooltip:"RewardSection.tootltip4para3",
        },
        {
          id: 4,
          icon: cross,
          text: "RewardSection.packageText44",
          tooltiptitle:"RewardSection.tootltip1title4",
          tooltip:"RewardSection.tootltip1para4",
        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText45",
          tooltiptitle:"RewardSection.tootltip1title5",
          tooltip:"RewardSection.tootltip1para5",
        },
        
      ],
    },
    {
      id: 5,
      packageType: "RewardSection.packageType5",
      spendPrice: amountRange,
      spendImage: img5,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText51",
          tooltip:"RewardSection.tootltip1para1",
          tooltiptitle:"RewardSection.tootltip1title51",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText52",
          tooltiptitle:"RewardSection.tootltip1title2",
          tooltip:"RewardSection.tootltip1para2",
        },
        {
          id: 3,
          icon: cross,
          text: "RewardSection.packageText53",
          tooltiptitle:"RewardSection.tootltip5title3",
          tooltip:"RewardSection.tootltip5para3",
        },
        {
          id: 4,
          icon: cross,
          text: "RewardSection.packageText54",
          tooltiptitle:"RewardSection.tootltip1title4",
          tooltip:"RewardSection.tootltip1para4",
        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText55",
          tooltiptitle:"RewardSection.tootltip1title5",
          tooltip:"RewardSection.tootltip1para5",
        },
        
      ],
    },
    {
      id: 6,
      packageType: "RewardSection.packageType6",
      spendPrice: amountRange,
      spendImage: img6,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText61",
          tooltip:"RewardSection.tootltip1para1",
          tooltiptitle:"RewardSection.tootltip1title61",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText62",
          tooltiptitle:"RewardSection.tootltip1title2",
          tooltip:"RewardSection.tootltip1para2",
        },
        {
          id: 3,
          icon: cross,
          text: "RewardSection.packageText63",
          tooltiptitle:"RewardSection.tootltip6title3",
          tooltip:"RewardSection.tootltip6para3",
        },
        {
          id: 4,
          icon: cross,
          text: "RewardSection.packageText64",
          tooltiptitle:"RewardSection.tootltip1title4",
          tooltip:"RewardSection.tootltip1para4",
        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText65",
          tooltiptitle:"RewardSection.tootltip1title5",
          tooltip:"RewardSection.tootltip1para5",
        },
      ],
    },
    {
      id: 7,
      packageType: "RewardSection.packageType7",
      spendPrice: amountRange,
      spendImage: img7,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText71",
          tooltip:"RewardSection.tootltip1para1",
          tooltiptitle:"RewardSection.tootltip1title71",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText72",
          tooltiptitle:"RewardSection.tootltip1title2",
          tooltip:"RewardSection.tootltip1para2",
        },
        {
          id: 3,
          icon: cross,
          text: "RewardSection.packageText73",
          tooltiptitle:"RewardSection.tootltip7title3",
          tooltip:"RewardSection.tootltip7para3",
        },
        {
          id: 4,
          icon: cross,
          text: "RewardSection.packageText74",
          tooltiptitle:"RewardSection.tootltip1title4",
          tooltip:"RewardSection.tootltip1para4",
        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText75",
          tooltiptitle:"RewardSection.tootltip1title5",
          tooltip:"RewardSection.tootltip1para5",
        },
        
      ],
    },
  ];
  return (
    <div className="rwdbggrmob 2xl:px-0 xl:px-0 lg:px-0 md:px-0 sm:px-x px-4 2xl:py-0 xl:py-0 lg:py-0 md:py-0 sm:py-6 py-6 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%]  sm:w-[96%] w-[96%] max-w-[1200px] mx-auto mb-[4rem] ">
      <div className=" 2xl:flex xl:flex lg:flex md:flex sm:block block justify-between items-center ">
        <div className=" 2xl:w-[48%] xl:w-[48%] lg:w-[48%] md:w-[100%] sm:w-[100%] w-[100%] 2xl:space-y-10 xl:space-y-10 lg:space-y-10 md:space-y-10 sm:space-y-4 space-y-4 pr-2  ">
          <div className="">
            <h3 className="2xl:block xl:block lg:block md:block sm:hidden hidden 2xl:text-[40px] xl:text-[40px] lg:text-[40px] md:text-[40px] sm:text-[46px] text-[46px] 2xl:font-[900] xl:font-[900] lg:font-[900] md:font-[900] sm:font-[700] font-[700] 2xl:leading-[120%]  xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[100%] leading-[100%] 2xl:tracking-[0px] xl:tracking-[0px] lg:tracking-[0px] md:tracking-[0px] sm:tracking-[-1.65px] tracking-[-1.65px] pb-6 text-[#FBD914] 2xl:uppercase xl:uppercase lg:uppercase md:uppercase sm:capitalize capitalize">
              {t("RewardSection.title")}
            </h3>
            <h3 className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block 2xl:text-[40px] xl:text-[40px] lg:text-[40px] md:text-[40px] sm:text-[46px] text-[46px] 2xl:font-[900] xl:font-[900] lg:font-[900] md:font-[900] sm:font-[700] font-[700] 2xl:leading-[120%]  xl:leading-[120%] lg:leading-[120%] md:leading-[120%] sm:leading-[100%] leading-[100%] 2xl:tracking-[0px] xl:tracking-[0px] lg:tracking-[0px] md:tracking-[0px] sm:tracking-[-1.65px] tracking-[-1.65px] pb-6 text-[#FBD914] 2xl:uppercase xl:uppercase lg:uppercase md:uppercase sm:capitalize capitalize text-center">
              {t("RewardSection.titlemob1")} <br />{" "}
              {t("RewardSection.titlemob2")} <br />{" "}
              {t("RewardSection.titlemob3")} <br /> $1,000
            </h3>
            <p className="text-[20px] xs:text-[20px] sm:text-[20px] leading-[120%] font-[400] font-Poopins  text-white text-center md:text-left">
              {t("RewardSection.para")}
            </p>
          </div>
          <div>
            <div className="space-y-[8px] bg-[#2A2A2A] rounded-[37px] px-6 pt-2 pb-4">
              <div className="flex justify-between items-center 2xl:pb-0 xl:pb-0 lg:pb-0 md:pb-0 sm:pb-1 pb-1">
                <h5 className="2xl:w-[70%] xl:w-[70%] lg:w-[70%] md:w-[auto] sm:w-[150px] w-[120px] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[14px] text-[12px] font-[400] font-Poopins 2xl:leading-[25px] xl:leading-[25px] lg:leading-[25px] md:leading-[25px] sm:leading-[15px] leading-[14px] text-white">
                  {t("RewardSection.spendText")}
                </h5>
                <h3 className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[18px] text-[18px]  text-white  font-[600] font-Poppins">
                  $ {Number(rewardAmount).toLocaleString("en-US")}
                </h3>
              </div>
              <div></div>

              <RangeSlider
                className="single-thumb"
                min={1000}
                max={250000}
                rangeSlideDisabled={true}
                onInput={(e) => {
                  let amount = e[1];
                  console.log(amount, "yes");

                  setAmountRange(amount);
                  setRewardAmount(amount);
                }}
                thumbsDisabled={[true, false]}
              />
            </div>
          </div>
        </div>
        <div className=" 2xl:w-[50%] rwdbggr xl:w-[50%] lg:w-[50%] md:w-[100%] sm:w-[100%] w-[100%] rewardCard px-10 px-3  2xl:pt-6  xl:pt-6 lg:pt-6  md:pt-6 sm:pt-0  pt-0 2xl:pb-6 xl:pb-6 lg:pb-6 md:pb-6 sm:pb-0 pb-0  ">
          {listData.map((item, id) =>
            amountRange >= 1000 && amountRange <= 2499 && item.id === 1 ? (
              <RewardCard item={item} key={id} amountRange={amountRange} />
            ) : amountRange >= 2500 && amountRange <= 4999 && item.id === 2 ? (
              <RewardCard item={item} key={id} />
            ) : amountRange >= 5000 && amountRange <= 9999 && item.id === 3 ? (
              <RewardCard item={item} key={id} />
            ) : amountRange >= 10000 &&
              amountRange <= 24999 &&
              item.id === 4 ? (
              <RewardCard item={item} key={id} />
            ) : amountRange >= 25000 &&
              amountRange <= 99999 &&
              item.id === 5 ? (
              <RewardCard item={item} key={id} />
            ) : amountRange >= 100000 &&
              amountRange <= 249999 &&
              item.id === 6 ? (
              <RewardCard item={item} key={id} />
            ) : amountRange >= 249999 &&
            amountRange <= 250000 &&
            item.id === 7 ? (
            <RewardCard item={item} key={id} />
          ) : (
              ""
            )
          )}

          <div className="2xl:hidden xl:hidden space-y-[15px] py-[15px] lg:hidden md:hidden sm:block block">
            <p className=" text-[16px] xs:pt-2 text-center font-[400] leading-[19px] text-[#fff]">
              {t("RewardSection.membershipmobpara")}
            </p>
            <div
             
              className=" flex 2xl:justify-start xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center"
            >
              <a href={getAnchorHref()} className="w-[200px]">
                <button className="w-[200px] bg-[#FBD914] rounded-full   h-[46px] hover:text-[#fff] border border-[#FBD914] text-[18px] font-[700] hover:bg-[transparent]  m-auto ">
                  {t("RewardSection.btnbuy")}
                </button>
              </a>
            </div>
          </div>

          <p
            className="2xl:block xl:block lg:block md:block sm:hidden hidden text-[13px] xs:pt-2 text-center font-[400] font-[Segoe UI] leading-[19px] text-[#fff]"
          >
            {t("RewardSection.membershipmobpara")}
          </p>
        </div>
      </div>
    </div>
  );
};
export default RewardSection;
