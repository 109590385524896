import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const faqsData = [
  {
    question: "Faqs.qust1",
    answer: "Faqs.answ1",
  },
  {
    question: "Faqs.qust2",
    answer:
      "Faqs.answ2",
  },
  {
    question: "Faqs.qust3",
    answer: "Faqs.answ3",
  },
  {
    question: "Faqs.qust4",
    answer: "Faqs.answ4",
  },
  {
    question: "Faqs.qust5",
    answer: "Faqs.answ5",
  },
  {
    question: "Faqs.qust6",
    answer: "Faqs.answ6",
  },
  {
    question: "Faqs.qust7",
    answer: "Faqs.answ7",
  },
  {
    question: "Faqs.qust8",
    answer: "Faqs.answ8",
  },
  {
    question: "Faqs.qust9",
    answer: "Faqs.answ9",
  },
  {
    question: "Faqs.qust10",
    answer: "Faqs.answ10",
  },
];

function Faqs() {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className=" max-w-[1200px] space-y-[3rem] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[100%] w-[100%] m-auto  pb-[4rem]">
      <div className="whatbggrsec rounded-[37px] 2xl:px-[4rem] xl:px-[4rem] lg:px-[4rem] md:px-[4rem] sm:px-[2rem] px-[2rem] pt-[3rem] 2xl:pb-[4rem] xl:pb-[4rem] lg:pb-[4rem] md:pb-[4rem] sm:pb-[3rem] pb-[3rem] space-y-[2rem] ">
        <div>
          <h3 className="text-[40px] text-[#FBD914] font-[700] text-center">
         {t("Faqs.title")}
          </h3>
          <p className="text-[18px] font-[400] text-center text-[#fff]">
          {t("Faqs.para")}
          </p>
        </div>
        <div className="space-y-[1rem]">
          {faqsData.map((faq, index) => (
            <div
              key={index}
              className=" border-b-[2px] border-[#505050] p-[1rem]"
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleClick(index)}
              >
                <h4 className="2xl:text-[24px] xl:text-[24px] lg:text-[24px] md:text-[24px] sm:text-[18px] text-[18px]  font-[400] text-[#fff]">
                  {t(faq.question)}
                </h4>
                <span className="text-[20px] font-[600] text-[#FBD914] border border-[#FBD914] rounded-[50px] w-[30px] flex justify-center items-center h-[30px]">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </div>
              {activeIndex === index && (
                 <p
                 className="faqp text-[#fff] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] font-[400] text-[#000] 2xl:w-[80%] xl:w-[80%] lg:w-[80%] md:w-[80%] ms:w-[80%] w-[90%]"
                 dangerouslySetInnerHTML={{ __html: t(faq.answer) }}
               ></p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faqs;
