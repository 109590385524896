import React, {useState, useEffect, useRef, useContext} from "react";
import Logo2 from "../assets/Logo/logo-new.svg"
import HoverDropDn from "./HoverDropDn";
import i18n from "i18next";
import { useTranslation } from "react-i18next";


import awr from "../assets/images/navdown.png"
import menu from "../assets/svgs/mwnu.svg";
import menu_open from "../assets/svgs/menu-open.svg";
import { truncateAddress } from "../presale-gg/utils";
import { Web3Context } from "../presale-gg/context/Web3Context";

import fg1 from "../assets/svgs/fg (1).svg";
import fg2 from "../assets/svgs/fg (2).svg";
import fg3 from "../assets/svgs/fg (3).svg";
import fg4 from "../assets/svgs/fg (4).svg";
import fg5 from "../assets/svgs/fg (5).svg";
import fg6 from "../assets/svgs/fg (6).svg";
import fg7 from "../assets/svgs/fg (7).svg";
import fg8 from "../assets/svgs/fg (8).svg";
import fg9 from "../assets/svgs/fg (9).svg";
import fg10 from "../assets/svgs/fg (10).svg";
import fg11 from "../assets/svgs/fg (11).svg";
import fg12 from "../assets/svgs/fg (12).svg";
import fg13 from "../assets/svgs/fg (13).svg";
import fg14 from "../assets/svgs/fg (14).svg";

function Navbar() {
  const [langStatus, setLangStatus] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);
  const web3 = useContext(Web3Context)
  const { t } = useTranslation()

  const [selectedLang, setSelectedLang] = useState({
    img: fg13,
    title: "English",
    sub:"EN"
  });

  const locales = {
    en: { img: fg13, sub: "EN", title: "English" },
    gr: { img: fg12, sub: "DE", title: "German" },
    fr: { img: fg11, sub: "FR", title: "French" },
    es: { img: fg10, sub: "ES", title: "Spanish" },
    it: { img: fg9, sub: "IT", title: "Italian" },
    du: { img: fg8, sub: "NL", title: "Dutch" },
    tu: { img: fg7, sub: "TR", title: "Turkey" },
    jp: { img: fg6, sub: "JA", title: "Japan" },
    kr: { img: fg5, sub: "KO", title: "Korean" },
    nr: { img: fg4, sub: "NO", title: "Norwegian" },
    ru: { img: fg3, sub: "RU", title: "Russian" },
    ch: { img: fg2, sub: "ZH", title: "Chinese" },
    pr: { img: fg1, sub: "PT", title: "Portugese" },
    vt: { img: fg14, sub: "VI", title: "Vietnamese" },
  };

  const handleLanguageChange = (locale) => {
    i18n.changeLanguage(locale);

    // Set the selected language and close the dropdown
    setSelectedLang(locales[locale]);
    setLangStatus(false);
  };


  const toggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };
    const handleLangDropdown = () => {
        setLangStatus((prevlangStatus) => !prevlangStatus);
      };
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setLangStatus(false);
        }
        if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const handleMenuItemClick = () => {
        setMenuOpen(false);
      };
  return (
  <>
    <div className="smnavbg  fixed z-[99] w-[100%] ">
        <div className="2xl:space-x-0 xl:space-x-0 lg:space-x-0 md:space-x-0 sm:space-x-2 space-x-2 2xl:w-[96%] xl:w-[96%] lg:w-[96%] md:w-[96%] sm:w-[94%] w-[94%] relative m-auto py-[20px] max-w-[1440px] flex justify-between items-center ">
         <div className="flex space-x-2 items-center">
         <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block p-2">
         <img src={menuOpen ? menu_open : menu} onClick={toggleMenu} alt="" />
          </div>
            <div className="">
               <a href="/" className="">
               {/* <img src={Logo} className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block 2xl:w-[auto] xl:w-[auto] lg:w-[auto] md:w-[auto] sm:w-[auto] w-[auto] h-[auto]" alt="" /> */}
               <img src={Logo2} className="2xl:w-[250px] xl:w-[220px] lg:w-[220px] md:w-[220px] sm:w-[210px] w-[191px] box-border" alt="" />

               </a>
            </div>
         </div>
            <div className="2xl:space-x-[25px] xl:space-x-[25px] lg:space-x-[15px] md:space-x-[25px] sm:space-x-[25px] space-x-[25px] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden flex items-center">
                <a className="text-[#fff] 2xl:text-[16px] xl:text-[16px] lg:text-[14px] md:text-[14px] sm:text-[16px] text-[6px]"  href="#whatisdoge">{t("main.navlink1")}</a>
                <a className="text-[#fff]"  href="#howtobuysec">{t("main.navlink2")}</a>
                <a className="text-[#fff]"  href="#whitepaper">{t("main.navlink3")}</a>
                <a className="text-[#fff]"  href="#tokenomics">{t("main.navlink4")}</a>
                <a className="text-[#fff]"  href="#roadmap">{t("main.navlink5")}</a>
                <a className="text-[#fff]"  href="#faqs">{t("main.navlink6")}</a>
                <div className="relative">
                    <button onClick={handleLangDropdown} className="flex items-center justify-center  px-[5px] py-[10px] bg-[transprent] text-[#fff]"> <img src={selectedLang.img} alt="" width={20} className="mr-2" />
                    {selectedLang.sub} <span><img src={awr} className="ml-3" alt="" /></span></button>
        {langStatus && (
                    <>
                      <div
                      ref={dropdownRef}
                          id="dropdown"
                          className="absolute font-[Poppins] bg-[#f0f8ff00] w-[1100px] h-[600px]  right-[-210%] top-[140%] z-10 "
                        >
                          <HoverDropDn
                           onLanguageChange={handleLanguageChange}
                           locales={locales}
                           setMenuOpen={setMenuOpen}
                          />
                        </div>
                      {/* <div onClick={handleLangDropdown} className='relative'>
                                            <div className='bg-[#faebd700] !w-[1400px] -top-[2] right-[-513%] h-[100vh] absolute '></div>
                                        </div> */}
                    </>
                  )}
                </div>
            </div>
           
         
            <div>
                <button
                  className="bg-[#FBDA19] hover:bg-[transparent] border border-[#FBDA19] rounded-[40px] px-[15px] py-[8px] 2xl:text-[18px] xl:text-[18px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[14px] font-[700] hover:text-[#fff]"
                  onClick={() => {
                    if (!web3.connected) web3.showConnectionModal()
                    else web3.disconnect()
                  }}
                >
                  {web3.connected ? t("main.navBtn-disconnect", {walletAddress: truncateAddress(web3.account, 10)}) : t("main.navBtn")}
                </button>
            </div>
        </div>
    </div>
        {menuOpen && (
        <div  className="fixed z-[99] top-20 right-0 bg-[#111010] min-h-[100vh] w-[100%] shadow-lg py-10 px-10">
          <nav>
            <ul className="flex flex-col space-y-[2rem]">
            <a className="text-[#fff] text-[16px]" onClick={handleMenuItemClick} href="#whatisdogesec">{t("main.navlink1")}</a>
                <a className="text-[#fff]" onClick={handleMenuItemClick} href="#howtobuy">{t("main.navlink2")}</a>
                <a className="text-[#fff]" onClick={handleMenuItemClick} href="#whitepapersec">{t("main.navlink3")}</a>
                <a className="text-[#fff]" onClick={handleMenuItemClick} href="#tokenomics">{t("main.navlink4")}</a>
                <a className="text-[#fff]" onClick={handleMenuItemClick} href="#roadmapsec">{t("main.navlink5")}</a>
                <a className="text-[#fff]" onClick={handleMenuItemClick} href="#faqs">{t("main.navlink6")}</a>
                <div className="relative">
                    <button onClick={handleLangDropdown} className="flex items-center justify-center  px-[5px] py-[10px] bg-[transprent] text-[#fff]"> <img src={selectedLang.img} alt="" width={20} className="mr-2" />
                    {selectedLang.sub} <span><img src={awr} className="ml-3" alt="" /></span></button>
        {langStatus && (
                    <>
                      <div
                      ref={dropdownRef}
                          id="dropdown"
                          className="absolute font-[Poppins] border border-gray-500 rounded-[20px] w-[300px] h-[160px] overflow-scroll  top-[100%] p-3 z-10 "
                        >
                          <HoverDropDn
                          setMenuOpen={setMenuOpen}
                           onLanguageChange={handleLanguageChange}
                           locales={locales}
                          />
                        </div>
                    </>
                  )}
                </div>
            </ul>
          </nav>
        </div>
      )}
  </>
  );
}

export default Navbar;
