import React from "react";

import Home from "./Page/Index";
import Navbar from "./compunents/navbar";
import Footer from "./compunents/footer";
import { GlobalWrapper } from "./presale-gg/context/GlobalWrapper";

function App() {

  return (
    <GlobalWrapper>
      <div className="bg-[#151515] overflow-hidden relative">
        <Navbar />
        <Home />
        <Footer />
      </div>
    </GlobalWrapper>
  );
}

export default App;
