import React from "react";
import icon1 from "../assets/svgs/secbaricn (2).svg"
import icon2 from "../assets/svgs/secbaricn (1).svg"
import icon3 from "../assets/svgs/secbaricn (3).svg"
import TopBannerCard from "../compunents/topbannercard";

const SecbannerData = [
    {
        id:1,
        icon:icon1,
        para:"Sestbar.para",
    },
    {
        id:2,
        icon:icon2,
        para:"Sestbar.para2",
    },
    {
        id:3,
        icon:icon3,
        para:"Sestbar.para3",
    },
]


function SecbarSec() {
  return (
    <div className=" w-[100%] bg-[#202020]">
    <div className="2xl:flex xl:flex 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[94%] w-[94%] m-auto lg:flex md:flex sm:block block justify-center 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[1rem] space-y-[1rem] items-center py-[25px]">
    {
        SecbannerData.map((item)=>(
            <TopBannerCard icon={item.icon} para={item.para} />

        ))
     }
    </div>
    </div>
  );
}

export default SecbarSec;
