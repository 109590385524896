import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import whtimg from "../assets/images/tokimg.png";
import whtimgmob from "../assets/images/mobtest.png";
import docn from "../assets/svgs/docn.svg";
import mout from "../assets/images/mout.png";
import copy from "../assets/svgs/copy.svg";
import toklofimg from "../assets/svgs/doge kombat logo.svg";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function DOKOnomicsSec() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getAnchorHref = () => {
    return screenSize >= 768 ? "#hero" : "#herosec";
  };

  const textToCopy = "J81qghZ57WcjZomA36YgcDpxGyyacHkAk3XpJv2zfroE";

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      toast.success("Text copied to clipboard!");
    }).catch(err => {
      toast.error("Failed to copy text");
      console.error('Failed to copy text: ', err);
    });
  };
  return (
    <div className="relative z-[1] max-w-[990px] space-y-[3rem] 2xl:w-[86%] xl:w-[86%] lg:w-[90%] md:w-[90%] sm:w-[94%] w-[94%] m-auto  2xl:pb-[2rem] xl:pb-[2rem] lg:pb-[2rem] md:pb-[2rem] sm:pb-[1rem] pb-[1rem]">
       <ToastContainer />
      <h2 className="2xl:block xl:block lg:block md:block sm:hidden hidden 2xl:text-[104px] 2xl:block xl:block lg:block md:block sm:hidden hidden xl:text-[104px] lg:text-[104px] md:text-[104px] sm:text-[46px] text-[46px] font-[700] leading-[120%] 2xl:text-[#fff] xl:text-[#fff] lg:text-[#fff] md:text-[#fff] sm:text-[#FBD914] text-[#FBD914] uppercase text-center">
      {t("DOKOnomicsSec.title")}
      </h2>
     
      <div className="w-[100%] relative 2xl:px-[2rem] xl:px-[2rem] lg:px-[2rem] md:px-[2rem] sm:px-[0rem] px-[0rem] pb-[3rem] pt-[2rem] whatbggrsec space-y-[1rem]">
      <img src={mout} className="2xl:block xl:block lg:block md:block sm:hidden hidden absolute top-[10%] left-[-15%]" alt="" />

        <h3 className="text-[26px] 2xl:hidden xl:block lg:block md:block sm:hidden hidden font-[700] text-[#FDDB12] text-center">
        {t("DOKOnomicsSec.title2")}
        </h3>
        <h2 className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block 2xl:text-[104px]  xl:text-[104px] lg:text-[104px] md:text-[104px] sm:text-[40px] text-[40px] font-[700] leading-[120%] 2xl:text-[#fff] xl:text-[#fff] lg:text-[#fff] md:text-[#fff] sm:text-[#FBD914] text-[#FBD914] uppercase text-center">
        {t("DOKOnomicsSec.title")}
      </h2>
        <div className="flex justify-center 2xl:block xl:block lg:block md:block sm:hidden hidden space-x-[2rem] items-center">
          <img src={whtimg} className="w-[90%] m-auto" alt="" />
        </div>
        <div className="flex justify-center 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block space-x-[2rem] items-center">
          <img src={whtimgmob} className="w-[100%] max-w-[394px] h-[804px] m-auto" alt="" />
        </div>
      </div>
      <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:flex  flex justify-center h-[100px] relative">
      <img src={docn}  className="z-[1] max-w-[250px] absolute top-[-80%] " alt="" />
      </div>
      <div className="w-[100%]  px-[2rem] pb-[3rem] pt-[2rem] space-y-[2rem] whatbggrsec ">
        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[2rem] space-y-[2rem] justify-between">
          <div>
            <h4 className="text-[26px] text-[#FDDB12] font-[700]">{t("DOKOnomicsSec.TokenName")}</h4>
            <h5 className="text-[18px] font-[400] text-[#fff]">{t("DOKOnomicsSec.Kombat")}</h5>
          </div>
          <div>
            <h4 className="text-[26px] text-[#FDDB12] font-[700]">{t("DOKOnomicsSec.Symbol")}</h4>
            <h5 className="text-[18px] font-[400] text-[#fff]">{t("DOKOnomicsSec.DOKO")}</h5>
          </div>
          <div>
            <h4 className="text-[26px] text-[#FDDB12] font-[700]">{t("DOKOnomicsSec.Type")}</h4>
            <h5 className="text-[18px] font-[400] text-[#fff]">{t("DOKOnomicsSec.Solana")}</h5>
          </div>
        </div>
        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block  2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[2rem] space-y-[2rem] justify-between items-center">
          <div>
            <h3 className="text-[26px] font-[700] text-[#FDDB12]">{t("DOKOnomicsSec.Address")}</h3>
            <div className="flex space-x-2 2xl:items-center xl:items-center lg:items-center md:items-center sm:items-end items-end">
              <p className="text-[#fff] text-[18px] font-[400] break-words overflow-hidden ">
              J81qghZ57WcjZomA36YgcDpxGyyacHkAk3XpJv2zfroE
              </p>
              <img src={copy} alt="Copy" className="cursor-pointer" onClick={handleCopy} />
            </div>
           
          </div>
          <div className="w-[125px]">
            <h3 className="text-[26px] font-[700] text-[#FDDB12]">{t("DOKOnomicsSec.Decimal")}</h3>
            <p className="text-[#fff] text-[18px] font-[400] ">9</p>
          </div>
        </div>
      </div>
      <div className="2xl:flex xl:flex lg:flex md:flex sm:block block 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[2rem] space-y-[2rem] justify-between 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem]">
        <div className="w-[50%] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden justify-center items-center  tokcard1bggr">
            <img src={toklofimg} alt="" />
        </div>
        <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%] relative 2xl:space-y-[2rem] xl:space-y-[2rem] lg:space-y-[2rem] md:space-y-[2rem] sm:space-y-[0rem] space-y-[0rem] 2xl:py-[5rem] xl:py-[5rem] lg:py-[5rem] md:py-[5rem] sm:py-[2rem] py-[2rem] 2xl:px-[6rem] xl:px-[6rem] lg:px-[6rem] md:px-[6rem] sm:px-[2rem] px-[2rem] whatbggrsec">
            <img src={docn} className="2xl:block xl:block lg:block md:block sm:hidden hidden absolute top-[0%] right-[-35%]" alt="" />
        <div className="2xl:pb-[0rem] xl:pb-[0rem] lg:pb-[0rem] md:pb-[0rem] sm:pb-[2rem] pb-[2rem]">
            <h3 className="text-[#FDDB12] text-[26px] font-[700]">{t("DOKOnomicsSec.Details")}</h3>
            <p className=" text-[18px] text-[#fff] font-[400]">{t("DOKOnomicsSec.Detailspara")}</p>
        </div>
        <div className="2xl:pb-[0rem] xl:pb-[0rem] lg:pb-[0rem] md:pb-[0rem] sm:pb-[2rem] pb-[2rem]">
            <h3 className="text-[#FDDB12] text-[26px] font-[700] 2xl:block xl:block lg:block md:block sm:hidden hidden">{t("DOKOnomicsSec.Current")}</h3>
            <h3 className="text-[#FDDB12] text-[26px] font-[700] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block">{t("DOKOnomicsSec.Current")}</h3>
            <p className=" text-[18px] text-[#fff] font-[400]">1 $DOKO = $0.002</p>
        </div>
        <div className="">
            <h3 className="text-[#FDDB12] text-[26px] font-[700] 2xl:block xl:block lg:block md:block sm:hidden hidden">{t("DOKOnomicsSec.Launch")}</h3>
            <h3 className="text-[#FDDB12] text-[26px] font-[700] 2xl:hidden xl:hidden lg:hidden md:hidden sm:block block">{t("DOKOnomicsSec.Exchange")}</h3>
            <p className=" text-[18px] text-[#fff] font-[400]">1 $DOKO = $0.01</p>
        </div>

        </div>
      </div>
      <div id="roadmap" className="flex justify-between items-center">
     <a href={getAnchorHref()} className="max-w-[200px] w-[100%] m-auto">
     <button className="bg-[#FBDA19] m-auto max-w-[200px] w-[100%] hover:bg-[transparent] border border-[#FBDA19] rounded-[40px] px-[24px] py-[8px] text-[18px] font-[700] hover:text-[#fff]">{t("DOKOnomicsSec.btn")}</button>
     </a>
      </div>
     
    </div>
  );
}

export default DOKOnomicsSec;
